import React, { forwardRef } from "react";

// Use forwardRef to properly handle the reference passed by useReactToPrint
const PdfValue = forwardRef((props, ref) => {
  const { meds, values, selectedStartDate, selectedEndDate, resumeDate } =
    props;
  // Replace with your selected date

  const formatDate = (date) => {
    const newDate = new Date(date); // Parse the date if it's not already a Date object
    const day = String(newDate.getDate()).padStart(2, "0"); // Ensure two digits
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = newDate.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <div
        ref={ref} // Assign ref for printing
        id="pdf-element"
        style={{
          width: "100%",
          marginTop: "26%",
          // marginTop: "30px",
          // padding: "20px",
          // border: "1px solid #ddd",
          // borderRadius: "4px",
          // fontSize: "16px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h1>{values} Certificate</h1>
          <hr></hr>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px", // Adjust gap size for spacing
              marginTop: "10px",
            }}
          >
            <h3
              style={{
                textDecoration: "underline",
                marginTop: "10px",
                // fontSize: "40px",
              }}
            >
              To Whomsoever It May Concern
            </h3>
            <h6 style={{ fontSize: "32px" }}>This is to certify that</h6>
            <h6 style={{ fontSize: "32px" }}>Mr./Mrs./Miss {meds.medName}</h6>
            <h6 style={{ fontSize: "32px" }}>
              Is/was under my treatment since
            </h6>
            <h6 style={{ fontSize: "32px" }}>
              {formatDate(selectedStartDate)} to {formatDate(selectedEndDate)}
            </h6>
            <h6 style={{ fontSize: "32px" }}>For {meds.medType}</h6>
            <h6 style={{ fontSize: "32px" }}>He/She is/was Advised</h6>
            <h6 style={{ fontSize: "32px" }}>
              Medical treatment and rest for this period.
            </h6>
            <h6 style={{ fontSize: "32px" }}>
              He/She is medically fit to resume his/her duties/class w.e.f.{" "}
              {formatDate(resumeDate)}
            </h6>
          </div>
        </div>

        <p
          style={{
            textAlign: "right",
            paddingRight: "70px",
            paddingTop: "60px",
            fontSize: "32px",
          }}
        >
          Signature
        </p>
      </div>
    </>
  );
});

export default PdfValue;
