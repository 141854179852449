import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import { Container, Row, Col } from "react-bootstrap";

import { Box, Typography } from "@material-ui/core";
import DrListTable from "./components/DrListTable";
import GroupList from "./components/GroupList";
// import PersonalDetail from "./ProfileInfo/PersonalDetail";
// import VisitList from "./ProfileInfo/VisitList";
// import PaymentHistory from "./ProfileInfo/PaymentHistory";
// import queryString from "query-string";
// import AddNewVisitForm from "./ProfileInfo/AddNewVisitForm";
import { hostInfo } from "../../store";
// import DrListView from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    // paddingBottom: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
    background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
  },
  inner: {
    minWidth: "100%",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  headCard: {
    background: "#EBEEFF",
    // margin: theme.spacing(1),
    // display: "flex",
    // alignItems: "center",
  },
  headCardDr: {
    background: "#FEF0CA",
    // margin: theme.spacing(1),
    // display: "flex",
    // alignItems: "center",
  },
  cardBottom: {
    marginBottom: theme.spacing(2),
  },
  headText: {
    // background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    marginTop: theme.spacing(1),
    // display: "flex",
    // alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const DrList = () => {
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    async function getDrGroups() {
      let url = hostInfo + "/getdrgroups";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null) {
            if (res != null) {
              setGroupList(res);
              setGroupId(res[0].id);
              setGroupName(res[0].name);
            }
          } else {
            setGroupList([]);
          }
        });
    }

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    if (groupId === 0) {
      getDrGroups();
    }
    console.log("inside the hook---");
    window.addEventListener("resize", handleResize);
  }, [groupId]);

  // const handleOnDeleteDr = (id) => {
  //   if (id === 0) {
  //     return;
  //   }

  //   let data = {
  //     id: id,
  //   };

  //   alert("Sure want to delete ???");

  //   let url = "http://" + hostInfo + "/deletegroup";
  //   fetch(url, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });

  //   window.location.reload();
  // };

  const handleOnDelete = (id) => {
    if (id === 0) {
      return;
    }

    let data = {
      id: id,
    };

    alert("Sure want to delete ???");

    let url = hostInfo + "/deletegroup";
    fetch(url, {
      method: "POST",
      // mode: "no-cors",
      headers: {
        Accept: "*/*",
        "Content-type": "application/json; charset=UTF-8",

        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // If the response status is 200-299, reload the page
          window.location.reload();
        } else {
          // Handle errors here
          response.json().then((error) => {
            console.error("Error:", error);
            // alert("Failed to add group. Please try again.");
          });
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error("Network error:", error);
        // alert("An error occurred. Please check your connection and try again.");
      });
  };

  const handleOnSelectGroup = (id, name) => {
    setGroupId(id);
    setGroupName(name);
    // if (groupId > 0) {
    //   let url = "http://" + hostInfo + "/getdrdetails?group_id=" + groupId;
    //   fetch(url)
    //     .then((res) => res.json())
    //     .then((data) => {
    //       // console.log("Got data Disease data  ----11111111111111");
    //       // console.log(data);
    //       if (data != null) setDrDetails(data);
    //     });
    // }
    console.log("Selected group id: ", id);
  };

  const handleOnAdd = (name, desc) => {
    if (name === null) {
      return;
    }

    let data = {
      name: name,
      description: desc,
    };

    let url = hostInfo + "/addgroup";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // If the response status is 200-299, reload the page
          window.location.reload();
        } else {
          // Handle errors here
          response.json().then((error) => {
            console.error("Error:", error);
            // alert("Failed to add group. Please try again.");
          });
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error("Network error:", error);
        // alert("An error occurred. Please check your connection and try again.");
      });
  };

  // const handleOnAddDr = (groupId, name, mobile, desc) => {
  //   if (groupId === 0 || name === null) {
  //     return;
  //   }

  //   let data = {
  //     dr_group_id: groupId,
  //     name: name,
  //     mobile: mobile,
  //     description: desc,
  //   };

  //   let url = "http://" + hostInfo + "/adddrdetails";
  //   fetch(url, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });
  //   window.location.reload();
  // };

  const handleOnSave = (id, name, desc) => {
    if (id === 0 || name === null) {
      return;
    }

    let data = {
      id: id,
      name: name,
      description: desc,
    };

    let url = hostInfo + "/updategroup";
    fetch(url, {
      method: "POST",
      // mode: "no-cors",
      headers: {
        Accept: "*/*",
        "Content-type": "application/json; charset=UTF-8",

        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // If the response status is 200-299, reload the page
          window.location.reload();
        } else {
          // Handle errors here
          response.json().then((error) => {
            console.error("Error:", error);
            // alert("Failed to add group. Please try again.");
          });
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error("Network error:", error);
        // alert("An error occurred. Please check your connection and try again.");
      });
  };

  // const handleOnSaveDr = (id, name, mobile, desc) => {
  //   if (id === 0 || name === null) {
  //     return;
  //   }

  //   let data = {
  //     id: id,
  //     name: name,
  //     module: mobile,
  //     description: desc,
  //   };

  //   let url = "http://" + hostInfo + "/updatedrdetails";
  //   fetch(url, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });
  // };

  return (
    <div className={classes.root}>
      <Container fluid className="main-content-container px-1">
        <Row>
          <Col lg="3">
            <Box
              // boxShadow={3}
              // borderRadius={5}
              bgcolor="background.paper"
              // p={1}
              className={classes.cardBottom}
            >
              {/* <Card className={classes.headCard}> */}
              {/* <CardContent> */}
              <Typography
                gutterBottom
                variant="h4"
                component="h2"
                color="textSecondary"
                align="center"
                className={classes.headText}
                // justify="space-between"

                // margin=""
              >
                Dr Groups
              </Typography>
              {/* </CardContent> */}
              {/* </Card> */}
              {/* <Paper style={{ maxHeight: 400, overflow: "auto" }}> */}

              <GroupList
                groupList={groupList}
                handleOnSave={handleOnSave}
                handleOnDelete={handleOnDelete}
                handleOnAdd={handleOnAdd}
                handleOnSelectGroup={handleOnSelectGroup}
                scrHeight={dimensions.height}
                scrWidth={dimensions.width}
              />
              {/* </Paper> */}
            </Box>
          </Col>

          <Col lg="9">
            {/* <Box
              // boxShadow={3}
              // borderRadius={5}
              bgcolor="background.paper"
              // m={1}
              // p={1}
              className={classes.cardBottom}
            > */}
            {/* <Card className={classes.headCardDr}> */}
            {/* <CardContent> */}
            {/* <Typography
                  gutterBottom
                  variant="h4"
                  component="h2"
                  color="textSecondary"
                  align="center"
                  className={classes.headText}
                  // justify="space-between"

                  // margin=""
                >
                  Dr Details
                </Typography> */}
            {/* </CardContent> */}
            {/* </Card> */}
            <DrListTable
              groupId={groupId}
              // drDetails={drDetails}
              groupName={groupName}
              scrHeight={dimensions.height}
              scrWidth={dimensions.width}
              // handleOnEditDr={handleOnSaveDr}
              // handleOnDeleteDr={handleOnDeleteDr}
              // handleOnAddDr={handleOnAddDr}
              //  handleOnSelectGroup={handleOnSelectGroup}
            />
            {/* </Box> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DrList;
