import React, { Fragment, createRef } from "react";
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Divider,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
  Modal,
  Popper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { diagnosisSuggestions, hostInfo } from "../../../store";
// import FuncComp from "./testFunc";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddKwDia from "./AddKwDia";
import ClearIcon from "@mui/icons-material/Clear";
import FormMedicine from "./components/Medicine";
import FormReport from "./components/Report";
// import RemoveIcon from "@material-ui/icons/Remove";
import { Country, State, City } from "country-state-city";
import FormGroup from "@material-ui/core/FormGroup";
import { visitFormfields } from "../../../store";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { Search } from "@material-ui/icons";
// import PrintHtml from "./PrintHtml/PrintHtml";
// import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: 230,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    // width: 230,
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
    //borderWidth: 4
  },
  presc: {
    width: "100%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
  cardClr: {
    background: "#F3F8F8",
  },
  freeTextNotes: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

// var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class Diagnosis extends React.Component {
    containerRef = createRef();

    state = {
      search: "",
      dialogOpen: false,
      templateName: "",
      isSuggestionOpen: 1,
      filteredDisSug: this.props.disSug,
      openSuggestionIndex: false,
      templates: [],
      isListVisible: false,
      isTemplateClicked: false,
      isSaveTemplateClicked: false,
      isLoadPreviousClicked: false,
      durationSuggestions: [
        { label: "__day" },
        { label: "__week" },

        { label: "__month" },
        { label: "__year" },
        // { label: "monthly" },
        // { label: "Loose Motion" },
        // { label: "Abdominal Pain" },
        // { label: "Nasal Blockage" },
      ],

      // rows: this.props.diagnosis,

      frequencyRefs: [],
      severityRefs: [],
      durationRefs: [],
      selectedComplaint: null,
      openModal: false,
      modalRowIndex: null,
    };

    countryArr = Country.getAllCountries();
    stateArr = [];
    cityArr = [];
    selectedCountry = "";
    selectedState = "";
    selectedCity = "";
    stateDisabled = true;
    cityDisabled = true;
    // frequencyRefs = [];
    // severityRefs = [];
    // durationRefs = [];
    medSug = [];
    repSug = [];
    drSug = [];
    symSug = [];
    disSug = [];
    drGroups = [];

    // handleComplaintChange = (index, value) => {
    //   const newRows = [...this.state.rows];

    //   newRows[index].diagnosis = value;

    //   if (value && value.trim() !== "" && index === newRows.length - 1) {
    //     // If the current index is the last row and there's a value, add a new empty object to the rows
    //     newRows.push({
    //       diagnosis: "",

    //       duration: "",
    //       date: "",
    //       inputNumber: "",
    //     });
    //   }

    //   // Update state with the new rows
    //   this.setState({ rows: newRows });
    //   this.props.onRowsChange(newRows);
    //   this.setState({ isSuggestionOpen: index });
    //   this.setState({ isfrequencySuggestionOpen: index });
    //   this.setState({ isseveritySuggestionOpen: index });
    // };

    handleComplaintChange = (index, value) => {
      const newRows = [...this.state.rows];
      const complaintKey = value ? value : ""; // Get the complaint key from the input

      // Get the existing row for the index or create a new object if it doesn't exist
      const existingRow = newRows[index] || {};

      // Get the current values for the complaint key or default to an empty array
      const complaintValues = existingRow[complaintKey] ||
        existingRow[Object.keys(existingRow)[0]] || ["", ""];

      // Create a new object with only the new complaint key and its values
      const updatedRow = {
        [complaintKey]: complaintValues, // Update the row with the new complaint key and its values
      };

      // Update the row at the specified index
      newRows[index] = updatedRow;

      // If the current index is the last row, add a new empty row
      if (index === newRows.length - 1) {
        newRows.push({ "": ["", ""] }); // Add an empty row for future input
      }

      // Update the state with the modified rows
      this.setState({ rows: newRows });
      this.props.onRowsChange(newRows);
      // Open the suggestion box only if the complaint values are empty
      if (!newRows[index][complaintKey].slice(0, 1).some((v) => v !== "")) {
        this.setState({
          isSuggestionOpen: index,
        });
      } else {
        // Close the suggestion boxes if values exist
        this.setState({
          isSuggestionOpen: -1,
        });
      }
    };

    handleLoadPrevious = async () => {
      this.setState({ isLoadPreviousClicked: true });

      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      try {
        const response = await fetch(
          `${hostInfo}/diagnosis/${this.props.patient_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to load previous report");
        }

        const data = await response.json();
        // console.log(JSON.parse(data)); // Log the fetched data

        const currentRepList = this.props.drNotes || [];

        // Create a new array with existing values and new values, ensuring uniqueness
        const newRepName = Array.from(
          new Set([...currentRepList, ...JSON.parse(data)])
        );
        this.props.onDiagnosisChange(newRepName);
        // Pass the combined unique values to the handleChange function
        // this.props.onRowsChange(newRepName); // Default to an empty array if no report is found

        // Update the repList by merging existing values with loaded values
        // this.setState((prevState) => ({
        //   formdetail: {
        //     ...prevState.formdetail,
        //     repList: [...prevState.formdetail.repList, ...previousValues], // Merge existing and loaded values
        //   },
        // }));
      } catch (error) {
        console.error("Error loading previous reports:", error);
      }
    };

    handleNumberInput(index, inputValue) {
      const rows = [...this.state.rows];
      rows[index].inputNumber = inputValue; // Store the number in the row
      this.setState({ rows });
      this.props.onRowsChange(rows);
    }
    handleDurationChange = (index, inputNumber) => {
      const rows = [...this.state.rows];
      const key = Object.keys(rows[index])[0]; // Get the complaint key

      rows[index][key][0] = inputNumber ? `${inputNumber}` : ""; // Set duration

      if (inputNumber) {
        const parts = inputNumber.split(" ");
        const durationValue = parseInt(parts[0], 10);
        const durationUnit = parts[1] ? parts[1].toLowerCase() : "";

        if (!isNaN(durationValue)) {
          const baseDate = new Date();

          if (durationUnit === "day" || durationUnit === "days") {
            baseDate.setDate(baseDate.getDate() - durationValue);
          } else if (durationUnit === "week" || durationUnit === "weeks") {
            baseDate.setDate(baseDate.getDate() - durationValue * 7);
          } else if (durationUnit === "month" || durationUnit === "months") {
            baseDate.setMonth(baseDate.getMonth() - durationValue);
          } else if (durationUnit === "year" || durationUnit === "years") {
            baseDate.setFullYear(baseDate.getFullYear() - durationValue);
          }

          rows[index][key][1] = baseDate.toISOString().split("T")[0]; // Set calculated date
        } else {
          rows[index][key][1] = ""; // Clear date if invalid
        }
      } else {
        rows[index][key][1] = ""; // Clear date if no duration provided
      }

      this.setState({ rows });
    };

    // Function to handle date change
    handleDateChange = (index, dateValue) => {
      const newRows = [...this.state.rows];
      const key = Object.keys(newRows[index])[0]; // Get the complaint key

      newRows[index][key][3] = dateValue; // Update date field

      this.setState({ rows: newRows });
    };
    handleDeleteComplaint = (index) => {
      this.setState((prevState) => {
        const newRows = prevState.rows.filter((_, i) => i !== index);

        // If no rows are left, insert a default empty row
        const updatedRows =
          newRows.length === 0
            ? [
                {
                  "": ["", new Date().toISOString().split("T")[0]],
                },
              ]
            : newRows;
        // console.log(updatedRows);
        this.props.onRowsChange(updatedRows);
        return {
          rows: updatedRows, // Update the rows state with the filtered array
        };
      });
    };

    handleDialogOpen = () => {
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });

      this.setState({ isSaveTemplateClicked: true });
      this.setState({ dialogOpen: true });
    };

    handleDialogClose = () => {
      this.setState({ isSaveTemplateClicked: false });
      this.setState({ dialogOpen: false });
    };

    // handleTemplateSelect = (template) => {
    //   this.setState(
    //     (prevState) => ({
    //       rows: [
    //         ...prevState.rows.slice(0, -1), // Remove the last empty row
    //         ...template.json_data, // Add the template's rows
    //         { "": ["", ""] }, // Add an empty row at the end
    //       ],
    //     }),
    //     () => {
    //       this.props.onRowsChange(this.state.rows); // Ensure rows are updated in parent component
    //     }
    //   );

    //   // Close suggestions and toggle the list visibility
    //   this.setState((prevState) => ({
    //     isListVisible: !prevState.isListVisible,
    //     isTemplateClicked: false,
    //     openSuggestionIndex: false,
    //   }));
    // };
    // handleSaveTemplate = () => {
    //   const getToken = () => {
    //     const cookieString = document.cookie;
    //     const cookies = cookieString.split("; ").reduce((acc, cookie) => {
    //       const [name, value] = cookie.split("=");
    //       acc[name] = value;
    //       return acc;
    //     }, {});
    //     return cookies.authToken;
    //   };

    //   const { templateName, rows } = this.state;

    //   // Filter rows to remove empty keys and values
    //   const filteredRows = rows.filter((row) => {
    //     const key = Object.keys(row)[0]; // Get the key (e.g., "Fever")
    //     const values = row[key]; // Get the values array (e.g., ["1 day", "2024-10-14"])
    //     return (
    //       key &&
    //       key.trim() !== "" &&
    //       values.every((value) => value.trim() !== "")
    //     ); // Check that key and all values are non-empty
    //   });

    //   if (filteredRows.length > 0 && templateName) {
    //     const savedData = { name: templateName, json_data: filteredRows }; // Use filtered rows for saving

    //     fetch(hostInfo + "/addTemplate", {
    //       method: "POST",
    //       headers: {
    //         Accept: "*/*",
    //         "Content-type": "application/json; charset=UTF-8",
    //         Authorization: `Bearer ${getToken()}`,
    //       },
    //       body: JSON.stringify(savedData),
    //     })
    //       .then((response) => response.json())
    //       .then((data) => {
    //         console.log("API Response:", data);
    //         if (data.message) {
    //           alert("Template saved successfully");
    //         } else {
    //           alert("Error saving template");
    //         }

    //         this.setState((prevState) => ({
    //           templates: [...(prevState.templates || []), savedData],
    //           templateName: "", // Reset template name
    //         }));
    //       })
    //       .catch((error) => {
    //         console.error("Error:", error);
    //         alert("Failed to save template");
    //       });

    //     this.handleDialogClose();
    //   } else {
    //     alert("Please enter valid template data and name.");
    //     this.handleDialogClose();
    //     this.setState({ templateName: "" });
    //   }
    // };

    // toggleTemplateList = () => {
    //   this.setState((prevState) => ({
    //     isListVisible: !prevState.isListVisible, // Toggle the visibility state
    //   }));
    //   this.setState({ isTemplateClicked: !this.state.isTemplateClicked });
    //   this.setState({
    //     isfrequencySuggestionOpen: -1,
    //     isseveritySuggestionOpen: -1,
    //     isSuggestionOpen: -1,
    //   });
    // };

    handleSearchChange = (event) => {
      this.setState({ search: event.target.value }); // Update search term
    };

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeyNavigation);
      // document.addEventListener("mousedown", this.handleClickOutside);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // this.fetchTemplates = () => {
      fetch(hostInfo + "/gettemplates", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          // Return the JSON response
          return response.json(); // Added return statement here
        })
        .then((data) => {
          this.setState({ templates: data }); // Update state with fetched data
          console.log("Fetched Templates:", data); // Log fetched data
        })
        .catch((error) => {
          console.error("Error fetching templates:", error); // Log any errors
        });

      // };
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("keydown", this.handleKeyNavigation);
    }

    handleClickOutside = (event) => {
      if (
        this.containerRef.current &&
        !this.containerRef.current.contains(event.target)
      ) {
        this.setState({ isListVisible: false, isTemplateClicked: false });
      }
    };
    handleKeyNavigation = (event) => {
      const { templates, highlightedIndex, isListVisible } = this.state;

      // Only trigger if the suggestion list is visible
      if (!isListVisible) return;

      // Prevent default scrolling behavior when navigating suggestions
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }

      // Navigate down (ArrowDown)
      if (event.key === "ArrowDown") {
        this.setState((prevState) => ({
          highlightedIndex: Math.min(
            prevState.highlightedIndex + 1,
            templates.length - 1
          ),
        }));
      }

      // Navigate up (ArrowUp)
      if (event.key === "ArrowUp") {
        this.setState((prevState) => ({
          highlightedIndex: Math.max(prevState.highlightedIndex - 1, 0),
        }));
      }

      // Select the highlighted suggestion (Enter key)
      if (event.key === "Enter" && highlightedIndex >= 0) {
        this.handleTemplateSelect(
          this.props.idx,
          "repName",
          templates[highlightedIndex]
        );
      }
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleDiaClickOpen = () => {
      this.setState({ openDia: true });
    };

    handleDiaClickClose = () => {
      this.setState({ openDia: false });
    };

    handleOnChange = (e, name) => {
      const value = e.target.value;
      // console.log("Value change --- ");
      // console.log(e.target);
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMultiFld = (name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.props.onRowsChange(value);
      // this.setState({
      //   ...this.state,
      //   formdetail: {
      //     ...this.state.formdetail,
      //     [name]: value,
      //   },
      // });
    };

    handleInsurance = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };
    handleFocus = () => {
      // Optional: Reset filteredDisSug when the field is focused
      this.setState({ filteredDisSug: this.props.disSug });
    };
    // Mitesh: TODO make index incremental

    render() {
      const {
        rows,
        dialogOpen,
        templateName,
        templates,
        search,
        isTemplateClicked,

        isSaveTemplateClicked,
        isListVisible,
      } = this.state;
      // console.log(rows);
      const maxVisibleSuggestions = 4; // Maximum number of visible suggestions
      const suggestionHeight =
        templates?.length === 0
          ? 42
          : templates?.length === 1
          ? 46
          : templates?.length === 2
          ? 42
          : templates?.length === 3
          ? 38
          : 60; // Height for each suggestion item (in px)
      const searchBarHeight = 30; // Height of the search bar

      // Calculate the height of the div based on suggestions and search bar
      const divHeight =
        searchBarHeight +
        Math.min(
          templates && templates.length > 0 ? templates.length : 1,
          maxVisibleSuggestions
        ) *
          suggestionHeight;
      const { classes } = this.props;

      return (
        <>
          {/* <Box> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "7px",
            }}
          >
            {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Complaints
                  </Typography> */}
            <Typography
              gutterBottom
              // variant="h6"
              color="textSecondary"
              // color="black"
              style={{ marginLeft: "10px" }}
            >
              Diagnosis (D/D)
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              style={{ marginLeft: "5px" }}
              // onClick={() => this.handleDeleteComplaint(item.id)}
              size="small"
            >
              <SkipPreviousIcon
                // color="textSecondary"
                // fontSize="small"
                style={{
                  height: "15px",
                  width: "15px",
                  // color: "rgba(89, 162, 162, 1)",
                }}
              />
            </Typography>
            <Typography
              // variant="h6"
              gutterBottom
              onClick={this.handleLoadPrevious}
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                color: "#ff3369",
                fontSize: "10px",
                marginTop: "2px",
              }}
            >
              Load Previous
            </Typography>

            {/* <Typography
              gutterBottom
              style={{ marginLeft: "20px" }}
              // onClick={() => this.handleDeleteComplaint(item.id)}
              size="small"
            >
              <SaveIcon
                fontSize="small"
                style={{
                  color: isSaveTemplateClicked ? "blue" : "black",
                }}
              />
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                marginLeft: "5px",
                cursor: "pointer",
                color: isSaveTemplateClicked ? "blue" : "black",
              }}
              onClick={this.handleDialogOpen}
            >
              Save Template
            </Typography> */}
            <Dialog open={dialogOpen} onClose={this.handleDialogClose}>
              <DialogTitle>Save Template</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Template Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={templateName}
                  onChange={(e) =>
                    this.setState({ templateName: e.target.value })
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleSaveTemplate} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <div
              style={{ position: "relative", display: "inline-block" }}
              ref={this.containerRef}
            >
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={this.toggleTemplateList} // Toggle list on click
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginLeft: "20px",
                    color: isTemplateClicked ? "blue" : "black",
                  }}
                >
                  Templates
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  // style={{ marginTop: "2px" }}
                >
                  <ArrowDropDownIcon
                    fontSize="small"
                    style={{
                      color: isTemplateClicked ? "blue" : "black",
                    }}
                  />
                </Typography>
                
              </div> */}
              {isListVisible && (
                <div
                  style={{
                    position: "absolute",
                    top: "65%", // Align right under the Typography
                    left: "20px", // Match marginLeft of Typography
                    zIndex: 10, // Ensure it's above other elements
                    backgroundColor: "white", // Set background color for better visibility
                    borderRadius: "4px", // Add border radius for aesthetics
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Add subtle shadow for depth
                    marginTop: "7px",
                    height: `${divHeight}px`, // Initial height based on suggestions and search bar
                    maxHeight: "156px", // Set max height to 156px
                    overflowY: "auto", // Enable scrolling if content exceeds max height
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center", // Center the items vertically
                      flexWrap: "wrap",
                      flexDirection: "column",
                      padding: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center", // Center the items vertically
                        flexDirection: "row",
                        marginBottom: "3px", // Add space below the search bar
                        height: `${searchBarHeight}px`, // Set the search bar height
                      }}
                    >
                      <Search
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "5px",
                          marginRight: "5px", // Add space between the icon and the input
                          color: "#666",
                        }}
                      />
                      <input
                        type="search"
                        placeholder="Search"
                        style={{
                          outline: "none",
                          border: "none", // Remove borders for a cleaner look
                          borderBottom: "1px solid #ccc", // Keep bottom border for input
                          padding: "5px", // Add padding for better UX
                          flexGrow: 1, // Allow input to take available width
                        }}
                        onChange={this.handleSearchChange} // Handle search input change
                      />
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <Autocomplete
                        options={
                          templates && Array.isArray(templates)
                            ? templates.filter((template) =>
                                template.name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                            : []
                        }
                        getOptionLabel={(option) => option.name} // Display name in the dropdown
                        disableClearable
                        open // Force the dropdown to open when the list is visible
                        onChange={(event, newValue) => {
                          if (newValue) {
                            this.handleTemplateSelect(newValue); // Handle the selected value
                          }
                        }}
                        style={{ width: "100%" }} // Make the dropdown take the full width
                        ListboxProps={{
                          style: {
                            border: "none",
                            maxHeight: `${
                              maxVisibleSuggestions * suggestionHeight
                            }px`, // Limit the height of suggestions
                            overflowY: "auto", // Add vertical scroll when needed
                            overflowX: "hidden", // Prevent horizontal scroll
                          },
                        }}
                        PopperComponent={(props) => (
                          <Popper {...props} placement="bottom" />
                        )}
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                height: "0", // Shrink the input field's height to 0
                                minHeight: "0", // Ensure the input field doesn't take any space
                                visibility: "hidden", // Hide the input field but keep it in the DOM
                                border: "none", // Remove borders from the input
                              },
                              endAdornment: null, // Remove the dropdown arrow if needed
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Divider variant="left" style={{ marginTop: "1px" }} />
          {/* <Table
            sx={{
              marginTop: "7px",
              border: "1px solid rgba(224, 224, 224, 1)",
              width: "auto", // Table Border
            }}
            size="small" // Reduce padding in the table
          >
            <TableHead>
              <TableRow style={{ backgroundColor: "#f8f9fa" }}>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "35px",
                    fontWeight: "bold",
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "300px",
                    fontWeight: "bold",
                  }}
                >
                  Diagnosis
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                >
                  Duration
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid white",
                    padding: "2px",
                    backgroundColor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => {
                const diagnosis = Object.keys(row)[0]; // This gives the complaint (e.g., "fever")
                const values = row[diagnosis]; // This gives the array of values (e.g., ["daily", "mild", "1 day", "15-10-2024"])

                return (
                  <TableRow key={index}>
                   
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <Autocomplete
                        onFocus={this.handleFocus}
                        options={this.state.filteredDisSug} // Use the filtered list
                        getOptionLabel={(option) => option.label || option} // Handle both objects and strings
                        disableClearable
                        freeSolo // Allows the user to enter custom values
                        value={diagnosis || ""} // Display the current diagnosis
                        onChange={(event, newValue) => {
                          // Handle both selected options and custom input values
                          const updatedComplaint = newValue
                            ? newValue.label || newValue
                            : "";
                          this.handleComplaintChange(index, updatedComplaint);
                        }}
                        onInputChange={(event, newInputValue) => {
                          this.handleComplaintChange(index, newInputValue);

                          const inputValue = newInputValue.toLowerCase();
                          const filteredOptions = this.props.disSug.filter(
                            (option) => {
                              const label = option.label || option;
                              return (
                                label &&
                                label.toLowerCase().includes(inputValue)
                              );
                            }
                          );

                          // Update filteredDisSug in state
                          this.setState({ filteredDisSug: filteredOptions });
                        }}
                        PopperComponent={(props) => (
                          <Popper {...props} placement="bottom" />
                        )}
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                          />
                        )}
                      />
                    </TableCell>
                   
                    <TableCell
                      key={index}
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <Autocomplete
                        open={
                          this.state.isSuggestionOpen === index && diagnosis
                        } // Open only for the current index
                        options={this.state.durationSuggestions} // Use the state variable
                        disableClearable
                        freeSolo
                        getOptionLabel={(option) => option.label} // Display the label of each option
                        value={
                          values[0] ? { label: values[0] } : null // Set the current value
                        }
                        filterOptions={(options) => options} // Show all suggestions without filtering by input
                        onChange={(event, newValue) => {
                          const formattedValue = newValue
                            ? newValue.label.replace(/__/g, "")
                            : ""; // Format value

                          this.handleDurationChange(
                            index,
                            formattedValue.trim().replace(/\s+/g, " ")
                          ); // Update the duration
                          this.setState({ isSuggestionOpen: -1 }); // Close all suggestions
                        }}
                        onInputChange={(event, newInputValue) => {
                          // console.log(
                          //   /^\d*$/.test(newInputValue) ||
                          //     /^\d+\s*[a-zA-Z]*$/.test(newInputValue)
                          // );
                          if (
                            /^\d*$/.test(newInputValue) ||
                            /^\d+\s*[a-zA-Z]*$/.test(newInputValue)
                          ) {
                            if (
                              !isNaN(newInputValue) &&
                              newInputValue.trim() !== ""
                            ) {
                              const newSuggestions = [
                                { label: `${newInputValue} day` },
                                { label: `${newInputValue} week` },
                                { label: `${newInputValue} month` },
                                { label: `${newInputValue} year` },
                              ];
                              this.setState({
                                durationSuggestions: newSuggestions,
                                isSuggestionOpen: index, // Open suggestion for the current index
                              });
                            } else {
                              // Reset to default suggestions if input is empty or invalid
                              const defaultSuggestions = [
                                { label: "__day" },
                                { label: "__week" },
                                { label: "__month" },
                                { label: "__year" },
                              ];
                              this.setState({
                                durationSuggestions: defaultSuggestions,
                                isSuggestionOpen: -1, // Close all suggestions if invalid input
                              });
                            }
                          }
                          // Check if the input is a valid number

                          // Update duration state on input change
                          this.handleDurationChange(
                            index,
                            newInputValue
                              .trim() // Remove leading and trailing spaces
                              .replace(/\s+/g, " ")
                          ); // Ensure the value is updated in the state
                        }}
                        onBlur={() => {
                          this.setState({ isSuggestionOpen: -1 }); // Close suggestions when the input loses focus
                        }}
                        PopperComponent={(props) => (
                          <Popper {...props} placement="bottom" />
                        )}
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            inputRef={this.inputRef}
                          />
                        )}
                      />
                    </TableCell>

                  
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <TextField
                        type="date"
                        variant="standard"
                        value={values[1]} // Bind the date value
                        onChange={(event) =>
                          this.handleDateChange(index, event.target.value)
                        } // Update on change
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </TableCell>
                    
                    <TableCell
                      sx={{
                        border: "1px solid white",
                        padding: "2px",
                      }}
                    >
                      <IconButton
                        onClick={() => this.handleDeleteComplaint(index)}
                        size="small"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table> */}
          <Autocomplete
            style={{ marginRight: "16px", marginLeft: "10px" }}
            //////////////////// Multifield
            multiple
            id="multi field"
            value={this.props.drNotes}
            // autoFocus
            // disableClearable
            size="small"
            freeSolo
            classes={{ option: classes.autocomp }}
            options={this.props.disSug || []}
            filterSelectedOptions
            onChange={(e, newValue) => {
              this.handleMultiFld("drNotes", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                multiline
                rows={2}
                variant="outlined"
                label={visitFormfields.diagnose}
                // placeholder="drNotes"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          {/* </Box> */}
        </>
      );
    }
  }
);
