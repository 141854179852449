import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { enableflag, hostInfo } from "../../store";
import DateFnsUtils from "@date-io/date-fns";
// import DateUtils from "@date-io/moment";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Button,

  // Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
// import CardMedia from "@material-ui/core/CardMedia";
// import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer";
// import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
// import { alpha } from "@material-ui/core/styles";
import myLogo from "../../../src/images/avatar/logo22.png";
import styles from "./mystyle.css";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import DatePicker from "@mui/lab/DatePicker";
// import Moment from "moment";
// import ComingSoon from "react-coming-soon";
// import csImg from "../../../images/avatar/c_s.jpeg";
// import csImg from "../../images/avatar/c_s.jpeg";
import moment from "moment";
// import { Card, CardContent } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import ReactToPrint from "react-to-print";
import { PatientsListTbl } from "../Certificate/components";
import { Tooltip } from "@mui/material";

// const useStyles = (theme) => ({
//   card: {
//     display: "flex",
//     margin: 100,
//   },
//   details: {
//     display: "flex",
//     flexDirection: "column",
//   },
//   content: {
//     flex: "1 0 auto",
//   },
//   cover: {
//     width: 151,
//   },
//   controls: {
//     display: "flex",
//     alignItems: "center",
//   },
//   container: {
//     position: "relative",
//     textAlign: "center",
//     color: "white",
//   },
//   topasad: {
//     color: "blue",
//     position: "absolute",
//     top: "10%",
//     left: "50%",
//   },

//   media: {
//     display: "flex",
//     height: 100,
//     objectFit: "contain",
//     alignItems: "left",
//   },
// });

const AssForm = () => {
  // const classes = useStyles();
  let componentRef = useRef();
  // const [isPrinting, setIsPrinting] = useState(false);
  // console.log(isPrinting);
  // const handlePrintClick = () => {
  //   setIsPrinting(true); // Set isPrinting to true before printing
  //   setTimeout(() => setIsPrinting(false), 1000); // Reset isPrinting after printing
  // };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  // const { handleSubmitAss, setIsAssReport } = props;
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    params.get("str_dt")
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    params.get("end_dt")
  );
  // const [isDisabled, setIsDisabled] = React.useState(false);
  // const [type, settype] = React.useState(params.get("type"));
  const [subtype, setsubtype] = React.useState("");

  const [graphData, setgraphData] = React.useState([]);

  const [data, setdata] = React.useState([]);

  const [appendedData, setappendedData] = React.useState([]);
  // console.log(appendedData);
  const [graphtype, setgraphtype] = React.useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const generateMonthsBetween = (startDate, endDate) => {
    const start = new Date(startDate); // Parse the start date
    const end = new Date(endDate); // Parse the end date
    const monthsArray = [];

    // Iterate through months between start and end dates
    while (start <= end) {
      const monthName = start.toLocaleString("default", { month: "short" }); // Get short month name
      const year = start.getFullYear(); // Get the year
      monthsArray.push(`${monthName}-${year}`);

      // Move to the next month
      start.setMonth(start.getMonth() + 1);
    }

    return monthsArray;
  };

  // End date (mm-dd-yyyy)
  const months = generateMonthsBetween(selectedStartDate, selectedEndDate);

  const [chartVisible, setChartVisible] = React.useState({
    showrevenu: false,
    showpatient: false,
    showvisit: false,
    showreffer: false,
    showpl: false,
    showvisithistory: false,
  });
  const [pData, setpData] = useState("");
  const [user, setUser] = useState([]);
  const [rData, setrData] = useState("");
  const [patientList, setPatientList] = useState([]);

  // var type = null;
  let datesInMonth = [];
  const categories = [];
  // console.log(categories);
  const count = [];
  const totalData = [];
  const paidData = [];
  const remainData = [];
  const start = selectedStartDate;
  const end = selectedEndDate;
  const genderdata = [];

  genderdata.push(pData.genderwise?.male || 0);
  genderdata.push(pData.genderwise?.female || 0);
  genderdata.push(pData.genderwise?.other || 0);

  var st = parseInt(moment(start, "MM-DD-YYYY").valueOf() / 1000);

  var en = parseInt(moment(end, "MM-DD-YYYY").valueOf() / 1000);

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  // useEffect(() => {
  //   if (type === "revenue" && selectedEndDate && selectedStartDate) {
  //     if (st < en) {
  //       const diff = (en - st) / 60 / 60 / 24;

  //       if (diff <= 30) {
  //         setsubtype("daily");
  //       } else if (diff > 30 && diff <= 365) {
  //         setsubtype("monthly");
  //       } else if (diff > 365) {
  //         setsubtype("yearly");
  //       }
  //     } else {
  //       alert("start date must be less than the end date");
  //       setChartVisible({
  //         showrevenu: false,
  //         showpatient: false,
  //         showvisit: false,
  //         showreffer: false,
  //         showpl: false,
  //       });
  //       return;
  //     }
  //     let url = hostInfo + "/getassesmentdata";
  //     fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Accept: "*/*",
  //         "Content-type": "application/json",

  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //       body: JSON.stringify({
  //         type: type,
  //         data_type: subtype,
  //         start_date: st,
  //         end_date: en,
  //       }),
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           return response.json();
  //         } else {
  //           throw new Error("HTTP request failed");
  //         }
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         if (res && res.graph_data.data && res.graph_data.data.length > 0) {
  //           // Data is available
  //           setdata(res);
  //           // if (enableflag === 1 && res?.expenses) {
  //           //   setappendedData([
  //           //     {
  //           //       amount: res.total,
  //           //       date_time: selectedStartDate + " to " + selectedEndDate, // Replace with the actual start date
  //           //       purpose: "visit info",
  //           //     },
  //           //     ...res?.expenses,
  //           //   ]);
  //           // }

  //           setgraphData(res.graph_data.data);

  //           if (subtype === "daily") {
  //             const dateString = res.graph_data.data[0].x_val.toString();
  //             // const dateString = res.revenue.graphData.map(
  //             //   (i) => i.value.map((i) => i.name)[0]
  //             // )[0];
  //             // console.log(dateString);
  //             const parts = dateString.split("/");
  //             // const day = parseInt(parts[1], 10);
  //             const month = parseInt(parts[0], 10) - 1;
  //             const year = parseInt(parts[2], 10);

  //             const startDate = new Date(year, month, 1);
  //             const endDate = new Date(year, month + 1, 0);

  //             for (
  //               let date = startDate;
  //               date <= endDate;
  //               date.setDate(date.getDate() + 1)
  //             ) {
  //               const formattedDate = `${String(date.getMonth() + 1).padStart(
  //                 2,
  //                 "0"
  //               )}/${String(date.getDate()).padStart(
  //                 2,
  //                 "0"
  //               )}/${date.getFullYear()}`;
  //               datesInMonth.push(formattedDate);
  //               // console.log(datesInMonth);
  //               setgraphtype(datesInMonth);
  //             }
  //             setChartVisible({
  //               showrevenu: true,
  //               showpatient: false,
  //               showvisit: false,
  //               showreffer: false,
  //               showpl: false,
  //             });
  //           } else if (subtype === "monthly") {
  //             setgraphtype(months);
  //             setChartVisible({
  //               showrevenu: true,
  //               showpatient: false,
  //               showvisit: false,
  //               showreffer: false,
  //               showpl: false,
  //             });
  //           } else if (subtype === "yearly") {
  //             // const dateString =
  //             //   res.revenue.graph_data.data[0].x_val.toString();
  //             // const lastValue = (parseInt(dateString, 10) - 1).toString();
  //             // const newValue = (parseInt(dateString, 10) + 1).toString();
  //             const graphData = res.graph_data.data.map((i) => i.x_val); // Assuming x_val contains the year

  //             // Find the maximum year in the data
  //             const maxYear = Math.max(...graphData);

  //             // Add missing years up to 6 years if needed
  //             const currentYear = new Date().getFullYear(); // Get the current year
  //             const yearsToAdd = [];

  //             for (let year = maxYear + 1; year <= currentYear + 5; year++) {
  //               yearsToAdd.push(year); // Add the missing years up to 6 years
  //             }

  //             // Combine the original data with the missing years
  //             const completeGraphData = [...graphData, ...yearsToAdd];

  //             setgraphtype(completeGraphData);
  //             // setgraphtype(res.graph_data.data.map((i) => i.x_val));
  //             setChartVisible({
  //               showrevenu: true,
  //               showpatient: false,
  //               showvisit: false,
  //               showreffer: false,
  //               showpl: false,
  //             });
  //           }
  //         } else {
  //           // alert("No data found or invalid response structure");
  //           // console.log("Data not found");
  //           setdata([]);
  //           setgraphData([]);
  //           setChartVisible({
  //             showrevenu: false,
  //             showpatient: false,
  //             showvisit: false,
  //             showreffer: false,
  //             showpl: false,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         // alert("No data found or invalid response structure");
  //         // console.error("Error fetching data:", error);
  //         // Handle error, set state accordingly
  //         setdata([]);
  //         setgraphData([]);
  //         setChartVisible({
  //           showrevenu: false,
  //           showpatient: false,
  //           showvisit: false,
  //           showreffer: false,
  //           showpl: false,
  //         });
  //       });
  //   } else if (type === "visit" && selectedEndDate && selectedStartDate) {
  //     if (st < en) {
  //       const diff = (en - st) / 60 / 60 / 24;

  //       if (diff <= 30) {
  //         setsubtype("daily");
  //       } else if (diff > 30 && diff <= 365) {
  //         setsubtype("monthly");
  //       } else if (diff > 365) {
  //         setsubtype("yearly");
  //       }
  //     } else {
  //       alert("start date must be less than the end date");
  //       setChartVisible({
  //         showrevenu: false,
  //         showpatient: false,
  //         showvisit: false,
  //         showreffer: false,
  //         showpl: false,
  //       });
  //       return;
  //     }

  //     let url = hostInfo + "/getassesmentdata";
  //     fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Accept: "*/*",
  //         "Content-type": "application/json",

  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //       body: JSON.stringify({
  //         type: type,
  //         data_type: subtype,
  //         start_date: st,
  //         end_date: en,
  //       }),
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           return response.json();
  //         } else {
  //           throw new Error("HTTP request failed");
  //         }
  //       })
  //       .then((res) => {
  //         // console.log(res);
  //         if (
  //           res &&
  //           res.graph_data &&
  //           res.graph_data.data &&
  //           res.graph_data.data.length > 0
  //         ) {
  //           setdata(res);
  //           console.log(res);
  //           setgraphData(res.graph_data.data);

  //           if (subtype === "daily") {
  //             const dateString = res.graph_data.data[0].x_val.toString();
  //             const parts = dateString.split("/");
  //             const month = parseInt(parts[0], 10) - 1;
  //             const year = parseInt(parts[2], 10);

  //             const startDate = new Date(year, month, 1);
  //             const endDate = new Date(year, month + 1, 0);

  //             for (
  //               let date = startDate;
  //               date <= endDate;
  //               date.setDate(date.getDate() + 1)
  //             ) {
  //               const formattedDate = `${String(date.getMonth() + 1).padStart(
  //                 2,
  //                 "0"
  //               )}/${String(date.getDate()).padStart(
  //                 2,
  //                 "0"
  //               )}/${date.getFullYear()}`;
  //               datesInMonth.push(formattedDate);
  //               setgraphtype(datesInMonth);
  //             }

  //             setChartVisible({
  //               showrevenu: false,
  //               showpatient: false,
  //               showvisit: true,
  //               showreffer: false,
  //               showpl: false,
  //             });
  //           } else if (subtype === "monthly") {
  //             setgraphtype(months);
  //             setChartVisible({
  //               showrevenu: false,
  //               showpatient: false,
  //               showvisit: true,
  //               showreffer: false,
  //               showpl: false,
  //             });
  //           } else if (subtype === "yearly") {
  //             const graphData = res.graph_data.data.map((i) => i.x_val); // Assuming x_val contains the year

  //             // Find the maximum year in the data
  //             const maxYear = Math.max(...graphData);

  //             // Add missing years up to 6 years if needed
  //             const currentYear = new Date().getFullYear(); // Get the current year
  //             const yearsToAdd = [];

  //             for (let year = maxYear + 1; year <= currentYear + 5; year++) {
  //               yearsToAdd.push(year); // Add the missing years up to 6 years
  //             }

  //             // Combine the original data with the missing years
  //             const completeGraphData = [...graphData, ...yearsToAdd];

  //             setgraphtype(completeGraphData);
  //             // setgraphtype(res.graph_data.data.map((i) => i.x_val));
  //             setChartVisible({
  //               showrevenu: false,
  //               showpatient: false,
  //               showvisit: true,
  //               showreffer: false,
  //               showpl: false,
  //             });
  //           }
  //         } else {
  //           // alert("No data found or invalid response structure");
  //           setdata([]);
  //           setgraphData([]);
  //           setChartVisible({
  //             showrevenu: false,
  //             showpatient: false,
  //             showvisit: false,
  //             showreffer: false,
  //             showpl: false,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         // console.error("Error fetching data:", error);
  //         // alert("No data found or invalid response structure");
  //         setdata([]);
  //         setgraphData([]);
  //         setChartVisible({
  //           showrevenu: false,
  //           showpatient: false,
  //           showvisit: false,
  //           showreffer: false,
  //           showpl: false,
  //         });
  //       });
  //   } else if (type === "patient" && selectedEndDate && selectedStartDate) {
  //     const url = hostInfo + "/getassesmentdata";
  //     const data = {
  //       type: type,
  //       data_type: " ",
  //       start_date: st,
  //       end_date: en,
  //       username: selectedOption,
  //     };

  //     fetch(url, {
  //       // mode: "no-cors",
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",

  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //       body: JSON.stringify(data),
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! Status: ${response.status}`);
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         if (data) {
  //           setpData(data);
  //         } else {
  //           setpData([]);
  //         }
  //       })
  //       .catch((error) => console.error("Error:", error));
  //     setChartVisible({
  //       showrevenu: false,
  //       showpatient: true,
  //       showvisit: false,
  //       showreffer: false,
  //       showpl: false,
  //     });
  //   } else if (type === "reffered" && selectedEndDate && selectedStartDate) {
  //     let url = hostInfo + "/getassesmentdata";
  //     fetch(url, {
  //       method: "POST",
  //       // mode: "no-cors",
  //       headers: {
  //         Accept: "*/*",
  //         "Content-type": "application/json",

  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //       body: JSON.stringify({
  //         type: type,
  //         data_type: "",
  //         start_date: st,
  //         end_date: en,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data) {
  //           console.log(data.visit_counts);
  //           setrData(data);
  //         } else {
  //           setrData([]);
  //         }
  //       });
  //     setChartVisible({
  //       showrevenu: false,
  //       showpatient: false,
  //       showvisit: false,
  //       showreffer: true,
  //       showpl: false,
  //     });
  //   } else if (type === "pl" && selectedEndDate && selectedStartDate) {
  //     if (st < en) {
  //       const diff = (en - st) / 60 / 60 / 24;

  //       if (diff <= 30) {
  //         setsubtype("daily");
  //       } else if (diff > 30 && diff <= 365) {
  //         setsubtype("monthly");
  //       } else if (diff > 365) {
  //         setsubtype("yearly");
  //       }
  //     } else {
  //       alert("start date must be less than the end date");
  //       setChartVisible({
  //         showrevenu: false,
  //         showpatient: false,
  //         showvisit: false,
  //         showreffer: false,
  //         showpl: false,
  //       });
  //       return;
  //     }
  //     let url = hostInfo + "/getassesmentdata";
  //     fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Accept: "*/*",
  //         "Content-type": "application/json",

  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //       body: JSON.stringify({
  //         type: type,
  //         data_type: subtype,
  //         start_date: st,
  //         end_date: en,
  //       }),
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           return response.json();
  //         } else {
  //           throw new Error("HTTP request failed");
  //         }
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         if (res && res.graph_data.data && res.graph_data.data.length > 0) {
  //           // Data is available
  //           setdata(res);
  //           if (enableflag === 1 && res?.expenses) {
  //             setappendedData([
  //               {
  //                 amount: res.total,
  //                 date_time: selectedStartDate + " to " + selectedEndDate, // Replace with the actual start date
  //                 purpose: "visit revenue",
  //               },
  //               ...res?.expenses,
  //             ]);
  //           }

  //           setgraphData(res.graph_data.data);

  //           if (subtype === "daily") {
  //             const dateString = res.graph_data.data[0].x_val.toString();
  //             // const dateString = res.revenue.graphData.map(
  //             //   (i) => i.value.map((i) => i.name)[0]
  //             // )[0];
  //             // console.log(dateString);
  //             const parts = dateString.split("/");
  //             // const day = parseInt(parts[1], 10);
  //             const month = parseInt(parts[0], 10) - 1;
  //             const year = parseInt(parts[2], 10);

  //             const startDate = new Date(year, month, 1);
  //             const endDate = new Date(year, month + 1, 0);

  //             for (
  //               let date = startDate;
  //               date <= endDate;
  //               date.setDate(date.getDate() + 1)
  //             ) {
  //               const formattedDate = `${String(date.getMonth() + 1).padStart(
  //                 2,
  //                 "0"
  //               )}/${String(date.getDate()).padStart(
  //                 2,
  //                 "0"
  //               )}/${date.getFullYear()}`;
  //               datesInMonth.push(formattedDate);
  //               // console.log(datesInMonth);
  //               setgraphtype(datesInMonth);
  //             }
  //             setChartVisible({
  //               showrevenu: false,
  //               showpatient: false,
  //               showvisit: false,
  //               showreffer: false,
  //               showpl: true,
  //             });
  //           } else if (subtype === "monthly") {
  //             setgraphtype(months);
  //             setChartVisible({
  //               showrevenu: false,
  //               showpatient: false,
  //               showvisit: false,
  //               showreffer: false,
  //               showpl: true,
  //             });
  //           } else if (subtype === "yearly") {
  //             // const dateString =
  //             //   res.revenue.graph_data.data[0].x_val.toString();
  //             // const lastValue = (parseInt(dateString, 10) - 1).toString();
  //             // const newValue = (parseInt(dateString, 10) + 1).toString();
  //             const graphData = res.graph_data.data.map((i) => i.x_val); // Assuming x_val contains the year

  //             // Find the maximum year in the data
  //             const maxYear = Math.max(...graphData);

  //             // Add missing years up to 6 years if needed
  //             const currentYear = new Date().getFullYear(); // Get the current year
  //             const yearsToAdd = [];

  //             for (let year = maxYear + 1; year <= currentYear + 5; year++) {
  //               yearsToAdd.push(year); // Add the missing years up to 6 years
  //             }

  //             // Combine the original data with the missing years
  //             const completeGraphData = [...graphData, ...yearsToAdd];

  //             setgraphtype(completeGraphData);
  //             // setgraphtype(res.graph_data.data.map((i) => i.x_val));
  //             setChartVisible({
  //               showrevenu: false,
  //               showpatient: false,
  //               showvisit: false,
  //               showreffer: false,
  //               showpl: true,
  //             });
  //           }
  //         } else {
  //           // alert("No data found or invalid response structure");
  //           // console.log("Data not found");
  //           setdata([]);
  //           setgraphData([]);
  //           setChartVisible({
  //             showrevenu: false,
  //             showpatient: false,
  //             showvisit: false,
  //             showreffer: false,
  //             showpl: false,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         // alert("No data found or invalid response structure");
  //         // console.error("Error fetching data:", error);
  //         // Handle error, set state accordingly
  //         setdata([]);
  //         setgraphData([]);
  //         setChartVisible({
  //           showrevenu: false,
  //           showpatient: false,
  //           showvisit: false,
  //           showreffer: false,
  //           showpl: false,
  //         });
  //       });
  //   }
  // }, [type, subtype]);

  const revenuInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=revenue&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };

  const visitInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=visit&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };

  const patientInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=patient&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };
  const refferInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=reffered&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };

  const PLInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=pl&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };

  const handleReset = () => {
    const url = "/assessment";

    window.location.href = url;
  };

  for (const month of graphtype) {
    const matchingData = graphData?.find((item) => item.x_val === month);

    if (matchingData) {
      categories.push(month);
      totalData.push(matchingData.y_val.total);
      paidData.push(matchingData.y_val.paid);
      remainData.push(matchingData.y_val.remain);
      count.push(matchingData.y_val.count);
    } else {
      categories.push(month);
      totalData.push(0);
      paidData.push(0);
      remainData.push(0);
      count.push(0);
    }
  }

  const options = {
    chart: {
      id: "bar",
    },
    xaxis: {
      categories: categories,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
  };
  const series = [
    {
      name: "Total",
      data: totalData,
    },
    {
      name: "Paid",
      data: paidData,
    },
    {
      name: "Remain",
      data: remainData,
    },
  ];

  const voptions = {
    chart: {
      id: "bar",
    },
    xaxis: {
      categories: categories,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
  };
  const vseries = [
    {
      name: "Patient",
      data: count,
    },
  ];
  // console.log(genderdata);
  const pseries = genderdata;
  const poptions = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Male", "Female", "Other"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const handleStartDateChange = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;

    setSelectedStartDate(formattedDate);
  };
  const handleEndDateChange = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;

    setSelectedEndDate(formattedDate);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUser(
          data.users.filter(
            (i) => i.accesses_flag === 3 || i.accesses_flag === 2
          )
        );
      } catch (error) {
        console.error("There was an error fetching the user info!", error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleNameChange = (event) => {
    setSelectedName(event.target.value);
  };
  const handleFetchButtonClick = (username) => {
    return () => {
      if (!selectedStartDate || !selectedEndDate) {
        alert("Please select both start and end dates.");
        return;
      }
      if (!selectedOption || !selectedName) {
        alert("Please select both doctor and info option.");
        return;
      }

      if (selectedName === "revenue" && selectedEndDate && selectedStartDate) {
        if (st < en) {
          const diff = (en - st) / 60 / 60 / 24;

          if (diff <= 30) {
            setsubtype("daily");
          } else if (diff > 30 && diff <= 365) {
            setsubtype("monthly");
          } else if (diff > 365) {
            setsubtype("yearly");
          }
        } else {
          alert("start date must be less than the end date");
          setChartVisible({
            showrevenu: false,
            showpatient: false,
            showvisit: false,
            showvisithistory: false,
            showreffer: false,
            showpl: false,
          });
          return;
        }
        let url = hostInfo + "/getassesmentdata";
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            type: selectedName,
            data_type: subtype,
            start_date: st,
            end_date: en,
            username: selectedOption,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("HTTP request failed");
            }
          })
          .then((res) => {
            console.log(res);
            if (res && res.graph_data.data && res.graph_data.data.length > 0) {
              // Data is available
              setdata(res);
              // if (enableflag === 1 && res?.expenses) {
              //   setappendedData([
              //     {
              //       amount: res.total,
              //       date_time: selectedStartDate + " to " + selectedEndDate, // Replace with the actual start date
              //       purpose: "visit info",
              //     },
              //     ...res?.expenses,
              //   ]);
              // }

              setgraphData(res.graph_data.data);

              if (subtype === "daily") {
                const dateString = res.graph_data.data[0].x_val.toString();
                // const dateString = res.revenue.graphData.map(
                //   (i) => i.value.map((i) => i.name)[0]
                // )[0];
                // console.log(dateString);
                const parts = dateString.split("/");
                // const day = parseInt(parts[1], 10);
                const month = parseInt(parts[0], 10) - 1;
                const year = parseInt(parts[2], 10);

                const startDate = new Date(year, month, 1);
                const endDate = new Date(year, month + 1, 0);

                for (
                  let date = startDate;
                  date <= endDate;
                  date.setDate(date.getDate() + 1)
                ) {
                  const formattedDate = `${String(date.getMonth() + 1).padStart(
                    2,
                    "0"
                  )}/${String(date.getDate()).padStart(
                    2,
                    "0"
                  )}/${date.getFullYear()}`;
                  datesInMonth.push(formattedDate);
                  // console.log(datesInMonth);
                  setgraphtype(datesInMonth);
                }
                setChartVisible({
                  showrevenu: true,
                  showpatient: false,
                  showvisit: false,
                  showvisithistory: false,
                  showreffer: false,
                  showpl: false,
                });
              } else if (subtype === "monthly") {
                setgraphtype(months);
                setChartVisible({
                  showrevenu: true,
                  showpatient: false,
                  showvisit: false,
                  showvisithistory: false,
                  showreffer: false,
                  showpl: false,
                });
              } else if (subtype === "yearly") {
                // const dateString =
                //   res.revenue.graph_data.data[0].x_val.toString();
                // const lastValue = (parseInt(dateString, 10) - 1).toString();
                // const newValue = (parseInt(dateString, 10) + 1).toString();
                const graphData = res.graph_data.data.map((i) => i.x_val); // Assuming x_val contains the year

                // Find the maximum year in the data
                const maxYear = Math.max(...graphData);

                // Add missing years up to 6 years if needed
                const currentYear = new Date().getFullYear(); // Get the current year
                const yearsToAdd = [];

                for (let year = maxYear + 1; year <= currentYear + 5; year++) {
                  yearsToAdd.push(year); // Add the missing years up to 6 years
                }

                // Combine the original data with the missing years
                const completeGraphData = [...graphData, ...yearsToAdd];

                setgraphtype(completeGraphData);
                // setgraphtype(res.graph_data.data.map((i) => i.x_val));
                setChartVisible({
                  showrevenu: true,
                  showpatient: false,
                  showvisit: false,
                  showvisithistory: false,
                  showreffer: false,
                  showpl: false,
                });
              }
            } else {
              // alert("No data found or invalid response structure");
              // console.log("Data not found");
              setdata([]);
              setgraphData([]);
              setChartVisible({
                showrevenu: false,
                showpatient: false,
                showvisit: false,
                showvisithistory: false,
                showreffer: false,
                showpl: false,
              });
            }
          })
          .catch((error) => {
            // alert("No data found or invalid response structure");
            // console.error("Error fetching data:", error);
            // Handle error, set state accordingly
            setdata([]);
            setgraphData([]);
            setChartVisible({
              showrevenu: false,
              showpatient: false,
              showvisit: false,
              showvisithistory: false,
              showreffer: false,
              showpl: false,
            });
          });
      } else if (
        selectedName === "visit" &&
        selectedEndDate &&
        selectedStartDate
      ) {
        if (st < en) {
          const diff = (en - st) / 60 / 60 / 24;

          if (diff <= 30) {
            setsubtype("daily");
          } else if (diff > 30 && diff <= 365) {
            setsubtype("monthly");
          } else if (diff > 365) {
            setsubtype("yearly");
          }
        } else {
          alert("start date must be less than the end date");
          setChartVisible({
            showrevenu: false,
            showpatient: false,
            showvisit: false,
            showvisithistory: false,
            showreffer: false,
            showpl: false,
          });
          return;
        }

        let url = hostInfo + "/getassesmentdata";
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            type: selectedName,
            data_type: subtype,
            start_date: st,
            end_date: en,
            username: selectedOption,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("HTTP request failed");
            }
          })
          .then((res) => {
            // console.log(res);
            if (
              res &&
              res.graph_data &&
              res.graph_data.data &&
              res.graph_data.data.length > 0
            ) {
              setdata(res);
              console.log(res);
              setgraphData(res.graph_data.data);

              if (subtype === "daily") {
                const dateString = res.graph_data.data[0].x_val.toString();
                const parts = dateString.split("/");
                const month = parseInt(parts[0], 10) - 1;
                const year = parseInt(parts[2], 10);

                const startDate = new Date(year, month, 1);
                const endDate = new Date(year, month + 1, 0);

                for (
                  let date = startDate;
                  date <= endDate;
                  date.setDate(date.getDate() + 1)
                ) {
                  const formattedDate = `${String(date.getMonth() + 1).padStart(
                    2,
                    "0"
                  )}/${String(date.getDate()).padStart(
                    2,
                    "0"
                  )}/${date.getFullYear()}`;
                  datesInMonth.push(formattedDate);
                  setgraphtype(datesInMonth);
                }

                setChartVisible({
                  showrevenu: false,
                  showpatient: false,
                  showvisit: true,
                  showvisithistory: false,
                  showreffer: false,
                  showpl: false,
                });
              } else if (subtype === "monthly") {
                setgraphtype(months);
                setChartVisible({
                  showrevenu: false,
                  showpatient: false,
                  showvisit: true,
                  showvisithistory: false,
                  showreffer: false,
                  showpl: false,
                });
              } else if (subtype === "yearly") {
                const graphData = res.graph_data.data.map((i) => i.x_val); // Assuming x_val contains the year

                // Find the maximum year in the data
                const maxYear = Math.max(...graphData);

                // Add missing years up to 6 years if needed
                const currentYear = new Date().getFullYear(); // Get the current year
                const yearsToAdd = [];

                for (let year = maxYear + 1; year <= currentYear + 5; year++) {
                  yearsToAdd.push(year); // Add the missing years up to 6 years
                }

                // Combine the original data with the missing years
                const completeGraphData = [...graphData, ...yearsToAdd];

                setgraphtype(completeGraphData);
                // setgraphtype(res.graph_data.data.map((i) => i.x_val));
                setChartVisible({
                  showrevenu: false,
                  showpatient: false,
                  showvisit: true,
                  showreffer: false,
                  showvisithistory: false,
                  showpl: false,
                });
              }
            } else {
              // alert("No data found or invalid response structure");
              setdata([]);
              setgraphData([]);
              setChartVisible({
                showrevenu: false,
                showpatient: false,
                showvisit: false,
                showvisithistory: false,
                showreffer: false,
                showpl: false,
              });
            }
          })
          .catch((error) => {
            // console.error("Error fetching data:", error);
            // alert("No data found or invalid response structure");
            setdata([]);
            setgraphData([]);
            setChartVisible({
              showrevenu: false,
              showpatient: false,
              showvisit: false,
              showvisithistory: false,
              showreffer: false,
              showpl: false,
            });
          });

        const fetchPatientData = async (startDate, endDate, username) => {
          const url = `${hostInfo}/getdatewisevisits?start_date=${startDate}&end_date=${endDate}&username=${username}`;
          try {
            const response = await fetch(url, {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            });

            if (response.ok) {
              const data = await response.json();
              setPatientList(data);

              setChartVisible({
                showrevenu: false,
                showpatient: false,
                showvisit: true,
                // showvisithistory: true,
                showreffer: false,
                showpl: false,
              });
            } else {
              alert("Failed to fetch patient data. Please try again.");
            }
          } catch (error) {
            console.error("Error fetching patient data:", error);
            alert("An error occurred. Please try again.");
          }
        };

        fetchPatientData(selectedStartDate, selectedEndDate, selectedOption);
      } else if (
        selectedName === "patient" &&
        selectedEndDate &&
        selectedStartDate
      ) {
        const url = hostInfo + "/getassesmentdata";
        const data = {
          type: selectedName,
          data_type: " ",
          start_date: st,
          end_date: en,
          username: selectedOption,
        };

        fetch(url, {
          // mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            if (data) {
              setpData(data);
              console.log(data);
            } else {
              setpData([]);
            }
          })
          .catch((error) => console.error("Error:", error));
        setChartVisible({
          showrevenu: false,
          showpatient: true,
          showvisit: false,
          showvisithistory: false,
          showreffer: false,
          showpl: false,
        });
      } else if (
        selectedName === "reffered" &&
        selectedEndDate &&
        selectedStartDate
      ) {
        let url = hostInfo + "/getassesmentdata";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            type: selectedName,
            data_type: "",
            start_date: st,
            end_date: en,
            username: selectedOption,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              console.log(data.visit_counts);
              setrData(data);
            } else {
              setrData([]);
            }
          });
        setChartVisible({
          showrevenu: false,
          showpatient: false,
          showvisit: false,
          showvisithistory: false,
          showreffer: true,
          showpl: false,
        });
      } else if (
        selectedName === "pl" &&
        selectedEndDate &&
        selectedStartDate
      ) {
        if (st < en) {
          const diff = (en - st) / 60 / 60 / 24;

          if (diff <= 30) {
            setsubtype("daily");
          } else if (diff > 30 && diff <= 365) {
            setsubtype("monthly");
          } else if (diff > 365) {
            setsubtype("yearly");
          }
        } else {
          alert("start date must be less than the end date");
          setChartVisible({
            showrevenu: false,
            showpatient: false,
            showvisit: false,
            showvisithistory: false,
            showreffer: false,
            showpl: false,
          });
          return;
        }
        let url = hostInfo + "/getassesmentdata";
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            type: selectedName,
            data_type: subtype,
            start_date: st,
            end_date: en,
            username: selectedOption,
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("HTTP request failed");
            }
          })
          .then((res) => {
            console.log(res);
            if (res && res.graph_data.data && res.graph_data.data.length > 0) {
              // Data is available
              setdata(res);
              if (enableflag === 1) {
                setappendedData(
                  res.expenses
                    ? [
                        {
                          amount: res.total,
                          date_time:
                            selectedStartDate + " to " + selectedEndDate, // Replace with the actual start date
                          purpose: "visit revenue",
                        },
                        ...res.expenses,
                      ]
                    : [
                        {
                          amount: res.total,
                          date_time:
                            selectedStartDate + " to " + selectedEndDate, // Replace with the actual start date
                          purpose: "visit revenue",
                        },
                      ]
                );
              }

              setgraphData(res.graph_data.data);

              if (subtype === "daily") {
                const dateString = res.graph_data.data[0].x_val.toString();
                // const dateString = res.revenue.graphData.map(
                //   (i) => i.value.map((i) => i.name)[0]
                // )[0];
                // console.log(dateString);
                const parts = dateString.split("/");
                // const day = parseInt(parts[1], 10);
                const month = parseInt(parts[0], 10) - 1;
                const year = parseInt(parts[2], 10);

                const startDate = new Date(year, month, 1);
                const endDate = new Date(year, month + 1, 0);

                for (
                  let date = startDate;
                  date <= endDate;
                  date.setDate(date.getDate() + 1)
                ) {
                  const formattedDate = `${String(date.getMonth() + 1).padStart(
                    2,
                    "0"
                  )}/${String(date.getDate()).padStart(
                    2,
                    "0"
                  )}/${date.getFullYear()}`;
                  datesInMonth.push(formattedDate);
                  // console.log(datesInMonth);
                  setgraphtype(datesInMonth);
                }
                setChartVisible({
                  showrevenu: false,
                  showpatient: false,
                  showvisit: false,
                  showvisithistory: false,
                  showreffer: false,
                  showpl: true,
                });
              } else if (subtype === "monthly") {
                setgraphtype(months);
                setChartVisible({
                  showrevenu: false,
                  showpatient: false,
                  showvisit: false,
                  showvisithistory: false,
                  showreffer: false,
                  showpl: true,
                });
              } else if (subtype === "yearly") {
                // const dateString =
                //   res.revenue.graph_data.data[0].x_val.toString();
                // const lastValue = (parseInt(dateString, 10) - 1).toString();
                // const newValue = (parseInt(dateString, 10) + 1).toString();
                const graphData = res.graph_data.data.map((i) => i.x_val); // Assuming x_val contains the year

                // Find the maximum year in the data
                const maxYear = Math.max(...graphData);

                // Add missing years up to 6 years if needed
                const currentYear = new Date().getFullYear(); // Get the current year
                const yearsToAdd = [];

                for (let year = maxYear + 1; year <= currentYear + 5; year++) {
                  yearsToAdd.push(year); // Add the missing years up to 6 years
                }

                // Combine the original data with the missing years
                const completeGraphData = [...graphData, ...yearsToAdd];

                setgraphtype(completeGraphData);
                // setgraphtype(res.graph_data.data.map((i) => i.x_val));
                setChartVisible({
                  showrevenu: false,
                  showpatient: false,
                  showvisit: false,
                  showvisithistory: false,
                  showreffer: false,
                  showpl: true,
                });
              }
            } else {
              // alert("No data found or invalid response structure");
              // console.log("Data not found");
              setdata([]);
              setgraphData([]);
              setChartVisible({
                showrevenu: false,
                showpatient: false,
                showvisit: false,
                showvisithistory: false,
                showreffer: false,
                showpl: false,
              });
            }
          })
          .catch((error) => {
            // alert("No data found or invalid response structure");
            // console.error("Error fetching data:", error);
            // Handle error, set state accordingly
            setdata([]);
            setgraphData([]);
            setChartVisible({
              showrevenu: false,
              showpatient: false,
              showvisit: false,
              showvisithistory: false,
              showreffer: false,
              showpl: false,
            });
          });
      }
      // const formattedStartDate = formatDate(selectedStartDate);
      // const formattedEndDate = formatDate(selectedEndDate);

      // fetchPatientData(formattedStartDate, formattedEndDate, username);
    };
  };

  // const handleFetchAllButtonClick = () => {
  //   if (!selectedStartDate || !selectedEndDate) {
  //     alert("Please select both start and end dates.");
  //     return;
  //   }
  //   const formattedStartDate = formatDate(selectedStartDate);
  //   const formattedEndDate = formatDate(selectedEndDate);
  //   fetchPatientData(formattedStartDate, formattedEndDate, "alluser");
  // };
  const handleSubmit = () => {
    // if (selectedOption === "alluser") {
    //   handleFetchAllButtonClick();
    // } else {
    handleFetchButtonClick(selectedName)();
    // }
  };
  return (
    <>
      <div>
        <form autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              container
              spacing={4}
              justifyContent="space-around"
              // justifyContent="center"
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Grid item xs={12} sm={3}>
                <FormControl
                  // style={{ padding: "12px 0px 0px 0px" }}
                  //   className={classes.addressControl}
                  fullWidth
                  // margin="normal"
                >
                  {/* Label for Filter by Purpose */}
                  <InputLabel id="filter-purpose-label">
                    {" "}
                    Select Doctor
                  </InputLabel>
                  <Select
                    labelId="filter-purpose-label" // Associates the label with this Select
                    id="filter-dropdown"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    label="Select Option"
                  >
                    <MenuItem value="alluser">All</MenuItem>
                    {user.map((i) => (
                      <MenuItem key={i.username} value={i.username}>
                        {i.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={3}>
                  <Box
                    border={1}
                    borderRadius={1}
                    borderColor="#adb7bb"
                    // padding={0.5}
                    // width="auto" // Adjust width to the content size
                    height={58}
                    // marginTop={2}
                  >
                    <KeyboardDatePicker
                      fullWidth
                      // margin="normal"
                      id="date-picker-dialog-start"
                      label="From"
                      format="MM/dd/yyyy" // Ensures date is displayed in MM/DD/YYYY format
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      InputProps={{
                        disableUnderline: true,

                        // Disable the default bottom underline
                      }}
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          paddingLeft: "20px",
                        }, // Center the label text
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box
                    border={1}
                    borderRadius={1}
                    borderColor="#adb7bb"
                    // marginTop={2}
                    // padding={0.5} // Adjust padding to make the box smaller
                    // width="auto" // Adjust width to the content size
                    height={58}
                  >
                    <KeyboardDatePicker
                      fullWidth
                      // margin="normal"
                      id="date-picker-dialog-end"
                      label="To"
                      format="MM/dd/yyyy" // Ensures date is displayed in MM/DD/YYYY format
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      InputProps={{
                        disableUnderline: true,

                        // Disable the default bottom underline
                      }}
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          // textAlign: "left",
                          // width: "100%",
                          paddingLeft: "20px",
                        }, // Center the label text
                      }}
                    />
                  </Box>
                </Grid>{" "}
              </MuiPickersUtilsProvider>
              <Grid item xs={12} sm={3}>
                <FormControl
                  // style={{ padding: "12px 0px 0px 0px" }}
                  //   className={classes.addressControl}
                  fullWidth
                  // margin="normal"
                >
                  {/* Label for Filter by Purpose */}
                  <InputLabel id="filter-purpose-label">
                    {" "}
                    Select Info
                  </InputLabel>
                  <Select
                    labelId="filter-purpose-label" // Associates the label with this Select
                    id="filter-dropdown"
                    value={selectedName}
                    onChange={handleNameChange}
                    label="Select Option"
                  >
                    {/* <MenuItem value="visithistory">Visit History</MenuItem> */}
                    <MenuItem value="patient">Patient Report</MenuItem>
                    <MenuItem value="visit">Visit Report</MenuItem>
                    <MenuItem value="revenue">Revenue Report</MenuItem>
                    <MenuItem value="pl">Profit / Loss Report</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid xs={12} sm={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={7} justifyContent="space-around">
                
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid> */}
            </Grid>
            {/* <Grid
              container
              spacing={2}
              justifyContent="space-around"
              style={{ marginBottom: "20px" }}
            >
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog-start"
                  label="From"
                  format="dd-MM-yyyy"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog-end"
                  label="To"
                  format="dd-MM-yyyy"
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid> */}
          </MuiPickersUtilsProvider>

          <Grid container spacing={2} justifyContent="center">
            {/* <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={patientInfo}
              >
                Patient Info
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={visitInfo}
              >
                Visit Info
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={revenuInfo}
              >
                Revenue Info
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={PLInfo}
              >
                Profit / Loss
              </Button>
            </Grid> */}
            {/* <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={refferInfo}
              >
                Referred Info
              </Button>
            </Grid> */}
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={handleReset}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      {chartVisible.showrevenu && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                // marginBottom: "30px",
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a", paddingTop: "10px" }}>
                1. Revenue Info
              </h5>
              <h6 style={{ marginLeft: "32px", color: "#546e7a" }}>
                Total (Rs.):{" "}
                <span style={{ color: "#263238" }}>{data.total}</span>
              </h6>
              <h6 style={{ marginLeft: "32px", color: "#546e7a" }}>
                Paid (Rs.):{" "}
                <span style={{ color: "#263238" }}>{data.paid}</span>
              </h6>
              <h6 style={{ marginLeft: "32px", color: "#546e7a" }}>
                Remain (Rs.):{" "}
                <span style={{ color: "#263238" }}>{data.remain}</span>
              </h6>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              {subtype === "monthly" ? (
                <h5 style={{ color: "#546e7a" }}>
                  2. Month wise revenue graph
                </h5>
              ) : subtype === "daily" ? (
                <h5 style={{ color: "#546e7a" }}>2. Day wise revenue graph</h5>
              ) : (
                <h5 style={{ color: "#546e7a" }}>2. Year wise revenue graph</h5>
              )}

              <div
                style={{
                  border: "2px solid",
                  width: "100%",
                  maxWidth: "1000px",
                  margin: "0px auto 20px",
                  // height: 360,
                  // width: 1000,
                  // margin: "0px auto 20px",
                }}
              >
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              {subtype === "monthly" ? (
                <h5 style={{ color: "#546e7a" }}>
                  3. Month wise revenue table
                </h5>
              ) : subtype === "daily" ? (
                <h5 style={{ color: "#546e7a" }}> 3. Day wise revenue table</h5>
              ) : (
                <h5 style={{ color: "#546e7a" }}>
                  {" "}
                  3. Year wise revenue table
                </h5>
              )}

              <TableContainer
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "20px auto 10px",
                // }}
                style={{
                  width: "100%", // Full width
                  maxWidth: "1000px", // Maximum width for larger screens
                  margin: "0 auto 20px", // Centered with margins
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  {subtype === "monthly" ? (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell>Month</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.total}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.paid}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : subtype === "daily" ? (
                    <>
                      {" "}
                      <TableHead>
                        <TableRow>
                          <TableCell>Day</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.total}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.paid}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : (
                    <>
                      {" "}
                      <TableHead>
                        <TableRow>
                          <TableCell>Year</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.total}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.paid}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
            </div>
            {enableflag === 1 && data?.expenses && (
              <div>
                {subtype === "monthly" ? (
                  <h5 style={{ color: "#546e7a" }}>
                    {" "}
                    4. Month wise profit loss table
                  </h5>
                ) : subtype === "daily" ? (
                  <h5 style={{ color: "#546e7a" }}>
                    {" "}
                    4. Day wise profit loss table
                  </h5>
                ) : (
                  <h5 style={{ color: "#546e7a" }}>
                    {" "}
                    4. Year wise profit loss table
                  </h5>
                )}

                <TableContainer
                  // style={{
                  //   height: "100%",
                  //   width: 1000,
                  //   margin: "20px auto 10px",
                  // }}
                  style={{
                    width: "100%", // Full width
                    maxWidth: "1000px", // Maximum width for larger screens
                    margin: "0 auto 20px", // Centered with margins
                  }}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    {subtype === "monthly" ? (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell>Month</TableCell>
                            <TableCell align="right">Total</TableCell>
                            {/* <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appendedData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    {i.date_time.includes("to")
                                      ? // Handle the case where date_time is a range
                                        i.date_time
                                      : // Convert a single date to the desired format
                                        new Date(
                                          i.date_time
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })}
                                  </TableCell>{" "}
                                  <TableCell align="right">
                                    {i.purpose}
                                  </TableCell>
                                  <TableCell align="right">
                                    {i.amount}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    ) : subtype === "daily" ? (
                      <>
                        {" "}
                        <TableHead>
                          <TableRow>
                            <TableCell>Day</TableCell>
                            <TableCell align="center">Purpose</TableCell>
                            <TableCell align="right">Total</TableCell>
                            {/* <TableCell align="right">Remain</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appendedData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {" "}
                                  <TableCell>
                                    {i.date_time.includes("to")
                                      ? // Handle the case where date_time is a range
                                        i.date_time
                                      : // Convert a single date to the desired format
                                        new Date(
                                          i.date_time
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i.purpose}
                                  </TableCell>
                                  <TableCell align="right">
                                    {i.amount}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    ) : (
                      <>
                        {" "}
                        <TableHead>
                          <TableRow>
                            <TableCell>Year</TableCell>
                            <TableCell align="right">Purpose</TableCell>
                            <TableCell align="right">Total</TableCell>
                            {/* <TableCell align="right">Remain</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appendedData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {" "}
                                  <TableCell>
                                    {i.date_time.includes("to")
                                      ? // Handle the case where date_time is a range
                                        i.date_time
                                      : // Convert a single date to the desired format
                                        new Date(
                                          i.date_time
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })}
                                  </TableCell>
                                  <TableCell align="right">
                                    {i.purpose}
                                  </TableCell>
                                  <TableCell align="right">
                                    {i.amount}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                <h6 style={{ marginLeft: "32px" }}>
                  Profit (Rs.):{" "}
                  {data?.total -
                    data?.expenses
                      ?.map((i) => i.amount)
                      ?.reduce((x, y) => x + y, 0)}
                </h6>
                <h6 style={{ marginLeft: "32px", marginBottom: "15px" }}>
                  Total Profit (Rs.):{" "}
                  {data?.total -
                    (data?.expenses
                      ?.map((i) => i.amount)
                      ?.reduce((x, y) => x + y, 0) +
                      data.remain)}
                </h6>
                {/* <h6 style={{ marginLeft: "32px" }}>
                Remain (Rs.): {data.remain}
              </h6> */}
              </div>
            )}
          </div>
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                size="small"
                // onClick={handlePrintClick}
              >
                Print
              </Button>
            )}
            content={() => componentRef}
            // onBeforeGetContent={() => Promise.resolve()}
            // onAfterPrint={() => setIsPrinting(false)}
          />
        </>
      )}

      {chartVisible.showpatient && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                // marginBottom: "20px",
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a", paddingTop: "10px" }}>
                1. Patient Info
              </h5>
              <h6 style={{ marginLeft: "32px", color: "#546e7a" }}>
                Total New Patients:{" "}
                <span style={{ color: "#263238" }}>
                  {" "}
                  {pData?.genderwise
                    ? Object.values(pData.genderwise).reduce(
                        (sum, value) => sum + value,
                        0
                      )
                    : 0}
                </span>
              </h6>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a" }}>2. Genderwise patient table</h5>
              <TableContainer
                // style={{
                //   height: 200,
                //   width: 1000,
                //   margin: "20px auto -50px",
                // }}
                style={{
                  maxHeight: "200px",
                  margin: "0 auto 20px",
                  width: "100%",
                  maxWidth: "1000px",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">Male</TableCell>
                      <TableCell align="center">
                        {pData.genderwise?.male}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">Female</TableCell>
                      <TableCell align="center">
                        {pData.genderwise?.female}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a" }}>3. Genderwise patient graph</h5>
              <div
                style={{
                  border: "2px solid",
                  // height: 360,
                  // width: 1000,
                  width: "100%",
                  maxWidth: "1000px",
                  margin: "0px auto 20px",
                }}
              >
                <ReactApexChart
                  type="pie"
                  options={poptions}
                  series={pseries}
                  height={350}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: "20px",
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a" }}>4. New Patient Info</h5>
              {/* <TableContainer
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "0px auto 10px",
                // }}
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  margin: "0 auto 20px",
                  maxWidth: "1000px",
                }}
              > */}
              <TableContainer
                className="print-table-container"
                style={{
                  maxHeight: "400px", // This will apply on screen
                  width: "100%",
                  margin: "0 auto 20px",
                  maxWidth: "1000px",
                }}
              >
                <style>
                  {`
      @media print {
        .print-table-container {
          max-height: none !important; /* Removes the height restriction */
          overflow: visible !important; /* Ensures all content is visible */
        }
      }
    `}
                </style>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">age</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">gender</TableCell>
                      <TableCell align="center">mobile</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pData && pData.patient_list ? (
                      pData.patient_list.map((i) => (
                        <TableRow
                          key={i.x_val} // Assuming x_val is unique, use an appropriate unique key
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">
                            {i.firstname} {i.lastname}
                          </TableCell>
                          <TableCell align="center">{i.curr_age}</TableCell>
                          <TableCell align="center">
                            {new Date(i.date_time).toISOString().split("T")[0]}
                          </TableCell>
                          <TableCell align="center">{i.gender}</TableCell>
                          <TableCell align="center">{i.mobile}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a" }}>
                5. Top 10 most visited patient
              </h5>
              <TableContainer
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "0px auto 10px",
                // }}
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  margin: "0 auto 20px",
                  maxWidth: "1000px",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">age</TableCell>
                      <TableCell align="center">Count</TableCell>
                      <TableCell align="center">gender</TableCell>
                      <TableCell align="center">mobile</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pData && pData.graph_data?.data ? (
                      pData.graph_data.data.map((i) => (
                        <TableRow
                          key={i.x_val} // Assuming x_val is unique, use an appropriate unique key
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">{i.x_val}</TableCell>
                          <TableCell align="center">{i.y_val.age}</TableCell>
                          <TableCell align="center">{i.y_val.count}</TableCell>
                          <TableCell align="center">{i.y_val.gender}</TableCell>
                          <TableCell align="center">{i.y_val.mobile}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </>
      )}
      {chartVisible.showvisit && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                // marginBottom: "20px",
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a", paddingTop: "10px" }}>
                1. Visit Info
              </h5>
              <h6 style={{ marginLeft: "32px", color: "#546e7a" }}>
                Total Visit:{" "}
                <span style={{ color: "#263238" }}>{data.total}</span>
              </h6>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              {subtype === "monthly" ? (
                <h5 style={{ color: "#546e7a" }}> 2. Month wise visit graph</h5>
              ) : subtype === "daily" ? (
                <h5 style={{ color: "#546e7a" }}> 2. Day wise visit graph</h5>
              ) : (
                <h5 style={{ color: "#546e7a" }}> 2. Year wise visit graph</h5>
              )}

              <div
                style={{
                  border: "2px solid",
                  // height: 360,
                  // width: 1000,
                  width: "100%",
                  maxWidth: "1000px",
                  margin: "0px auto 20px",
                }}
              >
                <ReactApexChart
                  options={voptions}
                  series={vseries}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              {subtype === "monthly" ? (
                <h5 style={{ color: "#546e7a" }}> 3. Month wise visit table</h5>
              ) : subtype === "daily" ? (
                <h5 style={{ color: "#546e7a" }}> 3. Day wise visit table</h5>
              ) : (
                <h5 style={{ color: "#546e7a" }}> 3. Year wise visit table</h5>
              )}

              <TableContainer
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "20px auto 10px",
                // }}
                style={{
                  width: "100%", // Full width
                  maxWidth: "1000px", // Maximum width for larger screens
                  margin: "0 auto 20px", // Centered with margins
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  {subtype === "monthly" ? (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell>Month</TableCell>
                          <TableCell align="right">Visit Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData.length > 0 ? (
                          graphData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{i.x_val}</TableCell>
                                  <TableCell align="right">
                                    {i.y_val.count}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={8} align="center">
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </>
                  ) : subtype === "daily" ? (
                    <>
                      {" "}
                      <TableHead>
                        <TableRow>
                          <TableCell>Day</TableCell>
                          <TableCell align="right">Visit Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData.length > 0 ? (
                          graphData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{i.x_val}</TableCell>
                                  <TableCell align="right">
                                    {i.y_val.count}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={8} align="center">
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </>
                  ) : (
                    <>
                      {" "}
                      <TableHead>
                        <TableRow>
                          <TableCell>Year</TableCell>
                          <TableCell align="right">Visit Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData.length > 0 ? (
                          graphData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{i.x_val}</TableCell>
                                  <TableCell align="right">
                                    {i.y_val.count}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={8} align="center">
                              No data available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                marginTop: "20px",
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a" }}>4. Visit Summary</h5>
              <TableContainer
                className="print-table-container"
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "0px auto 10px",
                // }}
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  margin: "0 auto 20px",
                  maxWidth: "1000px",
                }}
              >
                <style>
                  {`
      @media print {
        .print-table-container {
          max-height: none !important; /* Removes the height restriction */
          overflow: visible !important; /* Ensures all content is visible */
        }
      }
    `}
                </style>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Age</TableCell>
                      <TableCell align="center">Gender</TableCell>
                      <TableCell align="center">Diagnosis</TableCell>
                      <TableCell align="center">Total</TableCell>
                      <TableCell align="center">Paid</TableCell>
                      <TableCell align="center">Pending</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patientList.length > 0 ? (
                      patientList?.map((i) => (
                        <TableRow
                          // key={i.x_val} // Assuming x_val is unique, use an appropriate unique key
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">
                            {i.firstname} {i.lastname}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(i.date_time)
                              .getUTCDate()
                              .toString()
                              .padStart(2, "0") +
                              "-" +
                              (new Date(i.date_time).getUTCMonth() + 1)
                                .toString()
                                .padStart(2, "0") +
                              "-" +
                              new Date(i.date_time).getUTCFullYear()}
                          </TableCell>
                          <TableCell align="center">{i.age}</TableCell>
                          <TableCell align="center">{i.gender}</TableCell>
                          <TableCell align="center">
                            {JSON.parse(i.dr_notes)}
                          </TableCell>
                          <TableCell align="center">{i.amount}</TableCell>
                          <TableCell align="center">{i.paid_amount}</TableCell>
                          <TableCell align="center">
                            {i.amount - i.paid_amount}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </>
      )}
      {chartVisible.showreffer && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                marginBottom: "30px",
              }}
            >
              <h5 style={{ color: "#546e7a", paddingTop: "10px" }}>
                1. Reffered Info
              </h5>
              <h6 style={{ marginLeft: "32px" }}>
                Total reffered patient:{" "}
                {rData?.visit_counts
                  ?.map((i) => i.visit_count)
                  ?.reduce((x, y) => x + y, 0)}
              </h6>
            </div>
            <div>
              <h5 style={{ color: "#546e7a" }}>2. Reffered table (Dr. wise)</h5>
              <TableContainer
                // style={{
                //   height: 200,
                //   width: 1000,
                //   margin: "20px auto -50px",
                // }}
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  margin: "10px auto 20px",
                  maxWidth: "1000px",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Dr. name</TableCell>
                      <TableCell align="center">Patient count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rData && rData.visit_counts ? (
                      rData.visit_counts.map((i) => (
                        <TableRow
                          // key={i.x_val} // Assuming x_val is unique, use an appropriate unique key
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">{i.table_name}</TableCell>
                          <TableCell align="center">{i.visit_count}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </>
      )}

      {chartVisible.showpl && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                // marginBottom: "30px",
                width: "100%",
                maxWidth: "1000px",
                margin: "0px auto 20px",
              }}
            >
              <h5 style={{ color: "#546e7a", paddingTop: "10px" }}>
                1. Revenue Info
              </h5>
              <div
                style={{
                  display: "flex",

                  flexWrap: "wrap",
                  // justifyContent: "space-between",
                  padding: "0 60px",
                }}
              >
                {[
                  {
                    label: "Total (Rs.)",
                    value: data.total,
                    color: "#2979ff",
                    borderColor: "#2979ff",
                  },
                  {
                    label: "Paid (Rs.)",
                    value: data.paid,
                    color: "#D1D1D1",
                    borderColor: "#D1D1D1",
                  },
                  {
                    label: "Remain (Rs.)",
                    value: data.remain,
                    color: "#FF7396",
                    borderColor: "#FF7396",
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    style={{
                      textAlign: "center",
                      marginRight: index < 2 ? "105px" : "0",
                    }}
                  >
                    <h6 style={{ color: "#546e7a" }}>{item.label}</h6>
                    <Typography color="textPrimary" align="center">
                      <span
                        style={{
                          color: "#fff",
                          backgroundColor: item.color,
                          fontSize: "14px",
                          padding: "3px 10px",
                          borderRadius: "5px",
                          border: `1px solid ${item.borderColor}`,
                        }}
                      >
                        {item.value}
                      </span>
                    </Typography>
                  </div>
                ))}
              </div>
            </div>

            {enableflag === 1 && data && (
              <>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "1000px",
                    margin: "0px auto 20px",
                  }}
                >
                  {subtype === "monthly" ? (
                    <h5 style={{ color: "#546e7a" }}> 2. Profit loss table</h5>
                  ) : subtype === "daily" ? (
                    <h5 style={{ color: "#546e7a" }}> 2. Profit loss table</h5>
                  ) : (
                    <h5 style={{ color: "#546e7a" }}> 2. Profit loss table</h5>
                  )}

                  <TableContainer
                    // style={{
                    //   height: "100%",
                    //   width: 1000,
                    //   margin: "20px auto 10px",
                    // }}
                    style={{
                      width: "100%", // Full width
                      maxWidth: "1000px", // Maximum width for larger screens
                      margin: "0 auto 20px", // Centered with margins
                    }}
                  >
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      {subtype === "monthly" ? (
                        <>
                          <TableHead>
                            <TableRow>
                              <TableCell>Month</TableCell>
                              <TableCell align="center">Info</TableCell>
                              <TableCell align="center">Purpose</TableCell>
                              <TableCell align="right">Total</TableCell>
                              {/* <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {appendedData?.map((i) => {
                              return (
                                <>
                                  {" "}
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell>
                                      {i.date_time.includes("to")
                                        ? // Handle the case where date_time is a range
                                          i.date_time
                                        : // Convert a single date to the desired format
                                          new Date(
                                            i.date_time
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                          })}
                                    </TableCell>{" "}
                                    <TableCell align="center">
                                      {i.purpose === "visit revenue"
                                        ? "Income"
                                        : "expenses"}
                                    </TableCell>
                                    <Tooltip title={i.purpose}>
                                      <TableCell align="center">
                                        {i.purpose.length > 20
                                          ? `${i.purpose.substring(0, 18)}...`
                                          : i.purpose}
                                      </TableCell>
                                    </Tooltip>
                                    <TableCell align="right">
                                      {i.purpose === "visit revenue"
                                        ? "+"
                                        : "-"}{" "}
                                      {i.amount}
                                    </TableCell>
                                    {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        </>
                      ) : subtype === "daily" ? (
                        <>
                          {" "}
                          <TableHead>
                            <TableRow>
                              <TableCell>Day</TableCell>
                              <TableCell align="center">Info</TableCell>
                              <TableCell align="center">Purpose</TableCell>
                              <TableCell align="right">Total</TableCell>
                              {/* <TableCell align="right">Remain</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {appendedData?.map((i) => {
                              return (
                                <>
                                  {" "}
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    {" "}
                                    <TableCell>
                                      {i.date_time.includes("to")
                                        ? // Handle the case where date_time is a range
                                          i.date_time
                                        : // Convert a single date to the desired format
                                          new Date(
                                            i.date_time
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                          })}
                                    </TableCell>
                                    <TableCell align="center">
                                      {i.purpose === "visit revenue"
                                        ? "Income"
                                        : "expenses"}
                                    </TableCell>
                                    <Tooltip title={i.purpose}>
                                      <TableCell align="center">
                                        {i.purpose.length > 20
                                          ? `${i.purpose.substring(0, 18)}...`
                                          : i.purpose}
                                      </TableCell>
                                    </Tooltip>
                                    <TableCell align="right">
                                      {i.purpose === "visit revenue"
                                        ? "+"
                                        : "-"}{" "}
                                      {i.amount}
                                    </TableCell>
                                    {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        </>
                      ) : (
                        <>
                          {" "}
                          <TableHead>
                            <TableRow>
                              <TableCell>Year</TableCell>
                              <TableCell align="center">Info</TableCell>
                              <TableCell align="right">Purpose</TableCell>
                              <TableCell align="right">Total</TableCell>
                              {/* <TableCell align="right">Remain</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {appendedData?.map((i) => {
                              return (
                                <>
                                  {" "}
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    {" "}
                                    <TableCell>
                                      {i.date_time.includes("to")
                                        ? // Handle the case where date_time is a range
                                          i.date_time
                                        : // Convert a single date to the desired format
                                          new Date(
                                            i.date_time
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                          })}
                                    </TableCell>
                                    <TableCell align="center">
                                      {i.purpose === "visit revenue"
                                        ? "Income"
                                        : "expenses"}
                                    </TableCell>
                                    <Tooltip title={i.purpose}>
                                      <TableCell align="center">
                                        {i.purpose}
                                      </TableCell>
                                    </Tooltip>
                                    <TableCell align="right">
                                      {i.purpose === "visit revenue"
                                        ? "+"
                                        : "-"}{" "}
                                      {i.amount}
                                    </TableCell>
                                    {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        </>
                      )}
                    </Table>
                  </TableContainer>

                  {/* <h6 style={{ marginLeft: "32px" }}>
                Remain (Rs.): {data.remain}
              </h6> */}
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      // paddingLeft: "70px",
                      flexWrap: "wrap",
                    }}
                  >
                    {[
                      {
                        label: "Total (Rs.):",
                        value: data?.total,
                        color: "#2979ff",
                        borderColor: "#2979ff",
                      },
                      {
                        label: "Profit / Loss (Rs.):",
                        value:
                          data?.total -
                          (data?.expenses?.length
                            ? data?.expenses
                                .map((i) => i.amount)
                                .reduce((x, y) => x + y, 0)
                            : 0),
                        color: "#FFD700",
                        borderColor: "#FFD700",
                      },
                      {
                        label: "Remain (Rs.):",
                        value: data?.remain,
                        color: "#FF7396",
                        borderColor: "#FF7396",
                      },
                      {
                        label: "Absolute Profit / Loss (Rs.):",
                        value:
                          data?.total -
                          ((data?.expenses?.length
                            ? data?.expenses
                                .map((i) => i.amount)
                                .reduce((x, y) => x + y, 0)
                            : 0) -
                            data?.remain),
                        color:
                          data?.total -
                            ((data?.expenses?.length
                              ? data?.expenses
                                  .map((i) => i.amount)
                                  .reduce((x, y) => x + y, 0)
                              : 0) -
                              data?.remain) ===
                          0
                            ? "#D1D1D1"
                            : data?.total -
                                ((data?.expenses?.length
                                  ? data?.expenses
                                      .map((i) => i.amount)
                                      .reduce((x, y) => x + y, 0)
                                  : 0) -
                                  data?.remain) >
                              0
                            ? "#76BA99"
                            : "#dc3545",
                        borderColor:
                          data?.total -
                            ((data?.expenses?.length
                              ? data?.expenses
                                  .map((i) => i.amount)
                                  .reduce((x, y) => x + y, 0)
                              : 0) -
                              data?.remain) ===
                          0
                            ? "#D1D1D1"
                            : data?.total -
                                ((data?.expenses?.length
                                  ? data?.expenses
                                      .map((i) => i.amount)
                                      .reduce((x, y) => x + y, 0)
                                  : 0) -
                                  data?.remain) >
                              0
                            ? "#76BA99"
                            : "#dc3545",
                      },
                    ].map((item, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "15px",
                          minWidth: "200px",
                          textAlign: "center",
                        }}
                      >
                        <h6 style={{ color: "#546e7a" }}>{item.label}</h6>
                        <Typography color="textPrimary" align="center">
                          <span
                            style={{
                              color: "#fff",
                              backgroundColor: item.color,
                              fontSize: "14px",
                              padding: "3px 10px",
                              borderRadius: "5px",
                              border: `1px solid ${item.borderColor}`,
                            }}
                          >
                            {item.value}
                          </span>
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </>
      )}

      {/* {chartVisible.showvisithistory && patientList.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <PatientsListTbl patientList={patientList} />
        </div>
      )} */}

      {/* Mitesh :coming soon banner */}
      {/* <div>
        <CardContent className={classes.content}>
          <div style={{ position: "relative" }}>
            <CardMedia
              component="img"
              className={classes.media}
              image="https://picsum.photos/1000/400"
            />
            <div
              style={{
                position: "absolute",
                color: "white",
                top: 80,
                fontSize: 80,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              Coming Soon
            </div>
          </div>
        </CardContent>
      </div> */}
      {/* <div></div> */}
    </>
  );
};
export default AssForm;
