import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import ListIcon from "@material-ui/icons/List";
// import VisitListDia from "./diaComponent/VisitListDia";

// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { hostInfo } from "../../../store";
import { Button } from "@material-ui/core";
import { Box, Avatar } from "@material-ui/core";

import { useEffect } from "react";
import EditUser from "./EditUser";
import { id } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
}));

const UsersListTbl = (props) => {
  const {
    handleClickOpen,
    open,
    closeHdl,
    patientGrpList,
    handleDelPatient,
    patientList,
  } = props;

  // const [patientList, setPatientList] = useState([]);
  // const [patientGrpList, setPatientGrpList] = useState([]);
  // const [data, setData] = useState(rawData);
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [patientId, setPatientId] = useState(0);
  const [user, setUser] = useState("");
  console.log(patientList);
  // const [dialogWord, setDialogWord] = useState('');
  // const [dialogId, setDialogId] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const a = getToken();
        const b = JSON.parse(atob(a.split(".")[1]));
        const user = data.users.filter((i) => i.username === b.username);
        setUser(user);
      } catch (error) {
        console.error("There was an error fetching the user info!", error);
      }
    };

    fetchUserInfo();
  }, []);

  // const handleDialogClose = (event) => {
  //   setIsDialogOpen(false);
  // };

  const handlePatientClick = (id) => {
    const json_id = {
      patient_id: id,
      opd_id: -1,
    };
    const jsonString = JSON.stringify(json_id);

    // Encode the string to Base64
    const base64Encoded = btoa(jsonString);
    // console.log("Patient Id ----------");
    // console.log(id);
    // localStorage.setItem("opd_id", -1);
    window.location = "/patients/patientprofile?id=" + base64Encoded;
  };
  // const handleId = event => {
  //   setDialogId(event.target.value);
  // }

  // const handleWord = event => {
  //   setDialogWord(event.target.value);
  // }

  // const handleAddNewRow = event => {
  //   if (!dialogId || !dialogWord) return;

  //   setData(
  //     // Here you can add the new row to whatever index you want
  //     [{ id: dialogId, word: dialogWord }, ...data]
  //   );
  // }

  const addOpd = (patientId) => {
    const url = `${hostInfo}/addOpd`; // Replace with your actual API endpoint
    const data = {
      patient_id: patientId,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          alert("Patient added to OPD successfully");
          // Optionally refresh data or update UI
        } else {
          alert("Failed to add patient to OPD");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("There was an error adding patient to OPD");
      });
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      searchable: true,
      render: (rowData) => (
        <div
          className={classes.containerIcon}
          // onClick={(e, rowData) => handlePatientClick(e, rowData)}
        >
          <Avatar
            variant="rounded"
            className={`${rowData.status ? classes.active : classes.inactive}`}
          >
            {rowData.case_no}
          </Avatar>

          <Box fontSize={16} fontFamily="Helvetica" fontWeight={550}>
            {rowData.name}
          </Box>
        </div>
      ),
    },

    // { title: "Mail Id", field: "email" },
    {
      title: "Role",
      field: "role",
      // type: "numeric",
      searchable: true,
      // align: "center",
    },
    // {
    //   title: "Info",
    //   field: "info",
    //   // align: "center",
    //   render: (rowData) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         gap: "10px",
    //       }}
    //     >
    //       <Button
    //         // variant="outlined"
    //         // size="small"
    //         style={{
    //           backgroundColor: "#5E92C2",
    //           // borderColor: "#007bff",
    //           padding: "2px 4px",
    //           color: "white",
    //         }}
    //         onClick={() => addOpd(rowData.id)}
    //       >
    //         Add OPD
    //       </Button>
    //       {user[0].accesses_flag === 2 && (
    //         <Button
    //           // variant="outlined"
    //           // size="small"

    //           style={{
    //             backgroundColor: "#ef9a9a",
    //             // borderColor: "#007bff",
    //             padding: "2px 4px",
    //             color: "white",
    //           }}
    //           onClick={() => handlePatientClick(rowData.id)}
    //         >
    //           Without
    //         </Button>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  // useEffect(() => {
  //   let url = "http://" + hostInfo + "/getptinfo";
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res != null) {
  //         if (res != null) {
  //           console.log(res);
  //           setPatientList(res);
  //         }
  //       }
  //     });
  // }, []);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  return (
    <div className={classes.inner}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Fragment>
        <MaterialTable
          title="User List"
          columns={columns}
          data={patientList.map((patient) => ({
            name: patient.name,
            id: patient.id,
            role: patient.accesses_flag,
            // info: null,
            // email: patient.mail_id,
          }))}
          actions={[
            {
              icon: "edit",
              onClick: (event, rowData) => {
                setPatientId(rowData.id);
                let patient = patientList.find(
                  (item) => item.id === rowData.id
                );
                console.log(rowData);
                setPatientInfo(patient);
                handleClickOpen();
              },

              tooltip: "Edit",
            },
            // ...(patientList.find((i) => i.accesses_flag !== 2)
            //   ? [
            //       {
            //         icon: "delete",
            //         tooltip: "Delete User",
            //         onClick: (event, rowData) => {
            //           const url = `${hostInfo}/deleteUser?user_id=${rowData.id}`;
            //           if (
            //             window.confirm(`You want to delete ${rowData.name}`)
            //           ) {
            //             fetch(url, {
            //               method: "POST",
            //               headers: {
            //                 Accept: "*/*",
            //                 Authorization: `Bearer ${getToken()}`,
            //               },
            //             })
            //               .then(() => {
            //                 console.log(
            //                   `User ${rowData.id} deleted successfully.`
            //                 );
            //               })
            //               .catch((error) => {
            //                 console.error("There was an error!", error);
            //               });
            //           }
            //         },
            //       },
            //     ]
            //   : []),
          ]}
          options={{
            pageSizeOptions: [5, 10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#AFE1CE",
              color: "#000",
            },
            sorting: true,

            minBodyHeight: "auto",

            actionsColumnIndex: -1,
          }}
          // onRowClick={(event, rowData, togglePanel) =>
          //   handlePatientClick(rowData.id)
          // }
        />
        {open ? (
          <EditUser
            open={open}
            closeHdl={closeHdl}
            // title="Visit Details"
            // isOpen={isDialogOpen}
            // onClose={handleDialogClose}
            // patientGrpList={patientGrpList}
            classes={classes}
            patientId={patientId}
            patientInfo={patientInfo}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default UsersListTbl;
