import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import { Typography, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
    },
  },
  formFieldCls: {
    marginRight: theme.spacing(3),
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  addGroupBtn: {
    background: "#FF6085",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#9BE27A",
    },
    // marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  diaHeadText: {
    // background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    margin: theme.spacing(3),
    // display: "flex",
    // alignItems: "center",
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  innerEle: {
    // flexGrow: 1,
    paddingTop: theme.spacing(1.5),
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
}));

const GroupList = (props) => {
  const { scrHeight, scrWidth } = props;
  const [openAddDia, setOpenAddDia] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");

  const groupList = props.groupList;

  const classes = useStyles();

  const handleChange = (e) => {
    // console.log("Inside handle on click --");
    let name = e.target.name;
    let value = e.target.value;
    // console.log(name);
    // console.log(value);
    if (name === "GroupName") {
      setGroupName(value);
    }
    if (name === "GroupDescription") {
      setGroupDesc(value);
    }
  };

  const handleAdd = () => {
    // console.log("Inside handle on save");
    props.handleOnAdd(groupName, groupDesc);
    handleAddClose();
  };

  const handleAddClose = () => {
    setOpenAddDia(false);
  };

  const handleAddOpen = () => {
    setOpenAddDia(true);
  };

  return (
    <div className={classes.innerEle}>
      <Fragment>
        <Paper
          style={{
            height: scrWidth <= 599.95 ? 200 : scrHeight - 201, // Mobile view height: 80px
            overflow: "auto",
          }}
        >
          <List component="nav" aria-label="secondary mailbox folders">
            {groupList.map((grp, key) => (
              <ShowGroup
                key={key}
                group={grp}
                handleOnSave={props.handleOnSave}
                handleOnAdd={props.handleOnAdd}
                handleOnDelete={props.handleOnDelete}
                handleOnSelectGroup={props.handleOnSelectGroup}
              />
            ))}
          </List>
        </Paper>
        <Button
          variant="outlined"
          className={classes.addGroupBtn}
          onClick={handleAddOpen}
        >
          Add Group
        </Button>
      </Fragment>
      <div>
        <Dialog
          open={openAddDia}
          keepMounted
          fullWidth={true}
          // keepMounted={true}
          maxWidth={"md"}
          onClose={handleAddClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Typography
            gutterBottom
            variant="h4"
            component="h2"
            color="textSecondary"
            className={classes.diaHeadText}
            // justify="space-between"

            // margin=""
          >
            New Group
          </Typography>
          <DialogContent>
            <form noValidate autoComplete="off" onChange={handleChange}>
              <TextField
                margin="normal"
                id="group-name-id"
                name="GroupName"
                value={groupName}
                label="Group Name"
                type="string"
                className={classes.formFieldCls}

                // onChange={(e) => handleOnMedValChange(e, "medName")}
              />
              <TextField
                margin="normal"
                id="group-desc-id"
                name="GroupDescription"
                value={groupDesc}
                label="Description"
                type="string"
                // onChange={(e) => handleOnMedValChange(e, "medName")}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAdd} color="primary">
              Add
            </Button>
            <Button onClick={handleAddClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

function ShowGroup(props) {
  const [open, setOpen] = useState(false);
  const [groupName, setGroupName] = useState(props.group.name);
  const [groupDesc, setGroupDesc] = useState(props.group.description);
  const id = props.group.id;

  const classes = useStyles();

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSelectGroup = () => {
    // console.log("CLicked on button");
    props.handleOnSelectGroup(id, groupName);
  };

  const handleEdit = () => {
    // console.log("Inside handle on click 1");
    setOpen(true);
  };
  const handleDelete = () => {
    // console.log("Inside handle on delete");
    props.handleOnDelete(id);
    handleClickClose();
  };

  const handleSave = () => {
    // console.log("Inside handle on save");
    props.handleOnSave(id, groupName, groupDesc);
    handleClickClose();
  };

  const handleChange = (e) => {
    // console.log("Inside handle on click --");
    let name = e.target.name;
    let value = e.target.value;
    // console.log(name);
    // console.log(value);
    if (name === "GroupName") {
      setGroupName(value);
    }
    if (name === "GroupDescription") {
      setGroupDesc(value);
    }
  };

  return (
    <div>
      <ListItem button key={id} onClick={handleSelectGroup}>
        <ListItemText primary={groupName} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={() => handleEdit()}
            className={classes.root}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="comments"
            onClick={() => handleDelete()}
            className={classes.root}
          >
            <DeleteIcon style={{ color: "#DF646A" }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        // color={"primary"}
        fullWidth={true}
        // keepMounted={true}
        maxWidth={"md"}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">
        {"Use Google's location service?"}
      </DialogTitle> */}
        <DialogContent>
          <form noValidate autoComplete="off" onChange={handleChange}>
            <Typography
              gutterBottom
              variant="h4"
              component="h2"
              color="textSecondary"
              className={classes.diaHeadText}
              // justify="space-between"

              // margin=""
            >
              Edit Group
            </Typography>
            <TextField
              margin="normal"
              id="group-name-id"
              name="GroupName"
              value={groupName}
              label="Group Name"
              type="string"
              className={classes.formFieldCls}
              // onChange={(e) => handleOnMedValChange(e, "medName")}
            />

            <TextField
              margin="normal"
              id="group-desc-id"
              name="GroupDescription"
              value={groupDesc}
              label="Description"
              type="string"
              // onChange={(e) => handleOnMedValChange(e, "medName")}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GroupList;
