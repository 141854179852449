import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
// import { Button } from "@material-ui/core";
import ReactFileReader from "react-file-reader";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import { parse } from "csv-parse/browser/esm";
import { hostInfo } from "../../store";
import DataTables from "./DataTables";
import repIcon from "../../icons/rep.png";
import medIcon from "../../icons/meds.png";
import sympIcon from "../../icons/symp.png";
import disIcon from "../../icons/dis.png";
import Papa from "papaparse";
// import csv from "csv";
import {
  Box,
  Button,
  Typography,
  // TablePagination,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import AddReport from "./components/AddReport";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uploadBtn: {
    background: "#FF6085",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#9BE27A",
    },
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  iconCls: {
    marginRight: theme.spacing(1),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography component={"span"}>{children}</Typography>
          {/* {children} */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const validateData = (data) => {
  // console.log("Inside data validation...");
  // Data validation remain --------- TODO

  // console.log(data);

  return 0;
};

// Medicine -------------------------
const handleMedsUpload = (file) => {
  // console.log("Inside Med Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    uplofMedxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    uplofMedcsv(file[0]);
  }
  window.location.reload();
  // console.log("fie " + file[0].name + " Uploaded Successfully");
};
const getToken = () => {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});
  return cookies.authToken;
};

const uplofMedxlsx = (file) => {
  /* Boilerplate to set up FileReader */
  // const file = files[0].name;
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    // console.log("wsname ----- ", wsname);
    const ws = wb.Sheets[wsname];
    // console.log("wsname ----- ", wsname);

    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });
    if (!validateData(data)) {
      alert("uploading " + data.length + "medicine list");
      for (var i = 1; i < data.length; ) {
        var medList = [];
        for (var j = 0; i < data.length && j < 20; i++, j++) {
          if (
            data[i][0] === undefined ||
            data[i][0].match(/^ *$/) !== null ||
            data[i][1] === undefined ||
            data[i][1].match(/^ *$/) !== null
            // data[i][2] === undefined ||
            // data[i][2].match(/^ *$/) !== null
          ) {
            // console.log("Invalid medicine ----: ", data[i][0]);
            continue;
          }
          var med = {
            name: data[i][0],
            // company: data[i][1],
            type: data[i][1],
          };
          medList.push(med);
        }

        if (medList.length > 0) {
          let url = hostInfo + "/addmedlist";
          // console.log("POST data---: ", JSON.stringify(medList));
          fetch(url, {
            method: "POST",
            // mode: "no-cors",
            headers: {
              Accept: "*/*",
              "Content-type": "application/json; charset=UTF-8",

              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(medList),
          });
        }
      }
    } else {
      alert("Invalid data");
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const uplofMedcsv = (file) => {
  // Ensure a file is selected
  // console.log(file); // Log the file for debugging
  // if (!file) {
  //   alert("No file selected");
  //   return;
  // }

  // Read the file as text
  const reader = new FileReader();
  reader.onload = function (e) {
    const fileContent = e.target.result; // Get the file content as text

    // Parse the CSV content
    parse(
      fileContent,
      {
        delimiter: ",", // CSV delimiter (comma by default)
        skip_empty_lines: true, // Skip empty lines
        trim: true, // Trim spaces around values
        relax_column_count: true, // Allows rows with different column counts
      },
      (err, data) => {
        if (err) {
          console.error("Error parsing CSV:", err);
          alert("Error parsing CSV file.");
          return;
        }

        const medList = []; // Initialize the list to store medicine data

        // Iterate through the parsed data, starting from the second row (index 1)
        for (let i = 1; i < data.length; i++) {
          // Validate the required fields: name and type
          if (data[i][0]?.trim() && data[i][1]?.trim()) {
            const med = {
              name: data[i][0].trim(), // Store the medicine name
              type: data[i][1].trim(), // Store the medicine type
            };
            medList.push(med); // Add medicine object to the list
          }
        }

        // If the medList is not empty, send it to the server
        if (medList.length > 0) {
          const url = `${hostInfo}/addmedlist`; // Set the endpoint URL
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // Include authorization token
            },
            body: JSON.stringify(medList), // Send the data as JSON
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(
                  `Network response was not ok: ${response.statusText}`
                );
              }
              return response.json(); // Parse the JSON response
            })
            .then((data) => {
              console.log("Success:", data); // Handle success
            })
            .catch((error) => {
              console.error("Error:", error); // Handle errors
              // alert(
              //   "An error occurred while uploading the CSV. Please try again."
              // );
            });
        } else {
          alert("No valid medicines found in the file."); // Alert if no valid data
        }
      }
    );
  };

  // Read the file content as text
  reader.readAsText(file);
};

// Report Upload------------------------
const handleRepUpload = (file) => {
  // console.log("Inside Rep Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    uplofRepxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    uplofRepcsv(file[0]);
  }
  window.location.reload();
  // console.log("fie " + file[0].name + " Uploaded Successfully");
};

const uplofRepxlsx = (file) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      // var symList = [];
      let results = [];

      for (var j = 1; i < data.length && j < 20; i++, j++) {
        if (data[i][0] === undefined || data[i][0].match(/^ *$/) !== null) {
          continue;
        }

        var rep = {
          report: data[i][0],
        };
        results.push(rep);
      }
      // console.log("pushing to Server: ", results);

      if (results.length > 0) {
        let url = hostInfo + "/addreplist";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(results),
        });
      }
    }

    // let results = [];
    // alert(reader.result);
    // for (var i = 1; i < data.length; i++) {
    //   if (data[i][0] === undefined || data[i][0].match(/^ *$/) !== null) {
    //     continue;
    //   }
    //   var rep = {
    //     report: data[i][0],
    //   };
    //   results.push(rep);
    // }
    // if (results.length > 0) {
    //   let url = "http://" + hostInfo + "/addreplist";
    //   fetch(url, {
    //     method: "POST",
    //     mode: "no-cors",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(results),
    //   });
    // }

    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const uplofRepcsv = (file) => {
  // Ensure a file is selected
  // if (!file) {
  //   alert("No file selected");
  //   return;
  // }

  var reader = new FileReader();
  reader.onload = function (e) {
    let results = [];

    // Parse the CSV content using csv-parse (in browser-compatible mode)
    parse(
      reader.result,
      {
        delimiter: ",", // Define the CSV delimiter (comma is default)
        skip_empty_lines: true, // Skip empty lines
        trim: true, // Trim spaces around each value
        relax_column_count: true, // Allows rows with different numbers of columns
      },
      (err, data) => {
        if (err) {
          console.error("Error parsing CSV:", err);
          alert("Error parsing CSV file.");
          return;
        }

        // Loop through the parsed data and process it
        data.forEach((row) => {
          if (row[0]?.trim()) {
            // Check if the first column is not empty or just whitespace
            var rep = { report: row[0].trim() }; // Store the report name
            results.push(rep); // Add to the results array
          }
        });

        // If valid reports are found, send them to the server
        if (results.length > 0) {
          let url = `${hostInfo}/addreplist`; // Set the endpoint URL
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // Include authorization token
            },
            body: JSON.stringify(results), // Send the data as JSON
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              console.log("Success:", data); // Handle success
            })
            .catch((error) => {
              console.error("Error:", error); // Handle errors
              // alert(
              //   "An error occurred while uploading the CSV. Please try again."
              // );
            });
        } else {
          alert("No valid reports found in the file.");
        }
      }
    );
  };

  reader.readAsText(file); // Read the file as text
};

// Dr Add ------------------------------
const handleDrRefUpload = (file) => {
  // console.log("Inside DrRef Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    uplofDrRefxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    uplodDrRefcsv(file[0]);
  }
  // console.log("fie " + file[0].name + " Uploaded Successfully");
};

const uplofDrRefxlsx = (file) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      var drList = [];
      for (var j = 1; i < data.length && j < 20; i++, j++) {
        if (
          data[i][0] === undefined ||
          data[i][0].match(/^ *$/) !== null ||
          data[i][1] === undefined ||
          data[i][1].match(/^ *$/) !== null ||
          data[i][2] === undefined ||
          data[i][2].match(/^ *$/) !== null
        ) {
          continue;
        }
        var dr = {
          firstname: data[i][0],
          lastname: data[i][1],
          speciality: data[i][2],
          mobile: data[i][3],
        };
        drList.push(dr);
      }
      if (drList.length > 0) {
        let url = hostInfo + "/adddrinfo";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(drList),
        });
      }
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const uplodDrRefcsv = (file) => {
  Papa.parse(file[0], {
    complete: (results) => {
      const data = results.data; // Parsed CSV data
      if (!validateData(data)) {
        const drList = []; // Initialize the list to store doctor data
        for (let i = 0; i < data.length && i < 20; i++) {
          // Validate each row of data
          if (
            !data[i][0]?.trim() || // Check firstname
            !data[i][1]?.trim() || // Check lastname
            !data[i][2]?.trim() // Check speciality
          ) {
            continue; // Skip if any required field is empty
          }

          const dr = {
            firstname: data[i][0].trim(), // Store firstname
            lastname: data[i][1].trim(), // Store lastname
            speciality: data[i][2].trim(), // Store speciality
            mobile: data[i][3]?.trim() || null, // Store mobile, or null if not provided
          };
          drList.push(dr); // Add doctor object to the list
        }

        // If the doctor list is not empty, send it to the server
        if (drList.length > 0) {
          let url = hostInfo + "/adddrinfo"; // Set the endpoint URL
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // Include authorization token
            },
            body: JSON.stringify(drList), // Send the data as JSON
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json(); // Parse the JSON response
            })
            .then((data) => {
              console.log("Success:", data); // Handle success
            })
            .catch((error) => {
              console.error("Error:", error); // Handle errors
            });
        }
      } else {
        alert("Invalid data");
      }
    },
    header: false, // Set to true if your CSV has headers
  });
};

//Disease---------------------------------
const handleDesUpload = (file) => {
  // console.log("Inside Des Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    UploadDesxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    UploadDescsv(file[0]);
  }
  window.location.reload();
  // console.log("fie " + file[0].name + " Uploaded Successfully");
};

const UploadDesxlsx = (file) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      var desList = [];
      for (var j = 1; i < data.length && j < 20; i++, j++) {
        if (
          data[i][0] === undefined ||
          data[i][0].match(/^ *$/) !== null ||
          data[i][1] === undefined ||
          data[i][1].match(/^ *$/) !== null
        ) {
          continue;
        }
        var des = {
          name: data[i][0],
          description: data[i][1],
        };
        desList.push(des);
      }
      if (desList.length > 0) {
        let url = hostInfo + "/adddislist";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(desList),
        });
      }
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const UploadDescsv = (file) => {
  // Check if file is not provided or not a valid Blob/File
  // if (!file || !(file instanceof Blob)) {
  //   alert("No file selected or invalid file. Please upload a CSV file.");
  //   console.error("File input is empty, undefined, or not a valid file.");
  //   return;
  // }

  const reader = new FileReader();

  reader.onload = function () {
    parse(
      reader.result,
      {
        delimiter: ",",
        skip_empty_lines: true,
        trim: true,
        relax_column_count: true,
      },
      (err, data) => {
        if (err) {
          console.error("Error parsing CSV:", err);
          alert("Error parsing CSV file.");
          return;
        }

        const desList = [];

        // Process parsed data and create entries for each valid row
        for (let i = 0; i < data.length && i < 20; i++) {
          if (!data[i][0]?.trim() || !data[i][1]?.trim()) {
            continue; // Skip rows with empty required fields
          }

          const des = {
            name: data[i][0].trim(),
            description: data[i][1].trim(),
          };
          desList.push(des);
        }

        // Send valid data to the server if any entries were created
        if (desList.length > 0) {
          const url = `${hostInfo}/adddislist`;

          fetch(url, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(desList),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              console.log("Success:", data);
            })
            .catch((error) => {
              console.error("Error:", error);
              // alert(
              //   "An error occurred while uploading data. Please try again."
              // );
            });
        } else {
          alert("No valid data found in the file.");
        }
      }
    );
  };

  // Read file as text
  reader.readAsText(file);
};

//Symptoms---------------------------------
const handleSymptUpload = (file) => {
  // console.log("Inside Sympt Upload...");
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // console.log("Uploaded file is xlsx...");
    UploadSymptxlsx(file[0]);
  } else {
    // console.log("Uploaded file is not xlsx...");
    UploadSymptcsv(file[0]);
  }
  // fetchSymptom();
  // console.log("fie " + file[0].name + " Uploaded Successfully");
  window.location.reload();
};

const UploadSymptxlsx = (file) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      var symList = [];
      for (var j = 1; i < data.length && j < 20; i++, j++) {
        if (data[i][0] === undefined || data[i][0].match(/^ *$/) !== null) {
          continue;
        }
        var des = {
          name: data[i][0],
        };
        symList.push(des);
      }
      // console.log("pushing to Server: ", symList);

      if (symList.length > 0) {
        let url = hostInfo + "/addsymptlist";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(symList),
        });
      }
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);

    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const UploadSymptcsv = (file) => {
  // If file is an array, get the first element
  const selectedFile = Array.isArray(file) ? file[0] : file;

  if (!(selectedFile instanceof Blob)) {
    alert("Invalid file type. Please upload a valid CSV file.");
    return;
  }

  const reader = new FileReader();

  reader.onload = function () {
    const results = [];

    // Parse the CSV content using csv-parse
    parse(
      reader.result, // Pass the file content here
      {
        delimiter: ",", // Define the CSV delimiter (comma is default)
        skip_empty_lines: true, // Skip empty lines
        trim: true, // Trim spaces around each value
        relax_column_count: true, // Allows rows with different numbers of columns
      },
      (err, data) => {
        if (err) {
          console.error("Error parsing CSV:", err);
          alert("Error parsing CSV file.");
          return;
        }

        const desList = [];

        // Process parsed data
        for (let i = 0; i < data.length && i < 20; i++) {
          if (!data[i][0] || data[i][0].match(/^ *$/)) {
            continue; // Skip empty rows
          }
          const des = { name: data[i][0] };
          desList.push(des);
        }

        // Send valid data to the server
        if (desList.length > 0) {
          const url = hostInfo + "/addsympt";
          fetch(url, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(desList),
          });
        } else {
          alert("No valid data found in the file.");
        }
      }
    );
  };

  reader.readAsText(selectedFile); // Read the selected file as text
};

function ReportListTable(props) {
  const [reps, setReps] = React.useState([]);

  const EditData = (name) => {
    setReps(name);
  };
  const { values, open, handleClickClose } = props;
  // console.log(reps);
  const appendNewReps = (name) => {
    // console.log(name);
    setReps([...reps, name]);
  };

  const deleteData = (itemId) => {
    setReps((prevRx) => prevRx.filter((item) => !itemId.includes(item.Id)));
  };
  const fetchData = async () => {
    try {
      var url = hostInfo + "/getrepsinfo";
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // console.log("Fetched data:", data);
      if (data != null) {
        setReps(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch initial data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // The rest of your component...

  return (
    <div>
      <DataTables
        types="reps"
        dataList={reps}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewReps}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

// Medicine *************************
function MedListTable(props) {
  const [meds, setMeds] = React.useState([]);

  const EditData = (name) => {
    setMeds(name);
  };
  const appendNewMeds = (name) => {
    setMeds([...meds, name]);
  };
  const deleteData = (itemId) => {
    setMeds((prevRx) => prevRx.filter((item) => !itemId.includes(item.Id)));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchMedicine() {
      let url = hostInfo + "/getmedsinfo";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data medicine data  ----11111111111111");
          // console.log(data);
          if (data != null) setMeds(data);
        });
    }
    fetchMedicine();
  }, []);

  return (
    <div>
      <DataTables
        types="meds"
        dataList={meds}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewMeds}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function PaymentListTable(props) {
  const [payment, setPayment] = React.useState([]);

  const EditData = (name) => {
    setPayment(name);
  };
  const appendNewMeds = (name) => {
    setPayment([...payment, name]);
  };
  const deleteData = (itemId) => {
    setPayment((prevRx) => prevRx.filter((item) => !itemId.includes(item.Id)));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchMedicine() {
      let url = hostInfo + "/getpaymentinfo";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data medicine data  ----11111111111111");

          if (data != null) setPayment(data);
        });
    }
    fetchMedicine();
  }, []);

  return (
    <div>
      <DataTables
        types="payment"
        dataList={payment}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewMeds}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function ComplaintListTable(props) {
  const [complaint, setComplaint] = React.useState([]);
  const [symSug, setSymSug] = useState([]);
  const EditData = (name) => {
    setComplaint(name);
  };
  const appendNewMeds = (name) => {
    setComplaint([...complaint, name]);
  };
  const deleteData = (itemId) => {
    setComplaint((prevRx) =>
      prevRx.filter((item) => !itemId.includes(item.id))
    );
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchMedicine() {
      let url = hostInfo + "/gettemplates";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data medicine data  ----11111111111111");
          // console.log(data);
          if (data != null) setComplaint(data);
        });
    }
    fetchMedicine();
    async function fetchSymptom() {
      let url = hostInfo + "/getsymt";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Symptoms data  ----11111111111111");
          // console.log(data);
          if (data != null) setSymSug(data);
        });
    }
    fetchSymptom();
  }, []);

  return (
    <div>
      <DataTables
        types="complaint"
        dataList={complaint}
        deleteData={deleteData}
        Edit={EditData}
        symSug={symSug}
      />
      {open && (
        <AddReport
          values={values}
          symSug={symSug}
          open={open}
          appendNewItem={appendNewMeds}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function RxListTable(props) {
  const [Rx, setRx] = React.useState([]);
  const [medSug, setMedSug] = useState([]);
  // console.log(Rx);
  const EditData = (name) => {
    setRx(name);
  };
  const appendNewMeds = (name) => {
    setRx([...Rx, name]);
  };
  const deleteData = (itemId) => {
    // console.log(itemId);
    setRx((prevRx) => prevRx.filter((item) => !itemId.includes(item.id)));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchMedicine() {
      let url = hostInfo + "/getprescriptiontemplates";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data medicine data  ----11111111111111");
          // console.log(data);
          if (data != null) setRx(data);
        });
    }
    fetchMedicine();
    async function fetchMed() {
      let url = hostInfo + "/getmeds";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Symptoms data  ----11111111111111");
          // console.log(data);
          if (data != null) setMedSug(data);
        });
    }
    fetchMed();
  }, []);

  return (
    <div>
      <DataTables
        types="Rx"
        dataList={Rx}
        medSug={medSug}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          medSug={medSug}
          appendNewItem={appendNewMeds}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function IxListTable(props) {
  const [Ix, setIx] = React.useState([]);
  const [repSug, setRepSug] = useState([]);

  const EditData = (name) => {
    setIx(name);
  };
  const appendNewMeds = (name) => {
    // console.log(name);
    setIx([...Ix, name]);
  };
  const deleteData = (itemId) => {
    setIx((prevRx) => prevRx.filter((item) => !itemId.includes(item.id)));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchMedicine() {
      let url = hostInfo + "/getreporttemplates";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data medicine data  ----11111111111111");
          // console.log(data);
          if (data != null) setIx(data);
        });
    }
    fetchMedicine();
    async function fetchReport() {
      let url = hostInfo + "/getreps";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Symptoms data  ----11111111111111");
          // console.log(data);
          if (data != null) setRepSug(data);
        });
    }
    fetchReport();
  }, []);

  return (
    <div>
      <DataTables
        types="Ix"
        dataList={Ix}
        deleteData={deleteData}
        Edit={EditData}
        repSug={repSug}
      />
      {open && (
        <AddReport
          values={values}
          repSug={repSug}
          open={open}
          appendNewItem={appendNewMeds}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function AdviceListTable(props) {
  const [advice, setAdvice] = React.useState([]);
  // console.log(advice);
  const EditData = (name) => {
    setAdvice(name);
  };
  const appendNewMeds = (name) => {
    setAdvice([...advice, name]);
  };
  const deleteData = (itemId) => {
    setAdvice((prevRx) => prevRx.filter((item) => !itemId.includes(item.id)));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchMedicine() {
      let url = hostInfo + "/getadvicetemplates";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data medicine data  ----11111111111111");
          // console.log(data);
          if (data != null) setAdvice(data);
        });
    }
    fetchMedicine();
  }, []);

  return (
    <div>
      <DataTables
        types="advice"
        dataList={advice}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          // dataList={advice}
          appendNewItem={appendNewMeds}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function PaymentTemplateListTable(props) {
  const [paymentTemp, setPaymentTemp] = React.useState([]);
  const [paymentSug, setPaymentSug] = useState([]);
  // console.log(paymentSug);
  const EditData = (name) => {
    setPaymentTemp(name);
  };
  const appendNewMeds = (name) => {
    setPaymentTemp([...paymentTemp, name]);
  };
  const deleteData = (itemId) => {
    setPaymentTemp((prevRx) =>
      prevRx.filter((item) => !itemId.includes(item.id))
    );
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchMedicine() {
      let url = hostInfo + "/getpaymenttemplates";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data medicine data  ----11111111111111");
          // console.log(data);
          if (data != null) setPaymentTemp(data);
        });
    }
    fetchMedicine();
    async function fetchPayment() {
      let url = hostInfo + "/getpayment";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Symptoms data  ----11111111111111");
          // console.log(data);
          if (data != null) setPaymentSug(data);
        });
    }
    fetchPayment();
  }, []);

  return (
    <div>
      <DataTables
        types="paymentTemp"
        paymentSug={paymentSug}
        dataList={paymentTemp}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          paymentSug={paymentSug}
          values={values}
          open={open}
          appendNewItem={appendNewMeds}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function SymptListTable(props) {
  const [sympt, setSympt] = React.useState([]);
  const EditData = (name) => {
    setSympt(name);
  };
  const appendNewSymt = (name) => {
    setSympt([...sympt, name]);
  };
  const deleteData = (itemId) => {
    setSympt((prevRx) => prevRx.filter((item) => !itemId.includes(item.Id)));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchSymptom() {
      let url = hostInfo + "/getsymtinfo";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Symptoms data  ----11111111111111");
          // console.log(data);
          if (data != null) setSympt(data);
        });
    }
    fetchSymptom();
  }, []);

  return (
    <div>
      <DataTables
        types="symt"
        dataList={sympt}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewSymt}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function DistListTable(props) {
  const [dis, setDis] = React.useState([]);
  const EditData = (name) => {
    setDis(name);
  };
  const appendNewDis = (name) => {
    setDis([...dis, name]);
  };
  const deleteData = (itemId) => {
    setDis((prevRx) => prevRx.filter((item) => !itemId.includes(item.Id)));
  };
  const { values, open, handleClickClose } = props;
  useEffect(() => {
    // Fetch the reports *************************
    async function fetchDisease() {
      let url = hostInfo + "/getdisinfo";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Disease data  ----11111111111111");
          // console.log(data);
          if (data != null) setDis(data);
        });
    }
    fetchDisease();
  }, []);

  return (
    <div>
      <DataTables
        types="dise"
        dataList={dis}
        deleteData={deleteData}
        Edit={EditData}
      />
      {open && (
        <AddReport
          values={values}
          open={open}
          appendNewItem={appendNewDis}
          handleClickClose={handleClickClose} // Close the AddReport component
        />
      )}
    </div>
  );
}

function CenteredTabs() {
  const classes = useStyles();
  const tabs = [
    "reps",
    "meds",
    "symt",
    "dise",
    "payment",
    "complaint",
    "Rx",
    "Ix",
    "advice",
    "paymentTemp",
  ];
  const [values, setValues] = React.useState(tabs[0]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openDia, setOpenDia] = useState(false);

  // const [repSug, setRepSug] = useState([]);
  // const [disSug, setDisSug] = useState([]);
  // const [symSug, setSymSug] = useState([]);
  // // const [drSug, setDrSug] = useState([]);

  const [diagnosis, setDiagnosis] = useState([]);

  // const appendNewMeds = (name) => {
  //   // Use the spread operator to create a new array with the appended value
  //   setMedSug([...medSug, name]);
  // };

  // const appendNewReps = (name) => {
  //   setRepSug([...repSug, name]);
  // };

  // const appendNewDis = (name) => {
  //   setDisSug([...disSug, name]);
  // };

  // const appendNewSymt = (name) => {
  //   setSymSug([...symSug, name]);
  // };

  // const appendNewDrs = (name) => {
  //   setDrSug([...drSug, name]);
  // };

  const handleDiaClickOpen = (e, value) => {
    setOpenDia(true);
    if (value === tabs[0]) {
      setValues(tabs[0]);
    } else if (value === tabs[1]) {
      setValues(tabs[1]);
    } else if (value === tabs[2]) {
      setValues(tabs[2]);
    } else if (value === tabs[3]) {
      setValues(tabs[3]);
    } else if (value === tabs[4]) {
      setValues(tabs[4]);
    } else if (value === tabs[5]) {
      setValues(tabs[5]);
    } else if (value === tabs[6]) {
      setValues(tabs[6]);
    } else if (value === tabs[7]) {
      setValues(tabs[7]);
    } else if (value === tabs[8]) {
      setValues(tabs[8]);
    } else if (value === tabs[9]) {
      setValues(tabs[9]);
    }
  };

  const handleDiaClickClose = () => {
    setOpenDia(false);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Reports List" {...a11yProps(0)} />
        <Tab label="Medicine List" {...a11yProps(1)} />
        <Tab label="Symptom List" {...a11yProps(2)} />
        <Tab label="Disease List" {...a11yProps(3)} />
        <Tab label="Payment List" {...a11yProps(4)} />
        <Tab label="Complaints Template" {...a11yProps(5)} />
        <Tab label="Rx Template" {...a11yProps(6)} />
        <Tab label="Ix Template" {...a11yProps(7)} />
        <Tab label="Advice Template" {...a11yProps(8)} />
        <Tab label="Payment Template" {...a11yProps(9)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div
          style={{
            // maxWidth: "500px",
            // width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "flex-start", // Align content to the start
            gap: "10px", // Add spacing between elements
          }}
        >
          <ReactFileReader
            handleFiles={handleRepUpload}
            fileTypes={[".csv", ".xlsx"]}
            multipleFiles={false}
          >
            <Button
              variant="outlined"
              className={classes.uploadBtn}
              style={{
                display: "flex",
                alignItems: "center",
                // gap: "5px", // Spacing between icon and text
              }}
            >
              <img
                src={repIcon}
                width="20"
                height="20"
                alt="C"
                className={classes.iconCls}
              />
              Upload Report list
            </Button>
          </ReactFileReader>
          <Button
            value={tabs[0]}
            variant="outlined"
            style={{
              marginTop: "7px",
              backgroundColor: "#5E92C2",
              alignSelf: "flex-start", // Align to the start for consistency
            }}
            onClick={(e) => handleDiaClickOpen(e, tabs[0])}
          >
            Add Report
          </Button>
        </div>
        <ReportListTable
          types="report"
          values={values}
          open={openDia}
          // appendNewItem={appendNewReps}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          style={{
            // maxWidth: "500px",
            // width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "flex-start", // Align content to the start
            gap: "10px", // Add spacing between elements
          }}
        >
          <ReactFileReader
            handleFiles={handleMedsUpload}
            fileTypes={[".csv", ".xlsx"]}
            multipleFiles={false}
          >
            <Button
              variant="outlined"
              className={classes.uploadBtn}
              style={{
                display: "flex",
                alignItems: "center",
                // gap: "5px", // Spacing between icon and text
              }}
            >
              <img
                src={medIcon}
                width="20"
                height="20"
                alt="C"
                className={classes.iconCls}
              />
              Upload Medicine list
            </Button>
          </ReactFileReader>
          <Button
            value={tabs[1]}
            variant="outlined"
            style={{
              marginTop: "7px",
              backgroundColor: "#5E92C2",
              alignSelf: "flex-start", // Align to the start for consistency
            }}
            onClick={(e) => handleDiaClickOpen(e, tabs[1])}
          >
            Add Medicine
          </Button>
        </div>
        <MedListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div
          style={{
            // maxWidth: "500px",
            // width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "flex-start", // Align content to the start
            gap: "10px", // Add spacing between elements
          }}
        >
          <ReactFileReader
            handleFiles={handleSymptUpload}
            fileTypes={[".csv", ".xlsx"]}
            multipleFiles={false}
          >
            <Button
              variant="outlined"
              className={classes.uploadBtn}
              style={{
                display: "flex",
                alignItems: "center",
                // gap: "5px", // Spacing between icon and text
              }}
            >
              <img
                src={sympIcon}
                width="20"
                height="20"
                alt="C"
                className={classes.iconCls}
              />
              Upload Symptom List
            </Button>
          </ReactFileReader>
          <Button
            value={tabs[2]}
            variant="outlined"
            style={{
              marginTop: "7px",
              backgroundColor: "#5E92C2",
              alignSelf: "flex-start", // Align to the start for consistency
            }}
            onClick={(e) => handleDiaClickOpen(e, tabs[2])}
          >
            Add Symptom
          </Button>
        </div>
        <SymptListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewSymt}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div
          style={{
            // maxWidth: "500px",
            // width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "flex-start", // Align content to the start
            gap: "10px", // Add spacing between elements
          }}
        >
          {/* Upload Disease List Button */}
          <ReactFileReader
            handleFiles={handleDesUpload}
            fileTypes={[".csv", ".xlsx"]}
            multipleFiles={false}
          >
            <Button
              className={classes.uploadBtn}
              variant="outlined"
              style={{
                display: "flex",
                alignItems: "center",
                // gap: "5px", // Spacing between icon and text
              }}
            >
              <img
                src={disIcon}
                width="20"
                height="20"
                alt="C"
                style={{ display: "inline-block" }}
              />
              Upload Disease List
            </Button>
          </ReactFileReader>

          {/* Add Disease Button */}
          <Button
            value={tabs[3]}
            variant="outlined"
            style={{
              marginTop: "7px",
              backgroundColor: "#5E92C2",
              alignSelf: "flex-start", // Align to the start for consistency
            }}
            onClick={(e) => handleDiaClickOpen(e, tabs[3])}
          >
            Add Disease
          </Button>
        </div>

        {/* Disease List Table */}
        <DistListTable
          values={values}
          open={openDia}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>

      <TabPanel value={value} index={4}>
        {/* <ReactFileReader
          handleFiles={handleMedsUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={medIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Payment list
          </Button>
        </ReactFileReader> */}
        <Button
          value={tabs[4]}
          variant="outlined"
          style={{
            // marginLeft: "251px",
            backgroundColor: "#5E92C2",
            margin: "15px 0px 40px 0px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[4])}
        >
          Add Payment Name
        </Button>
        <PaymentListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        {/* <ReactFileReader
          handleFiles={handleMedsUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={medIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Payment list
          </Button>
        </ReactFileReader> */}
        <Button
          value={tabs[5]}
          variant="outlined"
          style={{
            // marginLeft: "251px",
            backgroundColor: "#5E92C2",
            margin: "15px 0px 40px 0px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[5])}
        >
          Add Complaints Template
        </Button>
        <ComplaintListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        {/* <ReactFileReader
          handleFiles={handleMedsUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={medIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Payment list
          </Button>
        </ReactFileReader> */}
        <Button
          value={tabs[6]}
          variant="outlined"
          style={{
            // marginLeft: "251px",
            backgroundColor: "#5E92C2",
            margin: "15px 0px 40px 0px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[6])}
        >
          Add Rx Template
        </Button>
        <RxListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={7}>
        {/* <ReactFileReader
          handleFiles={handleMedsUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={medIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Payment list
          </Button>
        </ReactFileReader> */}
        <Button
          value={tabs[7]}
          variant="outlined"
          style={{
            // marginLeft: "251px",
            backgroundColor: "#5E92C2",
            margin: "15px 0px 40px 0px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[7])}
        >
          Add Ix Template
        </Button>
        <IxListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={8}>
        {/* <ReactFileReader
          handleFiles={handleMedsUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={medIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Payment list
          </Button>
        </ReactFileReader> */}
        <Button
          value={tabs[8]}
          variant="outlined"
          style={{
            // marginLeft: "251px",
            backgroundColor: "#5E92C2",
            margin: "15px 0px 40px 0px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[8])}
        >
          Add Advice Template
        </Button>
        <AdviceListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      <TabPanel value={value} index={9}>
        {/* <ReactFileReader
          handleFiles={handleMedsUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" className={classes.uploadBtn}>
            <img
              src={medIcon}
              width="20"
              height="20"
              alt="C"
              className={classes.iconCls}
            />
            Upload Payment list
          </Button>
        </ReactFileReader> */}
        <Button
          value={tabs[9]}
          variant="outlined"
          style={{
            // marginLeft: "251px",
            backgroundColor: "#5E92C2",
            margin: "15px 0px 40px 0px",
          }}
          onClick={(e) => handleDiaClickOpen(e, tabs[9])}
        >
          Add Payment Template
        </Button>
        <PaymentTemplateListTable
          values={values}
          open={openDia}
          // appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose}
        />
      </TabPanel>
      {/* {openDia && (
        <AddReport
          values={values}
          open={openDia}
          appendNewItem={appendNewReps}
          handleClickClose={handleDiaClickClose} // Close the AddReport component
        />
      )} */}
      {/* {openDia && (
        <AddReport
          values={values}
          open={openDia}
          appendNewItem={appendNewMeds}
          handleClickClose={handleDiaClickClose} // Close the AddReport component
        />
      )}
      {openDia && (
        <AddReport
          values={values}
          open={openDia}
          appendNewItem={appendNewSymt}
          handleClickClose={handleDiaClickClose} // Close the AddReport component
        />
      )}
      {openDia && (
        <AddReport
          values={values}
          open={openDia}
          appendNewItem={appendNewDis}
          handleClickClose={handleDiaClickClose} // Close the AddReport component
        />
      )} */}
    </Paper>
  );
}

const MedicineMng = () => {
  // const [userlist, setUserlist] = useState([]);
  // const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CSVReader
        onFileLoad={handleReadCSV}
        // inputRef={fileInput}
        style={{ display: "none" }}
      /> */}
      <CenteredTabs />

      {/* <div className={classes.content}></div>
      <div className={classes.content}>
        <ReactFileReader
          handleFiles={handleDrRefUpload}
          fileTypes={[".csv", ".xlsx"]}
          multipleFiles={false}
        >
          <Button variant="outlined" color="primary">
            Upload Dr ref list
          </Button>
        </ReactFileReader>
      </div>
      <div className={classes.content}></div>
      <div className={classes.content}></div> */}
    </div>
  );
};

export default MedicineMng;
