import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import { hostInfo, medSchd, medSchdTime } from "../../../store";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Autocomplete } from "@material-ui/lab";
import {
  Grid,
  IconButton,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    background: "#9FC088",
    marginTop: theme.spacing(2),
  },
  tabBar: {
    background: "#9FC088",
    // borderRadius: 3,
  },
  kwdBtn: {
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: "#00C39B",
    borderRadius: 3,
    border: 0,
    color: "white",
    // padding: '0 30px',
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  fieldTxt: {
    marginRight: theme.spacing(1),
  },

  medName: {
    marginRight: theme.spacing(1),
    width: "30%",
  },
  // medQnt: {
  //   marginRight: theme.spacing(1),
  //   width: "30",
  // },
  medSchd: {
    marginRight: theme.spacing(1),
    minWidth: "10%",
  },
  medSchdTime: {
    marginRight: theme.spacing(1),
    minWidth: "14%",
  },
  medCountField: {
    marginRight: theme.spacing(1),
    width: "7%",
  },
  medOut: {
    marginRight: theme.spacing(1),
    // width: "7%",
  },
  fieldSpace: {
    "& > *": {
      margin: theme.spacing(1),
      // marginLeft: theme.spacing(2),
    },
  },
  fabBut: {
    // marginRight: theme.spacing(1),
    minWidth: "6%",
  },
  addBut: {
    background: "#00C39B",
    "&:hover": {
      background: "#9BE27A",
    },
  },
  // autocomp: {
  //   '&[data-focus="true"], &:hover': {
  //     backgroundColor: "#CFE8A9",
  //   },
  // },
}));

const AddReport = (props) => {
  // const {
  //   open,
  //   values,
  //   appendNewItem,
  //   handleClickClose,
  //   isEditDialogOpen,
  //   onClose,
  //   selectedRow,
  //   types,
  // } = props;

  const {
    dataList,
    open,
    EditReport,
    values,
    appendNewItem,
    handleClickClose,
    selectedRow,
    types,
    repSug,
    symSug,
    medSug,
    paymentSug,
    isEditDialogOpen,
  } = props;
  // console.log(values, types, dataList, repSug, selectedRow);
  // console.log(paymentSug);
  const tabs = [
    "reps",
    "meds",
    "symt",
    "dise",
    "payment",
    "complaint",
    "Rx",
    "Ix",
    "advice",
    "paymentTemp",
  ];
  const k = selectedRow ? selectedRow[0] : "";
  // console.log(k);
  const [meds, setMeds] = useState({
    medName: "",
    medCmp: "",
    medType: "",
  });

  const [payment, setPayment] = useState({
    paymentName: "",
    Price: "",
  });

  const [repList, setRepList] = useState({
    name: "",
    json_data: "",
  });

  const [adviceList, setadviceList] = useState({
    name: "",
    json_data: "",
  });
  // console.log(adviceList);
  const [RxList, setRxList] = useState({
    name: "",
    json_data: [
      {
        index: Math.random(),
        // medType: "",
        medName: "",
        medQnt: 1,
        medSchd: "",
        medTime: "After Eat",
        medCount: 0,
        medOut: false,
      },
    ],
  });

  // console.log(RxList);
  const [paymentList, setpaymentList] = useState({
    name: "",
    json_data: [{ name: "", price: "", discount: "", total: "" }],
  });
  const [complaintList, setcomplaintList] = useState({
    name: "",
    json_data: [{ "": ["", "", "", ""] }],
  });
  // console.log(complaintList);
  const [reps, setReps] = useState({
    repName: "",
  });

  const [dis, setDis] = useState({
    disName: "",
  });

  const [symt, setSymt] = useState({
    symName: "",
  });

  const [drs, setDrs] = useState({
    drFirstNm: "",
    drLastNm: "",
    drSpecl: "",
    drMob: "",
  });
  // const [medList,setMedList] = useState([
  //   {
  //     index: Math.random(),
  //     // medType: "",
  //     medName: "",
  //     medQnt: 1,
  //     medSchd: "",
  //     medTime: "After Eat",
  //     medCount: 0,
  //     medOut: false,
  //   },
  // ])
  useEffect(() => {
    if (isEditDialogOpen && types === tabs[0]) {
      setReps({
        repName: k.name || "",
      });
    } else if (isEditDialogOpen && types === tabs[1]) {
      setMeds({
        medName: k.name?.split(".")[1] || "",
        medType: k.name?.split(".")[0] || "",
      });
    } else if (isEditDialogOpen && types === tabs[2]) {
      setSymt({
        symName: k.name || "",
      });
    } else if (isEditDialogOpen && types === tabs[3]) {
      setDis({
        disName: k.name || "",
      });
    } else if (isEditDialogOpen && types === tabs[4]) {
      setPayment({
        paymentName: k.name?.split(".")[1] || "",
        Price: k.name?.split(".")[0] || "",
      });
    } else if (isEditDialogOpen && types === tabs[7]) {
      setRepList({
        name: k.name || "",
        json_data: k.json_data || "",
      });
    } else if (isEditDialogOpen && types === tabs[8]) {
      // console.log(k);
      setadviceList({
        name: k?.name || "",
        json_data: k?.json_data || "",
      });
    } else if (isEditDialogOpen && types === tabs[9]) {
      // console.log(k);
      setpaymentList({
        name: k?.name || "",
        json_data: [
          ...(Array.isArray(k?.json_data) ? k.json_data : []), // Handle undefined or non-array json_data
          { name: "", price: "", discount: "", total: "" },
        ],
      });

      // setpaymentList({
      //   name: k?.name || "",
      //   json_data: k?.json_data || "",
      // });
    } else if (isEditDialogOpen && types === tabs[5]) {
      // console.log(k);
      setcomplaintList({
        name: k?.name || "",
        json_data: [
          ...(Array.isArray(k?.json_data) ? k.json_data : []), // Handle undefined or non-array json_data
          { "": ["", "", "", ""] },
        ],
      });
    } else if (isEditDialogOpen && types === tabs[6]) {
      // console.log(k);
      setRxList({
        name: k?.name || "",
        json_data: [...(Array.isArray(k?.json_data) ? k.json_data : [])],
      });
    }
  }, [isEditDialogOpen, types]);

  const classes = useStyles();

  // const [value, setValue] = React.useState(tabs[0]);
  // console.log(value);
  const validateName = (name) => {
    if (name.trim() === "") {
      console.log("Invalid string to add in DB: ", name);
      return 1;
    } else if (name.trim().length > 100) {
      console.log("Invalid string: Too long, Str: ", name);
      return 1;
    }
    return 0;
  };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  const handleClickAdd = async (e, value) => {
    // const { appendNewItem } = props;
    // console.log("Dialog Add button got ----------");
    // var name = e.target.name;
    // var value1 = e.target.value;
    // // console.log("arg Value :", value);
    // console.log(
    //   "appendNewItem------------------------------------",
    //   appendNewItem
    // );
    // appendNewItem(reps.repName);

    // console.log(appendNewItem);
    if (value === tabs[1]) {
      if (validateName(meds.medName)) {
        alert("Invalid entry:", meds.medName, "  !!! Please correct ");
        return;
      }

      var me = {
        Name: meds.medName,
        // Company: meds.medCmp,
        Type: meds.medType,
      };
      let url = hostInfo + "/addmeds";
      const response = await fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(me),
      });
      const data = await response.json();
      const tmpmed = {
        Id: data?.id,
        Name: meds.medType + "." + meds.medName,
      };
      appendNewItem(tmpmed);
    } else if (value === tabs[4]) {
      if (validateName(payment.paymentName)) {
        alert("Invalid entry:", payment.paymentName, "  !!! Please correct ");
        return;
      }

      var me = {
        Name: payment.paymentName,
        // Company: meds.medCmp,
        Price: parseInt(payment.Price, 10),
      };
      let url = hostInfo + "/addpayment";
      const response = await fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(me),
      });
      const data = await response.json();
      const tmpmed = {
        Id: data?.id,
        Name: payment.Price + "." + payment.paymentName,
      };
      appendNewItem(tmpmed);
    } else if (value === tabs[0]) {
      if (validateName(reps.repName)) {
        alert("Invalid entry:", reps.repName, "  !!! Please correct ");
        return;
      }
      var re = {
        report: reps.repName,
      };
      let url = hostInfo + "/addreps";
      const response = await fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(re),
      });

      const data = await response.json();

      const tmprep = {
        Id: data?.id,
        Name: reps.repName,
      };
      // console.log(tmprep);
      appendNewItem(tmprep);
    } else if (value === tabs[3]) {
      if (validateName(dis.disName)) {
        alert("Invalid entry:", dis.disName, "  !!! Please correct ");
        return;
      }

      var di = {
        name: dis.disName,
        description: "-",
      };

      let url = hostInfo + "/adddis";
      const response = await fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(di),
      });
      const data = await response.json();
      const tmpdis = {
        Id: data?.id,
        Name: dis.disName,
      };
      appendNewItem(tmpdis);
    } else if (value === tabs[2]) {
      // console.log("arg Value :", value);

      if (validateName(symt.symName)) {
        alert("Invalid entry:", symt.symName, "  !!! Please correct ");
        return;
      }

      var sy = {
        name: symt.symName,
      };

      let url = hostInfo + "/addsympt";
      const response = await fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(sy),
      });
      const data = await response.json();
      // console.log(data);
      const tmpsymt = {
        Id: data?.id,
        Name: symt.symName,
      };
      appendNewItem(tmpsymt);
    } else if (value === tabs[7]) {
      const handleSaveTemplate = () => {
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, repList } = this.state;
        const shouldAddPreviousData =
          [repList]?.length > 0 && repList?.name !== "";

        if (shouldAddPreviousData) {
          const savedData = {
            // id: k?.Id,
            name: repList?.name,
            json_data: repList?.json_data,
          }; // Change `rows` to `json_data`

          fetch(hostInfo + "/addReportTemplate", {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // If required for authorization
            },
            body: JSON.stringify(savedData), // Ensure correct field name in payload
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("API Response:", data);
              const tmpsymt = {
                id: data?.id,
                name: repList.name,
                json_data: repList.json_data,
              };
              appendNewItem(tmpsymt);
            })
            .catch((error) => {
              console.error("Error:", error);
              // alert("Failed to save template");
            });

          // this.handleDialogClose();
        } else {
          alert("Please Enter Value...");
          // this.handleDialogClose();
          // this.setState({ templateName: "" });
        }
      };
      handleSaveTemplate();
    } else if (value === tabs[8]) {
      const handleSaveTemplate = () => {
        // Function to get the token from cookies
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, rows } = this.state;

        // Check if rows contain valid data
        const shouldAddPreviousData =
          [adviceList]?.length > 0 && adviceList?.name !== "";

        if (shouldAddPreviousData) {
          // Payload to send in the API request
          const savedData = {
            // id: k?.Id,
            name: adviceList?.name,
            json_data: adviceList?.json_data,
          };

          fetch(`${hostInfo}/addAdviceTemplate`, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // Ensure correct authorization token
            },
            body: JSON.stringify(savedData), // Send the template data
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to save template"); // Handle response errors
              }
              return response.json();
            })
            .then((data) => {
              // console.log("API Response:", data);
              const tmpsymt = {
                id: data?.id,
                name: adviceList.name,
                json_data: adviceList.json_data,
              };
              appendNewItem(tmpsymt);
            })
            .catch((error) => {
              console.error("Error:", error);
              alert("Failed to save template. Please try again.");
            });

          // this.handleDialogClose(); // Close dialog
        } else {
          alert(
            "Please enter a template name and fill out the required fields."
          );
          // this.handleDialogClose(); // Close dialog even on validation failure
          // this.setState({ templateName: "" }); // Reset templateName field
        }
      };

      handleSaveTemplate();
    } else if (value === tabs[9]) {
      const handleSaveTemplate = () => {
        // Function to get the token from cookies
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, rows } = this.state;

        // Check if rows contain valid data
        const shouldAddPreviousData =
          [paymentList]?.length > 0 && paymentList?.name !== "";

        if (shouldAddPreviousData) {
          // Payload to send in the API request
          const savedData = {
            // id: k?.Id,
            name: paymentList?.name,
            json_data: paymentList?.json_data?.slice(0, -1),
          };

          fetch(`${hostInfo}/addPaymentTemplate`, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // Ensure correct authorization token
            },
            body: JSON.stringify(savedData), // Send the template data
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to save template"); // Handle response errors
              }
              return response.json();
            })
            .then((data) => {
              // console.log("API Response:", data);
              const tmpsymt = {
                id: data?.id,
                name: paymentList.name,
                json_data: paymentList.json_data,
              };
              appendNewItem(tmpsymt);
            })
            .catch((error) => {
              console.error("Error:", error);
              alert("Failed to save template. Please try again.");
            });

          // this.handleDialogClose(); // Close dialog
        } else {
          alert(
            "Please enter a template name and fill out the required fields."
          );
          // this.handleDialogClose(); // Close dialog even on validation failure
          // this.setState({ templateName: "" }); // Reset templateName field
        }
      };

      handleSaveTemplate();
    } else if (value === tabs[5]) {
      const handleSaveTemplate = () => {
        // Function to get the token from cookies
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, rows } = this.state;

        // Check if rows contain valid data
        const shouldAddPreviousData =
          [complaintList]?.length > 0 && complaintList?.name !== "";

        if (shouldAddPreviousData) {
          // Payload to send in the API request
          const savedData = {
            // id: k?.Id,
            name: complaintList?.name,
            json_data: complaintList?.json_data?.slice(0, -1),
          };

          fetch(`${hostInfo}/addTemplate`, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // Ensure correct authorization token
            },
            body: JSON.stringify(savedData), // Send the template data
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to save template"); // Handle response errors
              }
              return response.json();
            })
            .then((data) => {
              // console.log("API Response:", data);
              const tmpsymt = {
                id: data?.id,
                name: complaintList.name,
                json_data: complaintList.json_data,
              };
              appendNewItem(tmpsymt);
            })
            .catch((error) => {
              console.error("Error:", error);
              alert("Failed to save template. Please try again.");
            });

          // this.handleDialogClose(); // Close dialog
        } else {
          alert(
            "Please enter a template name and fill out the required fields."
          );
          // this.handleDialogClose(); // Close dialog even on validation failure
          // this.setState({ templateName: "" }); // Reset templateName field
        }
      };

      handleSaveTemplate();
    } else if (value === tabs[6]) {
      const handleSaveTemplate = () => {
        // Function to get the token from cookies
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, rows } = this.state;

        // Check if rows contain valid data
        const shouldAddPreviousData =
          [RxList]?.length > 0 && RxList?.name !== "";

        if (shouldAddPreviousData) {
          // Payload to send in the API request
          const savedData = {
            // id: k?.Id,
            name: RxList?.name,
            json_data: RxList?.json_data,
          };

          fetch(`${hostInfo}/addPrescriptionTemplate`, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // Ensure correct authorization token
            },
            body: JSON.stringify(savedData), // Send the template data
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Failed to save template"); // Handle response errors
              }
              return response.json();
            })
            .then((data) => {
              // console.log("API Response:", data);
              const tmpsymt = {
                id: data?.id,
                name: RxList.name,
                json_data: RxList.json_data,
              };
              appendNewItem(tmpsymt);
            })
            .catch((error) => {
              console.error("Error:", error);
              alert("Failed to save template. Please try again.");
            });

          // this.handleDialogClose(); // Close dialog
        } else {
          alert(
            "Please enter a template name and fill out the required fields."
          );
          // this.handleDialogClose(); // Close dialog even on validation failure
          // this.setState({ templateName: "" }); // Reset templateName field
        }
      };

      handleSaveTemplate();
    }

    //  else if (value === tabs[4]) {
    //   let name = drs.drFirstNm + " " + drs.drLastNm;
    //   if (validateName(name)) {
    //     alert(
    //       "Invalid entry of first name: ",
    //       drs.drFirstNm,
    //       " & last name:",
    //       drs.drLastNm,
    //       "  !!! Please correct "
    //     );
    //     return;
    //   }
    //   var dr = {
    //     firstname: drs.drFirstNm,
    //     lastname: drs.drLastNm,
    //     speciality: drs.drSpecl,
    //     mobile: drs.drMob,
    //   };
    //   // console.log("Mitesh --- ");
    //   // console.log(JSON.stringify(dr));

    //   let url = "http://" + hostInfo + "/adddrinfo";
    //   fetch(url, {
    //     method: "POST",
    //     mode: "no-cors",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(dr),
    //   });

    //   appendNewItem(name);
    // }

    // console.log(this.keywordData);
    // this.setState({ keywordData: initKeywordData });
    // console.log(this.keywordData);
    handleClickClose();
    // window.location.reload();
  };

  const handleClickEdit = (e, value) => {
    // console.log("Dialog Add button got ----------");
    // var name = e.target.name;
    // var value1 = e.target.value;
    // console.log("Name: ", name, " Value: ", value1);
    // console.log("arg Value :", value);

    if (value === tabs[1]) {
      if (validateName(meds.medName)) {
        alert("Invalid entry:", meds.medName, "  !!! Please correct ");
        return;
      }

      var me = {
        Id: k.Id,
        Name: meds.medName,
        // Company: meds.medCmp,
        Type: meds.medType,
      };
      let url = hostInfo + "/updatemed";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(me),
      });

      const updatedMedications = dataList.map((item) => {
        if (item.Id === k.Id) {
          return { ...item, Name: meds.medType + "." + meds.medName };
        }
        return item;
      });

      EditReport(updatedMedications);

      handleClickClose();

      // appendNewItem(meds.medType + ". " + meds.medName);
    } else if (value === tabs[4]) {
      if (validateName(payment.paymentName)) {
        alert("Invalid entry:", payment.paymentName, "  !!! Please correct ");
        return;
      }

      var me = {
        Id: k.Id,
        Name: payment.paymentName,
        // Company: meds.medCmp,
        Price: parseInt(payment.Price, 10),
      };

      let url = hostInfo + "/updatepayment";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(me),
      });

      const updatedMedications = dataList.map((item) => {
        if (item.Id === k.Id) {
          return { ...item, Name: payment.Price + "." + payment.paymentName };
        }
        return item;
      });

      EditReport(updatedMedications);

      handleClickClose();

      // appendNewItem(meds.medType + ". " + meds.medName);
    } else if (value === tabs[0]) {
      if (validateName(reps.repName)) {
        alert("Invalid entry:", reps.repName, "  !!! Please correct ");
        return;
      }
      var re = {
        Id: k.Id,
        report: reps.repName,
      };
      let url = hostInfo + "/updatereps";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(re),
      });
      const updatedMedications = dataList.map((item) => {
        if (item.Id === k.Id) {
          return { ...item, Name: reps.repName };
        }
        return item;
      });
      EditReport(updatedMedications);

      handleClickClose();
      // appendNewItem(reps.repName);
    } else if (value === tabs[3]) {
      if (validateName(dis.disName)) {
        alert("Invalid entry:", dis.disName, "  !!! Please correct ");
        return;
      }

      var di = {
        Id: k.Id,
        name: dis.disName,
        description: "-",
      };
      // console.log(di);
      let url = hostInfo + "/updatedis";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(di),
      });
      const updatedMedications = dataList.map((item) => {
        if (item.Id === k.Id) {
          return { ...item, Name: dis.disName };
        }
        return item;
      });
      EditReport(updatedMedications);

      handleClickClose();
      // appendNewItem(dis.disName);
    } else if (value === tabs[2]) {
      // console.log("arg Value :", value);

      if (validateName(symt.symName)) {
        alert("Invalid entry:", symt.symName, "  !!! Please correct ");
        return;
      }

      var sy = {
        Id: k.Id,
        name: symt.symName,
      };

      let url = hostInfo + "/updatesympt";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(sy),
      });
      const updatedMedications = dataList.map((item) => {
        if (item.Id === k.Id) {
          return { ...item, Name: symt.symName };
        }
        return item;
      });
      EditReport(updatedMedications);

      handleClickClose();
      // appendNewItem(symt.symName);
    } else if (value === tabs[7]) {
      const handleSaveTemplate = () => {
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, repList } = this.state;
        const shouldAddPreviousData =
          [repList]?.length > 0 && repList?.name !== "";

        if (shouldAddPreviousData) {
          const savedData = {
            id: k?.Id,
            name: repList?.name,
            json_data: repList?.json_data,
          }; // Change `rows` to `json_data`

          fetch(hostInfo + "/updateIxTemplate", {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // If required for authorization
            },
            body: JSON.stringify(savedData), // Ensure correct field name in payload
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("API Response:", data);
              // if (data.message) {
              //   alert("Template update successfully");
              // } else {
              //   alert("Error saving template");
              // }
              // this.setState((prevState) => ({
              //   templates: [...(prevState.templates || []), savedData],
              //   // rows: [
              //   //   {
              //   //     complaint: "",
              //   //     frequency: "",
              //   //     severity: "",
              //   //     duration: "",
              //   //     date: "",
              //   //     inputNumber: "",
              //   //   },
              //   // ],
              //   templateName: "",
              // }));
            })
            .catch((error) => {
              console.error("Error:", error);
              // alert("Failed to save template");
            });

          // this.handleDialogClose();
        } else {
          alert("Please Enter Value...");
          // this.handleDialogClose();
          // this.setState({ templateName: "" });
        }
      };
      handleSaveTemplate();

      const updatedMedications = dataList.map((item) => {
        if (item.id === k.Id) {
          return { ...item, name: repList.name, json_data: repList.json_data };
        }
        return item;
      });

      // console.log(dataList);
      // const updatedMedications = [repList].map((item) => {
      //   if (item.id === k.Id) {
      //     return { ...item };
      //   }
      //   return item;
      // });
      // console.log(updatedMedications);
      EditReport(updatedMedications);

      handleClickClose();
      // appendNewItem(symt.symName);
    } else if (value === tabs[8]) {
      const handleSaveTemplate = () => {
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, repList } = this.state;
        const shouldAddPreviousData =
          [adviceList]?.length > 0 && adviceList?.name !== "";

        if (shouldAddPreviousData) {
          const savedData = {
            id: k?.Id,
            name: adviceList?.name,
            json_data: adviceList?.json_data,
          }; // Change `rows` to `json_data`

          fetch(hostInfo + "/updateAdviceTemplate", {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // If required for authorization
            },
            body: JSON.stringify(savedData), // Ensure correct field name in payload
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("API Response:", data);
              // if (data.message) {
              //   alert("Template update successfully");
              // } else {
              //   alert("Error saving template");
              // }
              // this.setState((prevState) => ({
              //   templates: [...(prevState.templates || []), savedData],
              //   // rows: [
              //   //   {
              //   //     complaint: "",
              //   //     frequency: "",
              //   //     severity: "",
              //   //     duration: "",
              //   //     date: "",
              //   //     inputNumber: "",
              //   //   },
              //   // ],
              //   templateName: "",
              // }));
            })
            .catch((error) => {
              console.error("Error:", error);
              // alert("Failed to save template");
            });

          // this.handleDialogClose();
        } else {
          alert("Please Enter Value...");
          // this.handleDialogClose();
          // this.setState({ templateName: "" });
        }
      };
      handleSaveTemplate();

      const updatedMedications = dataList.map((item) => {
        if (item.id === k.Id) {
          return {
            ...item,
            name: adviceList.name,
            json_data: adviceList.json_data,
          };
        }
        return item;
      });

      // console.log(dataList);
      // const updatedMedications = [repList].map((item) => {
      //   if (item.id === k.Id) {
      //     return { ...item };
      //   }
      //   return item;
      // });
      // console.log(updatedMedications);
      EditReport(updatedMedications);

      handleClickClose();
      // appendNewItem(symt.symName);
    } else if (value === tabs[9]) {
      const handleSaveTemplate = () => {
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, repList } = this.state;
        const shouldAddPreviousData =
          [paymentList]?.length > 0 && paymentList?.name !== "";

        if (shouldAddPreviousData) {
          const savedData = {
            id: k?.Id,
            name: paymentList?.name,
            json_data: paymentList?.json_data?.slice(0, -1),
          }; // Change `rows` to `json_data`

          fetch(hostInfo + "/updatePaymentTemplate", {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // If required for authorization
            },
            body: JSON.stringify(savedData), // Ensure correct field name in payload
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("API Response:", data);
              // if (data.message) {
              //   alert("Template update successfully");
              // } else {
              //   alert("Error saving template");
              // }
              // this.setState((prevState) => ({
              //   templates: [...(prevState.templates || []), savedData],
              //   // rows: [
              //   //   {
              //   //     complaint: "",
              //   //     frequency: "",
              //   //     severity: "",
              //   //     duration: "",
              //   //     date: "",
              //   //     inputNumber: "",
              //   //   },
              //   // ],
              //   templateName: "",
              // }));
            })
            .catch((error) => {
              console.error("Error:", error);
              // alert("Failed to save template");
            });

          // this.handleDialogClose();
        } else {
          alert("Please Enter Value...");
          // this.handleDialogClose();
          // this.setState({ templateName: "" });
        }
      };
      handleSaveTemplate();

      const updatedMedications = dataList.map((item) => {
        if (item.id === k.Id) {
          return {
            ...item,
            name: paymentList.name,
            json_data: paymentList.json_data,
          };
        }
        return item;
      });

      // console.log(dataList);
      // const updatedMedications = [repList].map((item) => {
      //   if (item.id === k.Id) {
      //     return { ...item };
      //   }
      //   return item;
      // });
      // console.log(updatedMedications);
      EditReport(updatedMedications);

      handleClickClose();
      // appendNewItem(symt.symName);
    } else if (value === tabs[5]) {
      const handleSaveTemplate = () => {
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, repList } = this.state;
        const shouldAddPreviousData =
          [complaintList]?.length > 0 && complaintList?.name !== "";

        if (shouldAddPreviousData) {
          const savedData = {
            id: k?.Id,
            name: complaintList?.name,
            json_data: complaintList?.json_data?.slice(0, -1),
          }; // Change `rows` to `json_data`

          fetch(hostInfo + "/updateComplaintTemplate", {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // If required for authorization
            },
            body: JSON.stringify(savedData), // Ensure correct field name in payload
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("API Response:", data);
              // if (data.message) {
              //   alert("Template update successfully");
              // } else {
              //   alert("Error saving template");
              // }
              // this.setState((prevState) => ({
              //   templates: [...(prevState.templates || []), savedData],
              //   // rows: [
              //   //   {
              //   //     complaint: "",
              //   //     frequency: "",
              //   //     severity: "",
              //   //     duration: "",
              //   //     date: "",
              //   //     inputNumber: "",
              //   //   },
              //   // ],
              //   templateName: "",
              // }));
            })
            .catch((error) => {
              console.error("Error:", error);
              // alert("Failed to save template");
            });

          // this.handleDialogClose();
        } else {
          alert("Please Enter Value...");
          // this.handleDialogClose();
          // this.setState({ templateName: "" });
        }
      };
      handleSaveTemplate();

      const updatedMedications = dataList.map((item) => {
        if (item.id === k.Id) {
          return {
            ...item,
            name: complaintList.name,
            json_data: complaintList.json_data,
          };
        }
        return item;
      });

      // console.log(dataList);
      // const updatedMedications = [repList].map((item) => {
      //   if (item.id === k.Id) {
      //     return { ...item };
      //   }
      //   return item;
      // });
      // console.log(updatedMedications);
      EditReport(updatedMedications);

      handleClickClose();
      // appendNewItem(symt.symName);
    } else if (value === tabs[6]) {
      const handleSaveTemplate = () => {
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // const { templateName, repList } = this.state;
        const shouldAddPreviousData =
          [RxList]?.length > 0 && RxList?.name !== "";

        if (shouldAddPreviousData) {
          const savedData = {
            id: k?.Id,
            name: RxList?.name,
            json_data: RxList?.json_data,
          }; // Change `rows` to `json_data`

          fetch(hostInfo + "/updateRxTemplate", {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`, // If required for authorization
            },
            body: JSON.stringify(savedData), // Ensure correct field name in payload
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("API Response:", data);
              // if (data.message) {
              //   alert("Template update successfully");
              // } else {
              //   alert("Error saving template");
              // }

              // this.setState((prevState) => ({
              //   templates: [...(prevState.templates || []), savedData],
              //   // rows: [
              //   //   {
              //   //     complaint: "",
              //   //     frequency: "",
              //   //     severity: "",
              //   //     duration: "",
              //   //     date: "",
              //   //     inputNumber: "",
              //   //   },
              //   // ],
              //   templateName: "",
              // }));
            })
            .catch((error) => {
              console.error("Error:", error);
              // alert("Failed to save template");
            });

          // this.handleDialogClose();
        } else {
          alert("Please Enter Value...");
          // this.handleDialogClose();
          // this.setState({ templateName: "" });
        }
      };
      handleSaveTemplate();

      const updatedMedications = dataList.map((item) => {
        if (item.id === k.Id) {
          return {
            ...item,
            name: RxList.name,
            json_data: RxList.json_data,
          };
        }
        return item;
      });

      // console.log(dataList);
      // const updatedMedications = [repList].map((item) => {
      //   if (item.id === k.Id) {
      //     return { ...item };
      //   }
      //   return item;
      // });
      // console.log(updatedMedications);
      EditReport(updatedMedications);

      handleClickClose();
      // appendNewItem(symt.symName);
    }
  };

  // Meds -----------
  const handleChangeMedProps = (e) => {
    // console.log("Inside handleChangeMedProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["medName", "medType"].includes(name)) {
      setMeds({ ...meds, [name]: value });
    }
  };

  //payment------------
  const handleChangePaymentProps = (e) => {
    // console.log("Inside handleChangeMedProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["paymentName", "Price"].includes(name)) {
      setPayment({ ...payment, [name]: value });
    }
  };

  // Reps -----------
  const handleChangeRepProps = (e) => {
    // console.log("Inside handleChangeRepProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["repName"].includes(name)) {
      setReps({ [name]: value });
    }
    // console.log(reps);
  };

  // Dis -----------
  const handleChangeDisProps = (e) => {
    // console.log("Inside handleChangeDisProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["disName"].includes(name)) {
      setDis({ [name]: value });
    }
  };

  // Symt -----------
  const handleChangeSymtProps = (e) => {
    // console.log("Inside handleChangeSymtProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["symName"].includes(name)) {
      setSymt({ [name]: value });
    }
  };

  // Dr -----------
  const handleChange = (e) => {
    // console.log("Inside handleChangeDrProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["IxName"].includes(name)) {
      setRepList((prevState) => ({
        ...prevState, // Retain the existing state
        name: value, // Update the specific field dynamically
      }));
    }
    if (["adviceName"].includes(name)) {
      setadviceList((prevState) => ({
        ...prevState, // Retain the existing state
        name: value, // Update the specific field dynamically
      }));
    }
    if (["PaymentTempName"].includes(name)) {
      setpaymentList((prevState) => ({
        ...prevState, // Retain the existing state
        name: value, // Update the specific field dynamically
      }));
    }
    if (["complaintName"].includes(name)) {
      setcomplaintList((prevState) => ({
        ...prevState, // Retain the existing state
        name: value, // Update the specific field dynamically
      }));
    }
    if (["RxName"].includes(name)) {
      setRxList((prevState) => ({
        ...prevState, // Retain the existing state
        name: value, // Update the specific field dynamically
      }));
    }
  };

  const handleRepChange = (newValue) => {
    // console.log(newValue);
    setRepList((prevState) => ({
      ...prevState, // Retain the existing state
      json_data: newValue, // Update the specific field dynamically
    }));
  };

  const handleAdviceChange = (newValue) => {
    setadviceList((prevState) => ({
      ...prevState,
      json_data: [{ ...prevState.json_data[0], notes: newValue }],
    }));
  };

  const handlePayment = (index, field, value) => {
    const newRows = [...paymentList.json_data]; // Create a shallow copy of the rows array

    // Update the specified field (price, discount, or total) only for the row at the given index
    newRows[index][field] = value;

    // Recalculate total when price or discount is updated
    if (field === "discount" || field === "price") {
      const price = parseFloat(newRows[index].price) || 0;
      const discount = parseFloat(newRows[index].discount) || 0;

      // Calculate total = price - (price * discount / 100)
      const total = price - (price * discount) / 100;
      newRows[index]["total"] = total.toFixed(2); // Round to 2 decimal places
      // console.log(total);
    }

    // Ensure that only one empty row is added when the last row is being modified
    const emptyRow = {
      name: "",
      price: "",
      discount: "",
      total: "",
    };

    // Add an empty row only if the last row's 'name' field is not empty and it's the last row
    const isNameEmpty = newRows[newRows.length - 1].name === "";

    if (!isNameEmpty && index === newRows.length - 1 && value !== "") {
      newRows.push(emptyRow);
    }

    // console.log(newRows);

    // Update the state with the modified rows
    setpaymentList((prev) => ({
      ...prev,
      json_data: newRows,
    }));

    // Call parent's onRowsChange to notify about the updates (if applicable)
  };

  const handleMedChange = (id, name, value) => {
    // console.log(id, name, value);
    setRxList((prevState) => {
      const updatedMedList = [...prevState.json_data];
      updatedMedList[id][name] = value;
      return { ...prevState, json_data: updatedMedList };
    });
  };
  const add = () => {
    setRxList((prevState) => ({
      ...prevState,
      json_data: [
        ...prevState.json_data, // Spread the existing json_data array
        {
          index: Math.random(),
          medName: "",
          medQnt: 1,
          medSchd: "",
          medTime: "After Eat",
          medCount: 0,
          medOut: false,
        },
      ],
    }));
  };

  // const [rows, setRows] = useState([]);
  // const [symSug, setSymSug] = useState([]);
  const [filteredSymSug, setFilteredSymSug] = useState([]);
  const [isSuggestionOpen, setIsSuggestionOpen] = useState(-1);
  const [durationSuggestions, setDurationSuggestions] = useState([
    { label: "__day" },
    { label: "__week" },
    { label: "__month" },
    { label: "__year" },
  ]);

  const handleDurationChange = (index, inputNumber) => {
    const updatedRows = [...complaintList.json_data];
    const key = Object.keys(updatedRows[index])[0];

    updatedRows[index][key][2] = inputNumber ? `${inputNumber}` : "";

    if (inputNumber) {
      const parts = inputNumber.split(" ");
      const durationValue = parseInt(parts[0], 10);
      const durationUnit = parts[1] ? parts[1].toLowerCase() : "";

      if (!isNaN(durationValue)) {
        const baseDate = new Date();

        if (["day", "days"].includes(durationUnit)) {
          baseDate.setDate(baseDate.getDate() - durationValue);
        } else if (["week", "weeks"].includes(durationUnit)) {
          baseDate.setDate(baseDate.getDate() - durationValue * 7);
        } else if (["month", "months"].includes(durationUnit)) {
          baseDate.setMonth(baseDate.getMonth() - durationValue);
        } else if (["year", "years"].includes(durationUnit)) {
          baseDate.setFullYear(baseDate.getFullYear() - durationValue);
        }

        updatedRows[index][key][3] = baseDate.toISOString().split("T")[0];
      } else {
        updatedRows[index][key][3] = "";
      }
    } else {
      updatedRows[index][key][3] = "";
    }
    setcomplaintList((prev) => ({
      ...prev,
      json_data: updatedRows,
    }));
    // setRows(updatedRows);
  };

  const handleComplaintChange = (index, value) => {
    const updatedRows = [...complaintList.json_data];
    const complaintKey = value || "";

    const existingRow = updatedRows[index] || {};
    const complaintValues = existingRow[complaintKey] ||
      existingRow[Object.keys(existingRow)[0]] || ["", "", "", ""];

    updatedRows[index] = { [complaintKey]: complaintValues };

    if (index === updatedRows.length - 1) {
      updatedRows.push({ "": ["", "", "", ""] });
    }
    setcomplaintList((prev) => ({
      ...prev,
      json_data: updatedRows,
    }));
    // setRows(updatedRows);

    if (!updatedRows[index][complaintKey].slice(0, 3).some((v) => v !== "")) {
      setIsSuggestionOpen(index);
    } else {
      setIsSuggestionOpen(-1);
    }
  };

  const handleDeleteMedRow = (index) => {
    // console.log(index);
    const updatedRows = RxList.json_data.filter((_, i) => i !== index);
    setRxList((prev) => ({
      ...prev,
      json_data: updatedRows,
    }));
  };

  const handleDeleteRow = (index) => {
    const updatedRows = complaintList.json_data.filter((_, i) => i !== index);
    setcomplaintList((prev) => ({
      ...prev,
      json_data: updatedRows.length ? updatedRows : [{ "": ["", "", "", ""] }],
    }));
  };

  const handleDeletePaymentRow = (index) => {
    const updatedRows = paymentList.json_data.filter((_, i) => i !== index);
    setpaymentList((prev) => ({
      ...prev,
      json_data: updatedRows.length
        ? updatedRows
        : [
            {
              name: "",
              price: "",
              discount: "",
              total: "",
            },
          ],
    }));
  };

  return (
    <div style={{ padding: "0px 20px 0px 20px" }}>
      <Fragment>
        <Dialog
          open={open || isEditDialogOpen}
          // color={"primary"}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClickClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <div className={classes.root}>
              <TabContext value={values || types}>
                {/* <AppBar
                  position="static"
                  className={classes.tabBar}
                  // size="small"
                >
                  <TabList
                    centered
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                  >
                    <Tab label="Reports name" value={tabs[0]} />
                    <Tab label="Medicine Name" value={tabs[1]} />
                    <Tab label="Disease" value={tabs[2]} />
                    <Tab label="Symptoms" value={tabs[3]} /> */}
                {/* <Tab label="Dr details" value={tabs[4]} /> */}
                {/* </TabList>
                </AppBar> */}
                {values || types === tabs[1] ? (
                  <>
                    <TabPanel value={tabs[1]}>
                      <h1>{isEditDialogOpen ? "Edit" : "Add"} Medicine</h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        onChange={handleChangeMedProps}
                      >
                        <TextField
                          margin="normal"
                          id="med-dia"
                          name="medName"
                          label="Medicine"
                          type="string"
                          className={classes.fieldTxt}
                          value={meds.medName}
                          // onChange={(e) => handleOnMedValChange(e, "medName")}
                        />
                        {/* <TextField
                      margin="normal"
                      id="med-dia1"
                      name="medCmp"
                      label="Brand"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    /> */}
                        <TextField
                          margin="normal"
                          id="med-dia2"
                          name="medType"
                          label="Type"
                          type="string"
                          className={classes.fieldTxt}
                          value={meds.medType}
                          // onChange={(e) => handleOnMedValChange(e, "medType")}
                        />
                        {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}
                {values || types === tabs[4] ? (
                  <>
                    <TabPanel value={tabs[4]}>
                      <h1>{isEditDialogOpen ? "Edit" : "Add"} Payment Name</h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        onChange={handleChangePaymentProps}
                      >
                        <TextField
                          margin="normal"
                          id="payment-dia"
                          name="paymentName"
                          label="Payment Name"
                          type="string"
                          className={classes.fieldTxt}
                          value={payment.paymentName}
                          // onChange={(e) => handleOnMedValChange(e, "medName")}
                        />
                        {/* <TextField
                      margin="normal"
                      id="med-dia1"
                      name="medCmp"
                      label="Brand"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    /> */}
                        <TextField
                          margin="normal"
                          id="payment-dia2"
                          name="Price"
                          label="Price"
                          type="number"
                          className={classes.fieldTxt}
                          value={payment.Price}
                          // onChange={(e) => handleOnMedValChange(e, "medType")}
                        />
                        {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}

                {values || types === tabs[5] ? (
                  <>
                    <TabPanel value={tabs[5]}>
                      <h1>
                        {isEditDialogOpen ? "Edit" : "Add"} Complaints Template
                      </h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        // onChange={handleChangePaymentProps}
                      >
                        <TextField
                          margin="normal"
                          id="complaints-dia"
                          name="complaintName"
                          label="Complaint Template Name"
                          type="string"
                          className={classes.fieldTxt}
                          value={complaintList?.name}
                          onChange={(e) => handleChange(e, "complaintName")}
                          // onChange={(e) => handleOnMedValChange(e, "medName")}
                        />
                        <Table
                          style={{
                            marginTop: "15px",
                            border: "1px solid rgba(224, 224, 224, 1)",
                            width: "auto", // Table Border
                          }}
                          size="small"
                        >
                          <TableHead>
                            <TableRow
                              style={{ backgroundColor: "rgb(238, 249, 222)" }}
                            >
                              <TableCell
                                style={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                  color: "#546e7a",
                                  fontSize: "12px",
                                  width: "35px",
                                  fontWeight: "bold",
                                }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                  width: "300px",
                                  color: "#546e7a",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                c/o
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                  width: "200px",
                                  fontSize: "12px",
                                  color: "#546e7a",
                                  fontWeight: "bold",
                                }}
                              >
                                Duration
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid white",
                                  padding: "2px",
                                  backgroundColor: "white",
                                  // width: "35px",
                                }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Array.isArray(complaintList?.json_data) &&
                              complaintList?.json_data?.map((row, index) => {
                                const complaint = Object.keys(row)[0];
                                const values = row[complaint];

                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      style={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                        padding: "2px",
                                      }}
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                        padding: "2px",
                                      }}
                                    >
                                      <Autocomplete
                                        options={symSug}
                                        getOptionLabel={(option) =>
                                          option.label || option
                                        }
                                        freeSolo
                                        value={complaint || null}
                                        onChange={(event, newValue) =>
                                          handleComplaintChange(index, newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                            }}
                                          />
                                        )}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                        padding: "2px",
                                      }}
                                    >
                                      <Autocomplete
                                        open={
                                          isSuggestionOpen === index &&
                                          complaint
                                        }
                                        options={durationSuggestions}
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        freeSolo
                                        value={
                                          values[2]
                                            ? { label: values[2] }
                                            : null
                                        }
                                        onChange={(event, newValue) => {
                                          const formattedValue =
                                            newValue?.label?.replace(
                                              /__/g,
                                              ""
                                            ) || "";
                                          handleDurationChange(
                                            index,
                                            formattedValue.trim()
                                          );
                                          setIsSuggestionOpen(-1);
                                        }}
                                        onInputChange={(
                                          event,
                                          newInputValue
                                        ) => {
                                          if (
                                            /^\d*$/.test(newInputValue) ||
                                            /^\d+\s*[a-zA-Z]*$/.test(
                                              newInputValue
                                            )
                                          ) {
                                            if (
                                              !isNaN(newInputValue) &&
                                              newInputValue.trim() !== ""
                                            ) {
                                              const newSuggestions = [
                                                {
                                                  label: `${newInputValue} day`,
                                                },
                                                {
                                                  label: `${newInputValue} week`,
                                                },
                                                {
                                                  label: `${newInputValue} month`,
                                                },
                                                {
                                                  label: `${newInputValue} year`,
                                                },
                                              ];
                                              setIsSuggestionOpen(index);
                                              setDurationSuggestions(
                                                newSuggestions
                                              );
                                            } else {
                                              // Reset to default suggestions if input is empty or invalid
                                              const defaultSuggestions = [
                                                { label: "__day" },
                                                { label: "__week" },
                                                { label: "__month" },
                                                { label: "__year" },
                                              ];
                                              setIsSuggestionOpen(-1);
                                              setDurationSuggestions(
                                                defaultSuggestions
                                              );
                                            }
                                          }
                                          // Check if the input is a valid number
                                          handleDurationChange(
                                            index,
                                            newInputValue
                                              .trim() // Remove leading and trailing spaces
                                              .replace(/\s+/g, " ")
                                          );
                                          // Update duration state on input change
                                          // this.handleDurationChange(
                                          //   index,
                                          //   newInputValue
                                          //     .trim() // Remove leading and trailing spaces
                                          //     .replace(/\s+/g, " ")
                                          // ); // Ensure the value is updated in the state
                                        }}
                                        onBlur={() => {
                                          setIsSuggestionOpen(-1);
                                          // this.setState({ isSuggestionOpen: -1 }); // Close suggestions when the input loses focus
                                        }}
                                        PopperComponent={(props) => (
                                          <Popper
                                            {...props}
                                            placement="bottom"
                                          />
                                        )}
                                        disablePortal
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                            }}
                                          />
                                        )}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "1px solid white",
                                        padding: "2px",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => handleDeleteRow(index)}
                                        color="error"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                        {/* <TextField
                      margin="normal"
                      id="med-dia1"
                      name="medCmp"
                      label="Brand"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    /> */}

                        {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}
                {values || types === tabs[6] ? (
                  <>
                    <TabPanel value={tabs[6]}>
                      <h1>{isEditDialogOpen ? "Edit" : "Add"} Rx Template</h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        // onChange={handleChangePaymentProps}
                      >
                        <TextField
                          margin="normal"
                          id="Rx-dia"
                          name="RxName"
                          label="Rx Template Name"
                          type="string"
                          className={classes.fieldTxt}
                          value={RxList.name}
                          onChange={(e) => handleChange(e, "RxName")}
                          // onChange={(e) => handleOnMedValChange(e, "medName")}
                        />
                        {Array.isArray(RxList?.json_data) &&
                          RxList?.json_data?.map((val, idx) => (
                            <div className={classes.fieldSpace} key={val.index}>
                              <Grid
                                container
                                direction="row"
                                style={{ marginTop: "15px" }}
                              >
                                <Grid item className={classes.medName}>
                                  <Autocomplete
                                    id={`medName-${idx}`} // Unique ID for accessibility
                                    margin="dense"
                                    classes={{ option: classes.autocomp }}
                                    autoSelect
                                    freeSolo
                                    disableClearable
                                    options={medSug}
                                    getOptionLabel={(option) => option}
                                    value={val.medName || ""} // Set the value from medList
                                    onChange={(e, value) => {
                                      handleMedChange(idx, "medName", value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Meds"
                                        margin="dense"
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    style={{
                                      width: "60px",
                                      marginRight: "5px",
                                    }}
                                    id={`medQnt-${idx}`} // Unique ID for accessibility
                                    label="Dose"
                                    name="medQnt"
                                    margin="dense"
                                    value={val.medQnt || 1} // Set value from medList or default to 1
                                    onChange={(e) =>
                                      handleMedChange(
                                        idx,
                                        "medQnt",
                                        e.target.value
                                      )
                                    } // Handle change
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid item className={classes.medSchd}>
                                  <Autocomplete
                                    id={`medSchd-${idx}`} // Unique ID for accessibility
                                    margin="dense"
                                    classes={{ option: classes.autocomp }}
                                    freeSolo
                                    disableClearable
                                    options={medSchd}
                                    getOptionLabel={(option) => option}
                                    value={val.medSchd || ""} // Set value from medList
                                    onChange={(e, value) => {
                                      handleMedChange(idx, "medSchd", value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Schd"
                                        margin="dense"
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item className={classes.medSchdTime}>
                                  <Autocomplete
                                    id={`medTime-${idx}`} // Unique ID for accessibility
                                    margin="dense"
                                    classes={{ option: classes.autocomp }}
                                    freeSolo
                                    options={medSchdTime}
                                    getOptionLabel={(option) => option}
                                    value={val.medTime || ""} // Set value from medList
                                    onChange={(e, value) => {
                                      handleMedChange(idx, "medTime", value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Schd Time"
                                        margin="dense"
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item className={classes.medCountField}>
                                  <TextField
                                    margin="dense"
                                    id={`medCount-${idx}`} // Unique ID for accessibility
                                    name="medCount"
                                    label="Count"
                                    type="number"
                                    value={val.medCount || ""} // Set value from medList
                                    onChange={(e) =>
                                      handleMedChange(
                                        idx,
                                        "medCount",
                                        e.target.value
                                      )
                                    } // Handle change
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                                <Grid>
                                  <Tooltip title="Is from outside ?">
                                    <Checkbox
                                      checked={val.medOut}
                                      onChange={(e) => {
                                        handleMedChange(
                                          idx,
                                          "medOut",
                                          e.target.checked
                                        ); // Update medOut state
                                      }}
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid className={classes.fabBut}>
                                  {idx === RxList.json_data.length - 1 ? (
                                    <Fab
                                      color="primary"
                                      aria-label="add"
                                      size="small"
                                      onClick={add} // Call add function
                                      className={classes.addBut}
                                    >
                                      <AddIcon />
                                    </Fab>
                                  ) : (
                                    <Fab
                                      color="inherit"
                                      aria-label="del"
                                      size="small"
                                      onClick={() => handleDeleteMedRow(idx)} // Call delete function
                                    >
                                      <RemoveIcon />
                                    </Fab>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          ))}

                        {/* <TextField
                      margin="normal"
                      id="med-dia1"
                      name="medCmp"
                      label="Brand"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    /> */}

                        {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}
                {values || types === tabs[7] ? (
                  <>
                    <TabPanel value={tabs[7]}>
                      <h1>{isEditDialogOpen ? "Edit" : "Add"} Ix Template</h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        // onChange={handleChangePaymentProps}
                      >
                        <TextField
                          margin="normal"
                          id="Ix-dia"
                          name="IxName"
                          label="Ix Template Name"
                          type="string"
                          className={classes.fieldTxt}
                          value={repList?.name}
                          onChange={(e) => handleChange(e, "IxName")}
                        />

                        <Autocomplete
                          style={{ marginRight: "10px" }}
                          multiple
                          id="multi-field"
                          size="small"
                          classes={{ option: classes.autocomp }}
                          autoSelect
                          freeSolo
                          disableClearable={false} // Allow clearing
                          options={repSug} // Suggestions passed from props
                          value={repList?.json_data || []} // Display the current repList values or an empty array
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            // When an item is added or removed, update the repList in the parent
                            handleRepChange(newValue); // Call the parent's handleRepChange
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Ix"
                              multiline
                              rows={3}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                        {/* <TextField
                      margin="normal"
                      id="med-dia1"
                      name="medCmp"
                      label="Brand"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    /> */}

                        {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}
                {values || types === tabs[8] ? (
                  <>
                    <TabPanel value={tabs[8]}>
                      <h1>
                        {isEditDialogOpen ? "Edit" : "Add"} Advice Template
                      </h1>
                      <form
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          width: "100%",
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        {/* Advice Template Name */}
                        <TextField
                          margin="normal"
                          id="advice-dia"
                          name="adviceName"
                          label="Advice Template Name"
                          type="text"
                          style={{
                            width: "30%",
                          }}
                          onChange={(e) => handleChange(e, "adviceName")}
                          value={adviceList?.name}
                        />

                        {/* Advice Textarea */}
                        <TextField
                          id="outlined-basic"
                          multiline
                          label="Advice"
                          rows={2}
                          name="diaNotes"
                          variant="outlined"
                          style={{
                            width: "100%",
                          }}
                          value={adviceList?.json_data[0]?.notes || ""}
                          onChange={(event) => {
                            handleAdviceChange(event.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                        {/* Optional Add Button (if needed) */}
                        {/* <Button
        variant="contained"
        color="primary"
        onClick={handleClickAdd}
        style={{
          alignSelf: 'flex-start',
        }}
      >
        Add
      </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}

                {values || types === tabs[9] ? (
                  <>
                    <TabPanel value={tabs[9]}>
                      <h1>
                        {isEditDialogOpen ? "Edit" : "Add"} Payment Template
                      </h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        // onChange={handleChangePaymentProps}
                      >
                        <TextField
                          margin="normal"
                          id="panymentTemp-dia"
                          name="PaymentTempName"
                          label="Payment Template Name"
                          type="string"
                          className={classes.fieldTxt}
                          value={paymentList?.name}
                          onChange={(e) => handleChange(e, "PaymentTempName")}
                        />

                        <Table
                          style={{
                            marginTop: "15px",
                            border: "1px solid rgba(224, 224, 224, 1)",
                            width: "auto", // Table Border
                          }}
                          size="small" // Reduce padding in the table
                        >
                          <TableHead>
                            <TableRow
                              style={{
                                backgroundColor: "rgb(238, 249, 222)",
                              }}
                            >
                              <TableCell
                                style={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                  color: "#546e7a",
                                  fontSize: "12px",
                                  width: "35px",
                                  fontWeight: "bold",
                                }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                  width: "300px",
                                  color: "#546e7a",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                Name
                              </TableCell>

                              <TableCell
                                style={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                  width: "200px",
                                  color: "#546e7a",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                Price
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                  width: "200px",
                                  color: "#546e7a",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                Discount (%)
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                  width: "200px",
                                  color: "#546e7a",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                }}
                              >
                                Total
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "1px solid white",
                                  padding: "2px",
                                  backgroundColor: "white",
                                }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Array.isArray(paymentList?.json_data) &&
                              paymentList?.json_data?.map((row, index) => {
                                return (
                                  <TableRow key={index}>
                                    {/* Row Number */}
                                    <TableCell
                                      style={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                        padding: "2px",
                                      }}
                                    >
                                      {index + 1}
                                    </TableCell>

                                    {/* Complaint Autocomplete */}
                                    <TableCell
                                      style={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                        padding: "2px",
                                      }}
                                    >
                                      <Autocomplete
                                        options={
                                          paymentSug?.map((option) => ({
                                            label:
                                              option?.split(".")[1]?.trim() ||
                                              "",
                                            value: option?.split(".")[0] || "",
                                          })) || []
                                        }
                                        getOptionLabel={(option) =>
                                          option && option.label
                                            ? option.label
                                            : ""
                                        }
                                        freeSolo
                                        disableClearable
                                        value={
                                          row.name ? { label: row.name } : null
                                        }
                                        onChange={(event, newValue) => {
                                          handlePayment(
                                            index,
                                            "name",
                                            newValue ? newValue.label : ""
                                          );
                                          handlePayment(
                                            index,
                                            "price",
                                            newValue ? newValue.value : ""
                                          );
                                        }}
                                        onInputChange={(
                                          event,
                                          newInputValue
                                        ) => {
                                          handlePayment(
                                            index,
                                            "name",
                                            newInputValue
                                          );

                                          const matchingOption =
                                            paymentSug?.find(
                                              (option) =>
                                                option
                                                  ?.split(".")[1]
                                                  ?.trim() === newInputValue
                                            );
                                          // console.log(matchingOption);
                                          if (matchingOption) {
                                            handlePayment(
                                              index,
                                              "price",
                                              matchingOption.split(".")[0]
                                            );
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            InputProps={{
                                              ...params.InputProps,
                                              disableUnderline: true,
                                            }}
                                          />
                                        )}
                                      />
                                    </TableCell>

                                    {/* Price Field */}
                                    <TableCell
                                      style={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                        padding: "2px",
                                      }}
                                    >
                                      <TextField
                                        value={row.price}
                                        variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (
                                            !isNaN(value) &&
                                            /^[0-9]*\.?[0-9]*$/.test(value)
                                          ) {
                                            handlePayment(
                                              index,
                                              "price",
                                              value
                                            );
                                          }
                                        }}
                                      />
                                    </TableCell>

                                    {/* Discount Field */}
                                    <TableCell
                                      style={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                        padding: "2px",
                                      }}
                                    >
                                      <TextField
                                        value={row.discount}
                                        variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                        onChange={(event) => {
                                          const value = event.target.value;
                                          if (
                                            !isNaN(value) &&
                                            /^[0-9]*\.?[0-9]*$/.test(value)
                                          ) {
                                            handlePayment(
                                              index,
                                              "discount",
                                              value
                                            );
                                          }
                                        }}
                                      />
                                    </TableCell>

                                    {/* Total Field */}
                                    <TableCell
                                      style={{
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                        padding: "2px",
                                        backgroundColor: "#f8f9fa",
                                      }}
                                    >
                                      <TextField
                                        value={row.total}
                                        variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                          readOnly: true,
                                        }}
                                      />
                                    </TableCell>

                                    {/* Delete Button */}
                                    <TableCell
                                      style={{
                                        border: "1px solid white",
                                        padding: "2px",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() =>
                                          handleDeletePaymentRow(index)
                                        }
                                        size="small"
                                      >
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                        <p style={{ marginTop: "10px" }}>
                          Sub Total:{" "}
                          {paymentList?.json_data
                            ?.filter(
                              (row) =>
                                !isNaN(Number(row.total)) && row.total !== ""
                            )
                            .reduce((sum, row) => sum + Number(row.total), 0)
                            .toFixed(2)}
                        </p>
                        {/* <TextField
                      margin="normal"
                      id="med-dia1"
                      name="medCmp"
                      label="Brand"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    /> */}

                        {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}

                {values || types === tabs[0] ? (
                  <>
                    <TabPanel value={tabs[0]}>
                      <h1>{isEditDialogOpen ? "Edit" : "Add"} Report</h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        onChange={handleChangeRepProps}
                      >
                        <TextField
                          margin="normal"
                          id="rep-dia"
                          name="repName"
                          label="Report"
                          type="string"
                          className={classes.fieldTxt}
                          value={reps.repName}
                        />
                        {/* <Button
                      variant="contained"
                      onClick={handleClickAdd}
                      color="primary"
                      className={classes.addBtn}
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}
                {values || types === tabs[3] ? (
                  <>
                    <TabPanel value={tabs[3]}>
                      <h1>{isEditDialogOpen ? "Edit" : "Add"} Disease</h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        onChange={handleChangeDisProps}
                      >
                        <TextField
                          margin="normal"
                          id="dis-dia"
                          name="disName"
                          label="Disease"
                          type="string"
                          className={classes.fieldTxt}
                          value={dis.disName}
                        />

                        {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}
                {values || types === tabs[2] ? (
                  <>
                    <TabPanel value={tabs[2]}>
                      <h1>{isEditDialogOpen ? "Edit" : "Add"} Symptom</h1>
                      <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                        onChange={handleChangeSymtProps}
                      >
                        <TextField
                          margin="normal"
                          id="sym-dia"
                          name="symName"
                          label="Symptom"
                          type="string"
                          className={classes.fieldTxt}
                          value={symt.symName}
                        />

                        {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                      </form>
                    </TabPanel>
                  </>
                ) : (
                  <></>
                )}
                {/* <TabPanel value={tabs[4]}>
                  <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onChange={handleChangeDrProps}
                  >
                    <TextField
                      margin="normal"
                      id="dr-fst-dia"
                      name="drFirstNm"
                      label="First Name"
                      type="string"
                      className={classes.fieldTxt}
                    />
                    <TextField
                      margin="normal"
                      id="dr-lst-dia"
                      name="drLastNm"
                      label="Last Name"
                      type="string"
                      className={classes.fieldTxt}
                    />
                    <TextField
                      margin="normal"
                      id="dr-spl-dia"
                      name="drSpecl"
                      label="Specialist"
                      type="string"
                      className={classes.fieldTxt}
                    />
                    <TextField
                      margin="normal"
                      id="dr-mob-dia"
                      name="drMob"
                      label="Mobile"
                      type="string"
                      className={classes.fieldTxt}
                    />
            
                  </form>
                </TabPanel> */}
              </TabContext>
            </div>
          </DialogContent>
          <DialogActions>
            {isEditDialogOpen ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => handleClickEdit(e, types)}
                // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                className={classes.addBtn}
                // color="primary"
              >
                EDIT
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => handleClickAdd(e, values)}
                // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                className={classes.addBtn}
                // color="primary"
              >
                Add
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleClickClose}
              className={classes.addBtn}
              // color="primary"
            >
              Close
            </Button>
            {/* <Button onClick={handleClickClose} color="primary">
            Agree
          </Button> */}
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default AddReport;
