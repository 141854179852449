import React from "react";
// import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { hostInfo } from "../../store";
import {
  DailyGraph,
  MonthlyGraph,
  DrReference,
  DailyCases,
  DashboardCards,
} from "./components";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    // backgroundColor: "green",
  },
  title: {
    marginTop: theme.spacing(3),
    margin: theme.spacing(2),
    // [theme.breakpoints.down("sm")]: {
    //   textAlign: "right", // Align text to the right on mobile screens
    // },
  },
}));

const Dashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const [dashboardCard, setDashboardCard] = useState({});
  const [user, setUser] = useState([]);
  console.log(user);
  // useEffect(() => {
  //   removeTokenAfterTime(10);
  // }, []);

  // const removeTokenAfterTime = (minutes) => {
  //   // Calculate the time in milliseconds
  //   const timeInMilliseconds = minutes * 60 * 1000;

  //   // Schedule the removal of token after the specified time
  //   setTimeout(() => {
  //     localStorage.removeItem("authToken");
  //     history.push("/login");
  //   }, timeInMilliseconds);
  // };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const url = `${hostInfo}/getdashboardcard`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        const data = await response.json();
        if (data) setDashboardCard(data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const token = getToken();
        const payload = JSON.parse(atob(token.split(".")[1]));
        const currentUser = data.users.find(
          (u) => u.username === payload.username
        );
        setUser(currentUser);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchDashboardData();
    fetchUserInfo();
  }, []);
  useEffect(() => {
    if (user) {
      if (user.accesses_flag === 4) {
        history.push("/patients");
      } else {
        history.push("/dashboard");
      }
    }
  }, [user, history]);

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h2"
        gutterBottom
        color="textSecondary"
      >
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={3} sm={6} xl={3} xs={6}>
          <Paper elevation={3}>
            <DashboardCards
              title="Total Patients"
              value={dashboardCard.total_ptn}
              unit="(No.)"
              color="#76B947"
            />
          </Paper>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={6}>
          <Paper elevation={3}>
            <DashboardCards
              title="New Patient(Cur Mnt)"
              value={dashboardCard.cur_mnth_ptn}
              unit="(No.)"
              color="#FF2768"
            />
          </Paper>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={6}>
          <Paper elevation={3}>
            <DashboardCards
              title="Revenue(Cur Mnt)"
              value={dashboardCard.cur_mnth_rev}
              unit="(&#8377;)"
              color="#658EA9"
            />
          </Paper>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={6}>
          <Paper elevation={3}>
            <DashboardCards
              title="Revenue(Today)"
              value={dashboardCard.today_rev}
              unit="(&#8377;)"
              color="#F3BC50"
            />
          </Paper>
        </Grid>
        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <Paper elevation={3}>
            <DailyGraph />
          </Paper>
        </Grid>
        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <Paper elevation={3}>
            <MonthlyGraph />
          </Paper>
        </Grid>
        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <Paper elevation={3}>
            <DrReference />
          </Paper>
        </Grid>
        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <Paper elevation={3}>
            <DailyCases />
          </Paper>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          {/* <TotalProfit /> */}
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          {/* <LatestSales /> */}
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          {/* <UsersByDevice /> */}
        </Grid>
      </Grid>
      {/* {text} */}
    </div>
  );
};

export default Dashboard;
