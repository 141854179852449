import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { hostInfo } from "../store";
import myLogo from "../images/avatar/logo1.png";

function Login() {
  const history = useHistory();
  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [redirect, setRedirect] = useState(false);
  const [user, setUser] = useState("");
  const useStyles = makeStyles((theme) => ({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      padding: theme.spacing(0.5),
      background:
        "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      background:
        "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
      margin: theme.spacing(3, 0, 2),
      padding: "10px",
    },
  }));
  const classes = useStyles();

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await fetch("http://localhost:5050/signIn", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-SourceIp": "192.168.1.8",
  //       },
  //       body: JSON.stringify(loginData),
  //     });

  //     if (response.status === 200) {
  //       const data = await response.json();
  //       const token = data.token;

  //       // Decode the token to get the expiration time
  //       const decodedToken = JSON.parse(atob(token.split(".")[1]));
  //       const expirationTime =
  //         decodedToken.exp * 1000 || Date.now() + 2 * 60 * 1000; // Convert to milliseconds

  //       // Store the token along with the expiration time
  //       localStorage.setItem(
  //         "authToken",
  //         JSON.stringify({
  //           token: token,
  //           expiresAt: expirationTime,
  //         })
  //       );

  //       setLoginData({ username: "", password: "" });
  //       checkAuth();
  //     } else {
  //       const errorData = await response.json();
  //       alert(errorData.error || "An unknown error occurred.");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("An error occurred during login");
  //   }
  // };

  // const checkAuth = async () => {
  //   try {
  //     let tokenData = localStorage.getItem("authToken");
  //     if (!tokenData) {
  //       console.error("Authentication token is missing");
  //       return;
  //     }

  //     tokenData = JSON.parse(tokenData);
  //     const token = tokenData.token;

  //     const response = await fetch("http://localhost:5050/dashboard", {
  //       method: "GET",
  //       headers: {
  //         Authorization: token,
  //       },
  //     });

  //     if (response.status === 200) {
  //       history.push("/dashboard");
  //     } else if (response.status === 401) {
  //       // Unauthorized, redirect to login page or handle the error
  //       console.error("Unauthorized access");
  //       // Redirect to login page or show an error message
  //     } else {
  //       // Handle other errors
  //       const errorData = await response.json();
  //       alert(errorData.error || "An unknown error occurred.");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${hostInfo}/signIn`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (response.status === 200) {
        const data = await response.json();

        const tokens = data.token;
        const tokenData = JSON.parse(atob(tokens.split(".")[1]));
        // console.log(new Date(tokenData.exp * 1000).toUTCString());
        // console.log(tokenData.loginTime);
        // console.log(new Date(tokenData.loginTime * 1000));
        // console.log(tokenData.exp * 1000 - Date.now());
        // console.log(new Date(tokenData.loginTime * 1000).toUTCString());
        // Set token in cookies
        document.cookie = `authToken=${tokens}; max-age=${
          tokenData.exp * 1000
        }; path=/`;
        console.log(JSON.parse(atob(tokens.split(".")[1])));
        setLoginData({ username: "", password: "" });
        // history.push("/tableColummn");
        history.push("/dashboard");
        // checkAuth();
      } else {
        const errorData = await response.json();
        alert(errorData.error || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred during login");
    }
  };

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    const a = getToken();
    // const fetchUserInfo = async () => {
    //   try {
    //     const response = await fetch(`${hostInfo}/usernames`, {
    //       headers: {
    //         Authorization: `Bearer ${getToken()}`,
    //       },
    //     });

    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }

    //     const data = await response.json();
    //     const a = getToken();
    //     const b = JSON.parse(atob(a.split(".")[1]));
    //     const user = data.users.filter((i) => i.username === b.username);
    //     setUser(user);
    //   } catch (error) {
    //     console.error("There was an error fetching the user info!", error);
    //   }
    // };

    // fetchUserInfo();
    if (a) {
      // if (user[0].accesses_flag === 4) {
      history.push("/dashboard");
      // } else {
      //   history.push("/dashboard");
      // }
    } else {
      history.push("/");
    }
  }, [history]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar src={myLogo} className={classes.avatar} />
        {/* <Avatar className={classes.avatar}></Avatar> */}
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username "
            name="username"
            autoComplete="username"
            autoFocus
            value={loginData.username}
            onChange={(e) =>
              setLoginData({ ...loginData, username: e.target.value })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={loginData.password}
            onChange={(e) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
          >
            Login
          </Button>
          {/* <Grid container justify="flex-end">
            <Grid item>
              <Link href="/signup" variant="body2">
                Sign Up
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
    </Container>
  );
}

export default Login;
