import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  Typography,
  TextField,
  // DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
// import PatientVisitList from "./PatientVisitList";
import { hostInfo } from "../../../store";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
    },
  },
  formFieldCls: {
    marginRight: theme.spacing(3),
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  addGroupBtn: {
    background: "#FF6085",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#9BE27A",
    },
    // marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  diaHeadText: {
    // background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    margin: theme.spacing(3),
    // display: "flex",
    // alignItems: "center",
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
  formDetailFields: {
    width: 450,
  },
}));

const DrDetailsDia = (props) => {
  const {
    isOpen = false,
    handleCloseDia = () => {},
    title = "My Dialog",
    id = 0,
    groupId = 0,
  } = props;
  // const [id, setId] = useState(0);
  // const []
  const [drName, setDrName] = useState("");
  const [drMobile, setDrMobile] = useState("");
  const [drDesc, setDrDesc] = useState("");
  console.log(drDesc);
  useEffect(() => {
    setDrName(props.drName);
    setDrMobile(props.drMobile);
    setDrDesc(props.drDesc);
  }, [props]);

  // // console.log("Got props --------");
  // console.log(props);
  // console.log(id);
  // console.log(drName);
  // console.log(drMobile);
  // console.log(drDesc);

  const handleClickClose = () => {
    handleCloseDia(false);
    // window.location.reload();
  };

  const handleChange = (e) => {
    // console.log("Inside handle on click --");
    let name = e.target.name;
    let value = e.target.value;
    // console.log(name);
    // console.log(value);
    if (name === "drName") {
      setDrName(value);
    } else if (name === "drMobile") {
      setDrMobile(value);
    } else if (name === "drDesc") {
      let newValue = value;
      setDrDesc(newValue);
    }
  };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  const handleSubmit = () => {
    if (id === 0) {
      //
      // id == 0 -> Add the dr details
      //
      if (groupId === 0 || drName === null || drName === "") {
        // console.log("Invalid dr Details to Add");
        return;
      }
      let data = {
        dr_group_id: groupId,
        name: drName,
        mobile: drMobile,
        description: drDesc,
        id: 0,
      };

      let url = hostInfo + "/adddrdetails";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      }).then(props.updateCurList(data));
      // window.location.reload();
      handleCloseDia(false);
    } else if (id > 0) {
      //
      // id > 0 -> Edit the details...
      //
      if (id === 0 || drName === null || drName === "") {
        // console.log("Invalid dr Details to Update");
        return;
      }
      let data = {
        id: id,
        name: drName,
        mobile: drMobile,
        description: drDesc,
      };

      let url = hostInfo + "/updatedrdetails";

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            // If the response is successful, reload the page
            // window.location.reload();
          } else {
            // Handle the error case
            return response.json().then((err) => {
              console.error("Error response:", err);
              alert(
                "Failed to update details: " + (err.message || "Unknown error")
              );
            });
          }
        })
        .catch((error) => {
          // Handle any network or fetch errors
          console.error("Network error:", error);
          alert("An error occurred while updating details.");
        });
    } else {
      // console.log("Invalid dr id got.. Id: ", id);
    }
  };
  // const handleKeyDown = (e) => {
  //   if (e.key === ",") {
  //     e.preventDefault(); // Prevent default Enter behavior (new line)
  //     setDrDesc(drDesc + ","); // Add semicolon instead
  //   }
  // };

  const classes = useStyles();

  return (
    <>
      {/* <div> */}
      <Dialog
        open={isOpen}
        keepMounted
        fullWidth={true}
        // keepMounted={true}
        maxWidth={"md"}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Typography
          gutterBottom
          variant="h4"
          component="h2"
          color="textSecondary"
          className={classes.diaHeadText}
          // justify="space-between"

          // margin=""
        >
          {title}
        </Typography>
        <DialogContent>
          <form noValidate autoComplete="off" onChange={handleChange}>
            <TextField
              margin="normal"
              id="dr-name-id"
              name="drName"
              value={drName}
              label="Name"
              type="string"
              className={classes.formFieldCls}

              // onChange={(e) => handleOnMedValChange(e, "medName")}
            />
            <TextField
              margin="normal"
              id="mobile-name-id"
              name="drMobile"
              value={drMobile}
              label="Mobile"
              type="string"
              className={classes.formFieldCls}

              // onChange={(e) => handleOnMedValChange(e, "medName")}
            />
            <TextField
              margin="normal"
              multiline
              minRows={4}
              maxRows={10}
              variant="outlined"
              id="dr-desc-id"
              name="drDesc"
              value={drDesc}
              label="Details"
              fullWidth={true}
              type="string"
              className={classes.formDetailFields}

              // onChange={(e) => handleOnMedValChange(e, "medName")}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* </div> */}
    </>
  );
};

export default DrDetailsDia;
