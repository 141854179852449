import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import ListIcon from "@material-ui/icons/List";
// import VisitListDia from "./diaComponent/VisitListDia";

// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { hostInfo } from "../../../store";
import { Button, Chip, Tooltip } from "@material-ui/core";
import { Box, Avatar } from "@material-ui/core";
import EditPatient from "./EditCertificate";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
}));

const CertificateListTbl = (props) => {
  const {
    handleClickOpen,
    open,
    closeHdl,
    patientGrpList,
    handleDelPatient,
    patientList,
  } = props;
  // console.log(patientList);
  // const [patientList, setPatientList] = useState([]);
  // const [patientGrpList, setPatientGrpList] = useState([]);
  // const [data, setData] = useState(rawData);
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [patientId, setPatientId] = useState(0);
  const [user, setUser] = useState("");
  console.log(user);
  // const [dialogWord, setDialogWord] = useState('');
  // const [dialogId, setDialogId] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const a = getToken();
        const b = JSON.parse(atob(a.split(".")[1]));
        const user = data.users.filter((i) => i.username === b.username);
        setUser(user);
      } catch (error) {
        console.error("There was an error fetching the user info!", error);
      }
    };

    fetchUserInfo();
  }, []);

  // const handleDialogClose = (event) => {
  //   setIsDialogOpen(false);
  // };

  // const handleId = event => {
  //   setDialogId(event.target.value);
  // }

  // const handleWord = event => {
  //   setDialogWord(event.target.value);
  // }

  // const handleAddNewRow = event => {
  //   if (!dialogId || !dialogWord) return;

  //   setData(
  //     // Here you can add the new row to whatever index you want
  //     [{ id: dialogId, word: dialogWord }, ...data]
  //   );
  // }

  const columns = [
    {
      title: "Name",
      field: "name",
      searchable: true,
      render: (rowData) => (
        <div
          className={classes.containerIcon}
          // onClick={(e, rowData) => handlePatientClick(e, rowData)}
        >
          <Avatar
            variant="rounded"
            className={`${rowData.status ? classes.active : classes.inactive}`}
          >
            {rowData.case_no}
          </Avatar>

          <Box fontSize={16} fontFamily="Helvetica" fontWeight={550}>
            {rowData.name}
          </Box>
        </div>
      ),
    },

    // {
    //   title: "Purpose",
    //   field: "purpose",
    //   // type: "numeric",
    //   searchable: true,
    //   render: (rowData) => {
    //     const purposeText = rowData.purpose || "No purpose available";
    //     return purposeText.length > 50 ? (
    //       <Tooltip title={purposeText} arrow>
    //         <span>{purposeText.substring(0, 50)}...</span>
    //       </Tooltip>
    //     ) : (
    //       <span>{purposeText}</span>
    //     );
    //   },
    //   // align: "center",
    // },
    {
      title: "Date",
      field: "date",
      // type: "numeric",
      searchable: false,
      // align: "center",
    },
    {
      title: "Age",
      field: "age",
      // type: "numeric",
      searchable: false,
      // align: "center",
    },
    {
      title: "Diagnosis",
      field: "diagnosis",
      // type: "numeric",
      searchable: false,
      // align: "center",
    },
    {
      title: "Total",
      field: "total",
      // type: "numeric",
      searchable: false,
      // align: "center",
    },
    {
      title: "Paid",
      field: "paid",
      // type: "numeric",
      searchable: false,
      // align: "center",
    },
    {
      title: "Remain",
      field: "remain",
      // type: "numeric",
      searchable: false,
      // sorting: true,
      // align: "center",
      // render: (rowData) => (
      //   <div>
      //     <Chip
      //       label={rowData.remain}
      //       color="primary"
      //       style={{
      //         backgroundColor:
      //           rowData.remain === 0
      //             ? "#D1D1D1"
      //             : rowData.remain > 0
      //             ? "#FFA1A1"
      //             : "#B2C8DF",
      //       }}
      //     />
      //   </div>
      // ),
    },
    // {
    //   title: "Info",
    //   field: "info",
    //   // align: "center",
    //   render: (rowData) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         gap: "10px",
    //       }}
    //     >
    //       <Button
    //         // variant="outlined"
    //         // size="small"
    //         style={{
    //           backgroundColor: "#5E92C2",
    //           // borderColor: "#007bff",
    //           padding: "2px 4px",
    //           color: "white",
    //         }}
    //         onClick={() => addOpd(rowData.id)}
    //       >
    //         Add OPD
    //       </Button>
    //       {user[0].accesses_flag === 2 && (
    //         <Button
    //           // variant="outlined"
    //           // size="small"

    //           style={{
    //             backgroundColor: "#ef9a9a",
    //             // borderColor: "#007bff",
    //             padding: "2px 4px",
    //             color: "white",
    //           }}
    //           onClick={() => handlePatientClick(rowData.id)}
    //         >
    //           Without
    //         </Button>
    //       )}
    //     </div>
    //   ),
    // },
  ];

  // useEffect(() => {
  //   let url = "http://" + hostInfo + "/getptinfo";
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res != null) {
  //         if (res != null) {
  //           console.log(res);
  //           setPatientList(res);
  //         }
  //       }
  //     });
  // }, []);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  return (
    <div className={classes.inner}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Fragment>
        <MaterialTable
          title="Visit List"
          columns={columns}
          data={patientList.map((patient) => ({
            name: patient.firstname + " " + patient.lastname,
            date:
              new Date(patient.date_time)
                .getUTCDate()
                .toString()
                .padStart(2, "0") +
              "-" +
              (new Date(patient.date_time).getUTCMonth() + 1)
                .toString()
                .padStart(2, "0") +
              "-" +
              new Date(patient.date_time).getUTCFullYear(),
            age: patient.age,
            diagnosis: JSON.parse(patient.dr_notes),
            total: patient.amount,
            paid: patient.paid_amount,
            remain: patient.amount - patient.paid_amount,
            // purpose: patient.purpose,

            id: patient.id,
            info: null,
            // email: patient.mail_id,
          }))}
          // actions={[
          //   {
          //     icon: "edit",
          //     onClick: (event, rowData) => {
          //       setPatientId(rowData.id);
          //       let patient = patientList.find(
          //         (item) => item.id === rowData.id
          //       );
          //       setPatientInfo(patient);
          //       handleClickOpen();
          //     },

          //     tooltip: "Edit",
          //   },
          //   {
          //     icon: "delete",
          //     tooltip: "Delete User",
          //     onClick: (event, rowData) => {
          //       const url =
          //         hostInfo + "/deleteCertificate?certificate_id=" + rowData.id;
          //       if (window.confirm("You want to delete " + rowData.name)) {
          //         fetch(url, {
          //           method: "POST",

          //           headers: {
          //             Accept: "*/*",
          //             Authorization: `Bearer ${getToken()}`,
          //           },
          //         })
          //           .then(() => {
          //             handleDelPatient(
          //               patientList.filter(
          //                 (patient) => patient.id !== rowData.id
          //               )
          //             );
          //             // console.log("Vist " + rowData.id + " Deleted Successfully");
          //           })
          //           .catch((error) => {
          //             console.error("There was an error!", error);
          //           });
          //       }
          //     },
          //   },
          // ]}
          options={{
            pageSizeOptions: [5, 10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#AFE1CE",
              color: "#000",
            },
            sorting: true,

            minBodyHeight: "auto",

            actionsColumnIndex: -1,
          }}
          // onRowClick={(event, rowData, togglePanel) =>
          //   handlePatientClick(rowData.id)
          // }
        />
        {open ? (
          <EditPatient
            open={open}
            closeHdl={closeHdl}
            // title="Visit Details"
            // isOpen={isDialogOpen}
            // onClose={handleDialogClose}
            patientGrpList={patientGrpList}
            classes={classes}
            patientId={patientId}
            patientInfo={patientInfo}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default CertificateListTbl;
