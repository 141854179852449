import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import ListIcon from "@material-ui/icons/List";
// import VisitListDia from "./diaComponent/VisitListDia";

// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { hostInfo } from "../../../store";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { Box, Avatar } from "@material-ui/core";
import EditPatient from "./EditPatient";
import { useEffect } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
}));

const PatientsListTbl = (props) => {
  const {
    handleClickOpen,
    open,
    closeHdl,
    patientGrpList,
    handleDelPatient,
    patientList,
  } = props;

  // const [patientList, setPatientList] = useState([]);
  // const [patientGrpList, setPatientGrpList] = useState([]);
  // const [data, setData] = useState(rawData);
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [patientId, setPatientId] = useState(0);
  const [user, setUser] = useState("");
  const [selectedDoctors, setSelectedDoctors] = useState(null);
  const [doctorList, setDoctorList] = useState([]); // List of doctors to display in checkboxes
  const [dialogOpen, setDialogOpen] = useState(false);
  console.log(doctorList);
  // const [dialogWord, setDialogWord] = useState('');
  // const [dialogId, setDialogId] = useState('');
  const classes = useStyles();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const a = getToken();
        const b = JSON.parse(atob(a.split(".")[1]));
        const user = data.users.filter((i) => i.username === b.username);

        setDoctorList(
          data.users.filter(
            (i) => i.accesses_flag === 3 || i.accesses_flag === 2
          )
        );
        setUser(user);
      } catch (error) {
        console.error("There was an error fetching the user info!", error);
      }
    };

    fetchUserInfo();
  }, []);

  // const handleDialogClose = (event) => {
  //   setIsDialogOpen(false);
  // };

  const handlePatientClick = (id) => {
    const json_id = {
      patient_id: id,
      opd_id: -1,
    };
    const jsonString = JSON.stringify(json_id);

    // Encode the string to Base64
    const base64Encoded = btoa(jsonString);
    // console.log("Patient Id ----------");
    // console.log(id);
    // localStorage.setItem("opd_id", -1);
    window.location = "/patients/patientprofile?id=" + base64Encoded;
  };
  // const handleId = event => {
  //   setDialogId(event.target.value);
  // }

  // const handleWord = event => {
  //   setDialogWord(event.target.value);
  // }

  // const handleAddNewRow = event => {
  //   if (!dialogId || !dialogWord) return;

  //   setData(
  //     // Here you can add the new row to whatever index you want
  //     [{ id: dialogId, word: dialogWord }, ...data]
  //   );
  // }
  const handleDoctorSelect = (event) => {
    const doctorName = event.target.value;
    // Set the selected doctor to the state
    setSelectedDoctors(doctorName === selectedDoctors ? null : doctorName); // Deselect if same doctor is clicked
  };

  const handleAddOpd = () => {
    if (selectedDoctors) {
      addOpd(patientId); // Pass the selected doctor to the API
    }
    handleDialogClose(); // Close the dialog after adding OPD
  };

  // Close the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedDoctors([]); // Reset selected doctors
  };
  const handleAddOpdDialog = (rowData) => {
    setPatientId(rowData.id);
    setDialogOpen(true);
  };
  const addOpd = (patientId) => {
    const url = `${hostInfo}/addOpd`; // Replace with your actual API endpoint
    const data = {
      patient_id: patientId,
      doctors: selectedDoctors,
      accesses_flag: doctorList.find((i) => i.username === selectedDoctors)
        ?.accesses_flag,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          alert("Patient added to OPD successfully");
          // Optionally refresh data or update UI
        } else {
          alert("Failed to add patient to OPD");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("There was an error adding patient to OPD");
      });
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      searchable: true,
      render: (rowData) => (
        <div
          className={classes.containerIcon}
          // onClick={(e, rowData) => handlePatientClick(e, rowData)}
        >
          <Avatar
            variant="rounded"
            className={`${rowData.status ? classes.active : classes.inactive}`}
          >
            {rowData.case_no}
          </Avatar>

          <Box fontSize={16} fontFamily="Helvetica" fontWeight={550}>
            {rowData.name}
          </Box>
        </div>
      ),
    },
    {
      title: "Gender",
      field: "gender",
      lookup: { M: "Male", F: "Female", O: "Other" },
    },
    { title: "Current Age", field: "age", searchable: false },
    { title: "Group", field: "grp", searchable: true },
    // { title: "Mail Id", field: "email" },
    {
      title: "Phone",
      field: "phone",
      // type: "numeric",
      searchable: true,
      // align: "center",
    },
    {
      title: "Info",
      field: "info",
      align: "center",
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          {user[0]?.accesses_flag === 4 && (
            <Button
              // variant="outlined"
              // size="small"
              style={{
                backgroundColor: "#5E92C2",
                // borderColor: "#007bff",
                padding: "2px 4px",
                color: "white",
              }}
              onClick={() => handleAddOpdDialog(rowData)}
            >
              Add OPD
            </Button>
          )}
          {(user[0]?.accesses_flag === 1 ||
            user[0]?.accesses_flag === 3 ||
            user[0]?.accesses_flag === 2) && (
            // <Button
            //   // variant="outlined"
            //   // size="small"

            //   style={{
            //     // marginRight: "10px",
            //     // textAlign: "left",
            //     // backgroundColor: "#ef9a9a",
            //     // borderColor: "#007bff",
            //     // padding: "2px 2px",
            //     // color: "white",
            //   }}
            //   onClick={() => handlePatientClick(rowData.id)}
            // >
            <Tooltip title="Start Consult">
              <GroupAddIcon
                onClick={() => handlePatientClick(rowData.id)}
                style={{
                  cursor: "pointer",
                }}
              />
            </Tooltip>

            // </Button>
          )}
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   let url = "http://" + hostInfo + "/getptinfo";
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res != null) {
  //         if (res != null) {
  //           console.log(res);
  //           setPatientList(res);
  //         }
  //       }
  //     });
  // }, []);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  return (
    <div className={classes.inner}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Fragment>
        <MaterialTable
          title="Patients List"
          columns={columns}
          data={patientList.map((patient) => ({
            name: patient.firstname + " " + patient.lastname,
            gender: patient.gender,
            age: patient.curr_age + " Yrs",
            grp: patient.patient_grp_name,
            disease: patient.disease_name,
            phone: patient.mobile,
            id: patient.id,
            info: null,
            // email: patient.mail_id,
          }))}
          actions={[
            {
              icon: "edit",
              onClick: (event, rowData) => {
                setPatientId(rowData.id);
                let patient = patientList.find(
                  (item) => item.id === rowData.id
                );
                setPatientInfo(patient);
                handleClickOpen();
              },

              tooltip: "Edit",
            },
            {
              icon: "delete",
              tooltip: "Delete User",
              onClick: (event, rowData) => {
                const url =
                  hostInfo + "/deletePatient?patient_id=" + rowData.id;
                if (window.confirm("You want to delete " + rowData.name)) {
                  fetch(url, {
                    method: "POST",

                    headers: {
                      Accept: "*/*",
                      Authorization: `Bearer ${getToken()}`,
                    },
                  })
                    .then(() => {
                      handleDelPatient(
                        patientList.filter(
                          (patient) => patient.id !== rowData.id
                        )
                      );
                      // console.log("Vist " + rowData.id + " Deleted Successfully");
                    })
                    .catch((error) => {
                      console.error("There was an error!", error);
                    });
                }
              },
            },
          ]}
          options={{
            pageSizeOptions: [5, 10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#AFE1CE",
              color: "#000",
            },
            sorting: true,

            minBodyHeight: "auto",

            actionsColumnIndex: -1,
          }}
          // onRowClick={(event, rowData, togglePanel) =>
          //   handlePatientClick(rowData.id)
          // }
        />

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Select Doctors</DialogTitle>
          <DialogContent>
            {/* Container for checkboxes, stacked vertically */}
            <div style={{ display: "block" }}>
              {doctorList.map((doctor) => (
                <FormControlLabel
                  key={doctor.id}
                  control={
                    <Checkbox
                      value={doctor.username}
                      onChange={handleDoctorSelect}
                      checked={selectedDoctors === doctor.username} // Check if it's the selected doctor
                    />
                  }
                  label={doctor.username}
                  style={{ display: "block" }} // Ensure vertical spacing
                />
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddOpd} color="primary">
              Add OPD
            </Button>
          </DialogActions>
        </Dialog>

        {open ? (
          <EditPatient
            open={open}
            closeHdl={closeHdl}
            // title="Visit Details"
            // isOpen={isDialogOpen}
            // onClose={handleDialogClose}
            patientGrpList={patientGrpList}
            classes={classes}
            patientId={patientId}
            patientInfo={patientInfo}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default PatientsListTbl;
