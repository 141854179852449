import React, { Fragment, createRef } from "react";
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Divider,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
  Modal,
  Popper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { hostInfo } from "../../../../store";
// import FuncComp from "./testFunc";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ClearIcon from "@mui/icons-material/Clear";

// import RemoveIcon from "@material-ui/icons/Remove";

import FormGroup from "@material-ui/core/FormGroup";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { Search } from "@material-ui/icons";
// import PrintHtml from "./PrintHtml/PrintHtml";
// import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: 230,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    // width: 230,
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
    //borderWidth: 4
  },
  presc: {
    width: "100%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
  cardClr: {
    background: "#F3F8F8",
  },
  freeTextNotes: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class EditReport extends React.Component {
    // constructor(props) {
    //   super(props);
    //   this.inputRef = React.createRef();
    // }
    containerRef = createRef();
    state = {
      search: "",
      dialogOpen: false,
      templateName: "",
      isSuggestionOpen: 1,

      openSuggestionIndex: false,
      templates: [],
      isListVisible: false,
      isTemplateClicked: false,
      isSaveTemplateClicked: false,
      isLoadPreviousClicked: false,
      // repList: [
      //   {
      //     index: Math.random(),
      //     repName: "",
      //   },
      // ],

      frequencyRefs: [],
      severityRefs: [],
      durationRefs: [],
      selectedComplaint: null,
      openModal: false,
      modalRowIndex: null,
    };

    // countryArr = countryStateCity.getAllCountries();
    stateArr = [];
    cityArr = [];
    selectedCountry = "";
    selectedState = "";
    selectedCity = "";
    stateDisabled = true;
    cityDisabled = true;
    // frequencyRefs = [];
    // severityRefs = [];
    // durationRefs = [];
    // medSug = [];
    // repSug = [];
    // drSug = [];
    // symSug = [];
    // disSug = [];
    // drGroups = [];

    // handleLoadPrevious = async () => {
    //   this.setState({ isLoadPreviousClicked: true });

    //   const getToken = () => {
    //     const cookieString = document.cookie;
    //     const cookies = cookieString.split("; ").reduce((acc, cookie) => {
    //       const [name, value] = cookie.split("=");
    //       acc[name] = value;
    //       return acc;
    //     }, {});
    //     return cookies.authToken;
    //   };

    //   try {
    //     const response = await fetch(
    //       `${hostInfo}/reports/${this.props.patient_id}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${getToken()}`,
    //         },
    //       }
    //     );

    //     if (!response.ok) {
    //       throw new Error("Failed to load previous report");
    //     }

    //     const data = await response.json();
    //     console.log(data); // Log the fetched data

    //     // Check if the data is an array and get the first report's repName
    //     const reports = Array.isArray(data) ? data : [data];
    //     const repName = reports[0]?.repName;

    //     // Check if repName exists before proceeding
    //     // if (repName) {
    //     // Here, use handleChange to set the value
    //     this.props.handleChange([JSON.parse(data)[0].repName]); // Assuming you want to set this to the first index of repList
    //     // } else {
    //     //   console.warn("No repName found in the report data");
    //     // }
    //   } catch (error) {
    //     console.error("Error loading previous reports:", error);
    //   }
    // };
    handleLoadPrevious = async () => {
      this.setState({ isLoadPreviousClicked: true });

      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      try {
        const response = await fetch(
          `${hostInfo}/reports/${this.props.patient_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to load previous report");
        }

        const data = await response.json();
        console.log(JSON.parse(data)); // Log the fetched data

        const currentRepList = this.props.repList || [];

        // Create a new array with existing values and new values, ensuring uniqueness
        const newRepName = Array.from(
          new Set([...currentRepList, ...JSON.parse(data)])
        );

        // Pass the combined unique values to the handleChange function
        this.props.handleRepChange(newRepName); // Default to an empty array if no report is found

        // Update the repList by merging existing values with loaded values
        // this.setState((prevState) => ({
        //   formdetail: {
        //     ...prevState.formdetail,
        //     repList: [...prevState.formdetail.repList, ...previousValues], // Merge existing and loaded values
        //   },
        // }));
      } catch (error) {
        console.error("Error loading previous reports:", error);
      }
    };

    handleDialogOpen = () => {
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });

      this.setState({ isSaveTemplateClicked: true });
      this.setState({ dialogOpen: true });
    };

    handleDialogClose = () => {
      this.setState({ isSaveTemplateClicked: false });
      this.setState({ dialogOpen: false });
    };

    handleSaveTemplate = () => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const { templateName, repList } = this.state;
      const shouldAddPreviousData =
        this.props.repList.length > 0 && this.props.repList[0].repName !== "";

      if (shouldAddPreviousData) {
        const savedData = { name: templateName, json_data: this.props.repList }; // Change `rows` to `json_data`

        fetch(hostInfo + "/addReportTemplate", {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`, // If required for authorization
          },
          body: JSON.stringify(savedData), // Ensure correct field name in payload
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("API Response:", data);
            if (data.message) {
              alert("Template saved successfully");
            } else {
              alert("Error saving template");
            }

            this.setState((prevState) => ({
              templates: [...(prevState.templates || []), savedData],
              // rows: [
              //   {
              //     complaint: "",
              //     frequency: "",
              //     severity: "",
              //     duration: "",
              //     date: "",
              //     inputNumber: "",
              //   },
              // ],
              templateName: "",
            }));
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("Failed to save template");
          });

        this.handleDialogClose();
      } else {
        alert("Please Enter Value...");
        this.handleDialogClose();
        this.setState({ templateName: "" });
      }
    };

    toggleTemplateList = () => {
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible, // Toggle the visibility state
      }));
      this.setState({ isTemplateClicked: !this.state.isTemplateClicked });
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });
    };
    // handleTemplateSelect = (template) => {
    //   console.log(template);
    //   this.setState((prevState) => ({
    //     repList: [...this.props.repList, ...template.json_data],
    //   }));
    //   this.setState((prevState) => ({
    //     isListVisible: !prevState.isListVisible, // Toggle the visibility state
    //   }));
    //   this.setState({ isTemplateClicked: false });
    //   this.setState({ openSuggestionIndex: false });
    //   this.props.handleChange(template.json_data);
    // };
    // handleTemplateSelect = (newValue) => {
    //   // Handle the selected template
    //   console.log("Selected template:", newValue);
    // };

    // handleTemplateSelect = (idx, name, template) => {
    //   console.log(template.json_data[0].repName);
    //   this.props.handleChange(idx, name, template.json_data[0].repName);
    //   this.setState((prevState) => ({
    //     isListVisible: !prevState.isListVisible, // Toggle the visibility state
    //   }));
    //   this.setState({ isTemplateClicked: false });
    //   this.setState({ openSuggestionIndex: false });
    //   //   }
    // };

    handleSearchChange = (event) => {
      this.setState({ search: event.target.value }); // Update search term
    };

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeyNavigation);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      fetch(hostInfo + "/getreporttemplates", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ templates: data });
          console.log("Fetched Templates:", data);
        })
        .catch((error) => {
          console.error("Error fetching templates:", error);
        });
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("keydown", this.handleKeyNavigation);
    }

    handleClickOutside = (event) => {
      if (
        this.containerRef.current &&
        !this.containerRef.current.contains(event.target)
      ) {
        this.setState({ isListVisible: false, isTemplateClicked: false });
      }
    };

    // Function to handle keyboard navigation
    handleKeyNavigation = (event) => {
      const { templates, highlightedIndex, isListVisible } = this.state;

      // Only trigger if the suggestion list is visible
      if (!isListVisible) return;

      // Prevent default scrolling behavior when navigating suggestions
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }

      // Navigate down (ArrowDown)
      if (event.key === "ArrowDown") {
        this.setState((prevState) => ({
          highlightedIndex: Math.min(
            prevState.highlightedIndex + 1,
            templates.length - 1
          ),
        }));
      }

      // Navigate up (ArrowUp)
      if (event.key === "ArrowUp") {
        this.setState((prevState) => ({
          highlightedIndex: Math.max(prevState.highlightedIndex - 1, 0),
        }));
      }

      // Select the highlighted suggestion (Enter key)
      if (event.key === "Enter" && highlightedIndex >= 0) {
        this.handleTemplateSelect(
          // this.props.idx,
          // "repName",
          templates[highlightedIndex]
        );
      }
    };

    handleTemplateSelect = (template) => {
      console.log(template);
      console.log(template.json_data);

      // Get the current repList from props
      const currentRepList = this.props.repList || [];

      // Create a new array with existing values and new values, ensuring uniqueness
      const newRepName = Array.from(
        new Set([...currentRepList, ...template.json_data])
      );

      // Pass the combined unique values to the handleChange function
      this.props.handleRepChange(newRepName);

      // Update the state to close the suggestion list after selection
      this.setState({
        isListVisible: false,
        isTemplateClicked: false,
        openSuggestionIndex: false,
        highlightedIndex: -1,
      });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleDiaClickOpen = () => {
      this.setState({ openDia: true });
    };

    handleDiaClickClose = () => {
      this.setState({ openDia: false });
    };

    render() {
      const {
        dialogOpen,
        templateName,
        templates,
        search,
        isTemplateClicked,

        isSaveTemplateClicked,
        isListVisible,
      } = this.state;

      const { classes, patient_id, repList, repSug } = this.props;

      // return this.props.repList.map((val) => {
      return (
        <Fragment>
          {/* <Box> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              // marginTop: "10px",
            }}
          >
            {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Complaints
                  </Typography> */}
            <Typography
              gutterBottom
              color="textSecondary"
              // variant="h6"
              // color="black"
              // style={{ fontWeight: "bold" }}
            >
              Test Reports (Ix)
            </Typography>
          </div>
          <Divider />
          <Autocomplete
            style={{ marginRight: "10px" }}
            multiple
            id="multi-field"
            size="small"
            classes={{ option: classes.autocomp }}
            autoSelect
            freeSolo
            disableClearable={false} // Allow clearing
            options={this.props.repSug} // Suggestions passed from props
            value={this.props.repList || []} // Display the current repList values or an empty array
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              // When an item is added or removed, update the repList in the parent
              this.props.handleRepChange(newValue); // Call the parent's handleRepChange
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Ix"
                multiline
                rows={3}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />

          {/* </Box> */}
        </Fragment>
      );
      // });
    }
  }
);
