import React, { Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Popper,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@material-ui/core/TextField";
import { hostInfo } from "../../../store";

import EditVisitMedicine from "./editcomponents/EditMedicine";
import EditVisitReport from "./editcomponents/EditReport";

import FormGroup from "@material-ui/core/FormGroup";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { visitFormfields } from "../../../store";
import EditDiagnosis from "./editcomponents/EditDiagnosis";
import EditReports from "./editcomponents/EditReports";
import EditAdvice from "./editcomponents/EditAdvice";
import EditPayment from "./editcomponents/EditPayment";
import Payment from "./Payment";

const styles = (theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(2),
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  paymentCls: {
    color: "green",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  btnClass: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
  },
  presc: {
    width: "100%",
  },
  freeTextNotes: {
    width: "96%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
});

// const complaintsSuggestions = [
//   { label: "Fever" },
//   { label: "Fatigue" },
//   { label: "Fracture" },
//   { label: "Cough" },
//   { label: "Cold" },
//   { label: "Vomiting" },
//   { label: "Loose Motion" },
//   { label: "Abdominal Pain" },
//   { label: "Nasal Blockage" },
// ];
// var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class EditVisit extends React.Component {
    state = {
      user: [],
      drRefState: {
        refMark: false,
        refDisabled: true,
        refGrpDisabled: true,
      },
      BilldialogOpen: false,
      drGroups: [],
      drList: [],
      search: "",
      dialogOpen: false,
      templateName: "",
      isSuggestionOpen: 1,
      isfrequencySuggestionOpen: 1,
      isseveritySuggestionOpen: 1,
      openSuggestionIndex: false,
      templates: [],
      symSug: [],
      filteredSymSug: [],
      isListVisible: false,
      isTemplateClicked: false,
      isSaveTemplateClicked: false,
      isLoadPreviousClicked: false,
      durationSuggestions: [
        { label: "__day" },
        { label: "__week" },

        { label: "__month" },
        { label: "__year" },
        // { label: "monthly" },
        // { label: "Loose Motion" },
        // { label: "Abdominal Pain" },
        // { label: "Nasal Blockage" },
      ],

      frequencySuggestions: [
        { label: "daily" },
        { label: "alternate day" },

        { label: "weekly" },
        { label: "twice weekly" },
        { label: "monthly" },
        // { label: "Loose Motion" },
        // { label: "Abdominal Pain" },
        // { label: "Nasal Blockage" },
      ],

      severitySuggestions: [
        { label: "mild" },
        { label: "moderate" },

        { label: "severe" },
        { label: "profound" },
        // { label: "monthly" },
        // { label: "Loose Motion" },
        // { label: "Abdominal Pain" },
        // { label: "Nasal Blockage" },
      ],
      // rows: [
      //   {
      //     complaint: "",
      //     frequency: "",
      //     severity: "",
      //     duration: "",
      //     date: new Date().toISOString().split("T")[0],
      //     inputNumber: "",
      //   },
      //   // Add more rows if necessary
      // ],
      rows: [],
      payment: [
        // Add more rows if necessary
      ],
      // diagnosis: [
      //   {
      //     diagnosis: "",
      //     duration: "",
      //     date: new Date().toISOString().split("T")[0], // Default to current date
      //     inputNumber: "",
      //   },
      // ],
      diagnosis: [],
      formdetail: {
        id: 0,
        weight: 0,
        height: 0,
        bp: "",
        pulse: 0,
        spo: 0,
        rr: "",
        temperature: 0,
        age: 0,
        ptNotes: [],
        drNotes: [],
        notes: "",
        diaNotes: "",
        amount: 0,
        amountPaid: 0,
        drRef: "",
        drRefInfo: "",
        medList: [],
        repList: [],
        drGrp: "",

        weightError: false,
        ageError: false,
        heightError: false,
        bpError: false,
        spoError: false,
        temperatureError: false,
        pulseError: false,
        weightErrorTxt: "",
        ageErrorTxt: "",
        heightErrorTxt: "",
        bpErrorTxt: "",
        spoErrorTxt: "",
        temperatureErrorTxt: "",
        pulseErrorTxt: "",
        amountClass: "",
      },
    };
    paymentSug = [];
    medSug = [];
    repSug = [];
    drSug = [];
    // symSug = [];
    disSug = [];

    validate = () => {
      let isError = false;
      const { formdetail } = this.state;

      // console.log(this.state);

      // Clean old error status
      formdetail.userIdError = false;
      formdetail.drIdError = false;

      formdetail.userIdErrorTxt = "";
      formdetail.drIdErrorTxt = "";

      // console.log()

      // User id Validation----
      // 1. Length
      // 2. valid id number
      // if (
      //   !this.isUserIdPresentInArray(this.state.userList, formdetail.userId)
      // ) {
      //   isError = true;
      //   formdetail.userIdError = true;
      //   formdetail.userIdErrorTxt =
      //     "Invalid user. Please select proper username";
      // }

      // console.log(
      //   "userid: " +
      //     formdetail.userId +
      //     " and length: " +
      //     formdetail.userId.length
      // );
      // Doctor id Validation----
      // 1. Length
      // 2. special character check
      // if (!this.isDrIdPresentInArray(this.state.drList, formdetail.drId)) {
      //   isError = true;
      //   formdetail.drIdError = true;
      //   formdetail.drIdErrorTxt = "Invalid doctor. Please select proper doctor";
      // }
      // console.log(
      //   "drid: " + formdetail.drId + " and length: " + formdetail.drId.length
      // );

      // this.setState({
      //   ...this.state.formdetail,
      // });

      return isError;
    };

    handleSave = () => {
      // const err = this.validate();

      let { formdetail } = this.state;

      // console.log("Final from object...111");

      let medList = formdetail.medList.filter(
        (x, idx) => x.medName !== "" || idx === 0
      );
      let repList = formdetail.repList.filter(
        (x, idx) => x.repName !== "" || idx === 0
      );
      // console.log(repList);
      // console.log(formdetail.weight);
      let formObj = {
        id: this.props.visit.id,
        weight: formdetail.weight,
        bp: formdetail.bp,
        spo: formdetail.spo,
        rr: formdetail.rr,
        temperature: formdetail.temperature,
        pulse: formdetail.pulse,
        age: formdetail.age,
        height: formdetail.height,
        meds: JSON.stringify(medList),
        reps: JSON.stringify(repList),
        complaints: this.state.rows.slice(0, -1),
        diagnosis: this.state.diagnosis.slice(0, -1),
        payment: [],
        amount: formdetail.amount,
        // amount: formdetail.amount,
        paid_amount: formdetail.amountPaid,
        pt_notes: JSON.stringify(formdetail.ptNotes),
        dr_notes: JSON.stringify(formdetail.drNotes),
        notes: formdetail.notes,
        dia_notes: formdetail.diaNotes,
        ref_dr: formdetail.drRef,
        ref_grp: formdetail.drGrp,
        ref_info: formdetail.drRefInfo,
        is_ref: this.state.drRefState.refMark ? 1 : 0,
        accesses_flag: this.state.user[0].accesses_flag,
      };
      // console.log("Final from object...");
      // console.log(formObj);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };
      // console.log(formObj);
      let url = hostInfo + "/updatecasevisit";
      // console.log(url);
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(formObj),
      })
        .then((response) => {
          // Check if the response status is OK
          if (response.ok) {
            // If the status is OK, proceed with calling the handleSubmit function
            this.props.handleSubmit();
          } else {
            // Handle error (optional, e.g., show a message)
            console.error("Request failed with status:", response.status);
          }
        })
        .catch((error) => {
          // Handle any errors that occur during the fetch request
          console.error("Error during fetch:", error);
        });

      //
      // Update in Visit value
      //
      // Call the submit function of parent
      //
    };

    handleBillSave = () => {
      let formObj = {
        id: this.props.visit.id,
        payment: this.state.payment,
      };

      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      let url = hostInfo + "/updatepaymentbill";

      // Perform the fetch request
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(formObj),
      })
        .then((response) => {
          if (response.ok) {
            // If the response is successful, close the dialog and reload the page
            this.setState({ BilldialogOpen: false });
            window.location.reload();
          } else {
            // Handle the error case
            alert("Error: Unable to update payment.");
          }
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
          alert("An error occurred while updating the payment.");
        });
    };

    async componentDidMount() {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // console.log("Component DID MOUNT!");
      let url = hostInfo + "/getmeds";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in meds ... ");
          // console.log(data);
          if (data != null) this.medSug = data;
        });

      // repSug -------------------
      url = hostInfo + "/getreps";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in reps ... ");
          // console.log(data);
          if (data != null) this.repSug = data;
        });

      // drSug ------------------
      url = hostInfo + "/getdrs";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in drs ... ");
          // console.log(data);
          if (data != null) this.drSug = data;
        });

      // disSug ------------------
      url = hostInfo + "/getdis";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in dis ... ");
          // console.log(data);
          if (data != null) this.disSug = data;
        });

      // symSug ------------------
      url = hostInfo + "/getsymt";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data != null) {
            // Correctly update the state
            this.setState({ symSug: data, filteredSymSug: data });
          }
        });

      url = hostInfo + "/usernames";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const a = getToken();
          const b = JSON.parse(atob(a.split(".")[1]));
          const user = data.users.filter((i) => i.username === b.username);

          this.setState({ user });
        });

      url = hostInfo + "/getpayment";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in meds ... ");
          // console.log(data);
          if (data != null) this.paymentSug = data;
        });

      url = hostInfo + "/getdrgroups";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("dr Groups got ... ");
          // console.log(data);
          if (data != null) this.setState({ drGroups: data });
        });

      let visit = this.props.visit;

      let drRefState = { refMark: visit.is_ref === 0 ? false : true };

      this.setState({ ...this.state, drRefState });
      let rows = [...visit.complaints];
      rows.push({
        "": ["", "", "", new Date().toISOString().split("T")[0]],
      });
      this.setState({ ...this.state, rows });
      let diagnosis = [...visit.diagnosis]; // Create a copy of the current diagnosis array
      diagnosis.push({ "": ["", ""] });
      this.setState({ ...this.state, diagnosis });

      // let payment = visit.payment;
      // this.setState({ ...this.state, payment });
      let payment = [...visit.payment];
      payment.push({
        name: "",
        price: "",
        discount: "",
        total: "",
      });
      this.setState({ ...this.state, payment });
      // console.log(drRefState);
      let formdetail = {
        id: visit.id,
        weight: visit.weight,
        bp: visit.bp,
        spo: visit.spo,
        rr: visit.rr,
        temperature: visit.temperature,
        pulse: visit.pulse,
        age: visit.age,
        height: visit.height,
        ptNotes: JSON.parse(visit.patient_notes),
        drNotes: JSON.parse(visit.dr_notes),
        notes: visit.notes,
        diaNotes: visit.dia_notes,
        amount: visit.amount,
        amountPaid: visit.paid_amount,
        drRef: visit.ref_dr,
        drGrp: visit.ref_grp,
        drRefInfo: visit.ref_info,
        medList: JSON.parse(visit.medicine_info),
        repList: JSON.parse(visit.report_info),
        // drGrp: this.drGroups.length > 0 ? this.drGroups[0] : "",

        weightError: false,
        ageError: false,
        heightError: false,
        bpError: false,
        spoError: false,
        temperatureError: false,
        pulseError: false,
        weightErrorTxt: "",
        ageErrorTxt: "",
        heightErrorTxt: "",
        bpErrorTxt: "",
        spoErrorTxt: "",
        temperatureErrorTxt: "",
        pulseErrorTxt: "",
        amountClass: "",
      };
      // console.log(formdetail);
      this.setState({ ...this.state, formdetail });
    }

    handleChangeCheckbox = (event) => {
      if (event.target.checked === true) {
        let drRefState = { ...this.state.drRefState };
        drRefState.refGrpDisabled = false;
        drRefState.refMark = true;
        this.setState(() => ({ drRefState }));
      } else {
        let drRefState = { ...this.state.drRefState };
        drRefState.refGrpDisabled = true;
        drRefState.refMark = false;
        this.setState({ drRefState });
        let formdetail = { ...this.state.formdetail };

        formdetail.drGrp = null;
        formdetail.drRef = null;
        formdetail.drRefInfo = null;
        this.setState({ formdetail });
      }
    };

    handleDrGrpChange = (e, value) => {
      // console.log("Inside handle dr Group change change---");
      // console.log(value);

      let drRefState = { ...this.state.drRefState };
      this.setState({
        ...this.state,
        formdetail: { ...this.state.formdetail, drRef: "", drGrp: value.name },
      });
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      if (value.id > 0) {
        drRefState.refDisabled = false;

        // Fetch drlist for given dr group ------------------
        let url = hostInfo + "/getdrdetails?group_id=" + value.id;
        fetch(url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data != null) {
              this.setState({ drList: data });
            } else {
              this.setState({ drList: [] });
            }
          });
      } else {
        drRefState.refDisabled = false;
        // drRefState.refGrpDisabled = false;
      }
      this.setState(() => ({ drRefState }));
    };

    handleDrChange = (e, name, value) => {
      // console.log("Inside handle dr change change---");
      // console.log(id);
      // console.log(name);
      // console.log(value.name);

      let formdetail = { ...this.state.formdetail };
      formdetail.drRef = value.name;
      formdetail.drRefInfo = value.description + " (" + value.mobile + ")";
      this.setState({ formdetail });
    };

    handleOnChange = (e, name) => {
      const value = e.target.value;
      // console.log("Value change --- ");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };
    handleBillDialogOpen = () => {
      this.setState({ BilldialogOpen: true });
    };

    handleBillDialogClose = () => {
      this.setState({ BilldialogOpen: false });
    };
    handleMultiFld = (name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleBGChange = (name, value) => {
      // console.log(name);
      // console.log(value);

      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMedChange = (id, name, value) => {
      // console.log("Inside handleMed change---");
      // console.log(id);
      // console.log(name);
      // console.log(value);

      let medList = [...this.state.formdetail.medList];
      medList[id][name] = value;

      this.setState({ medList });
      // console.log(medList);
    };
    // handleRepChange = (id, name, value) => {
    //   // console.log("Inside Rep change---");
    //   // console.log(id);
    //   // console.log(name);
    //   // console.log(value);

    //   let repList = [...this.state.formdetail.repList];
    //   repList[id][name] = value;

    //   this.setState({ repList });
    // };

    addMedRow = (e) => {
      this.setState((prevState) => ({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          medList: [
            ...prevState.formdetail.medList,
            {
              index: Math.random(),
              medName: "",
              // medType: "",
              medQnt: 1,
              medSchd: "",
              medTime: "After Eat",
              medCount: 0,
            },
          ],
        },
      }));
    };

    addRepRow = (e) => {
      this.setState((prevState) => ({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          repList: [
            ...prevState.formdetail.repList,
            {
              index: Math.random(),
              repName: "",
            },
          ],
        },
      }));
    };

    deleteMedRow = (index) => {
      // console.log("Inside Delete med row.......");
      // console.log(this.state.formdetail.medList);
      // console.log(index);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          medList: this.state.formdetail.medList.filter(
            (s, idx) => index !== s.index
          ),
        },
      });
    };

    deleteRepRow = (index) => {
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          repList: this.state.formdetail.repList.filter(
            (s, idx) => index !== s.index
          ),
        },
      });
    };

    handleChange = (e) => {
      // console.log("Inside handle change..11.....");
      // console.log(e.target);
      // console.log(e.target.name);
      // console.log(e.target.type);
      // console.log(e.target.checked);
      // console.log(e.target.value);
      // console.log(e.target);
      // console.log(
      //   "change value-- id:  ",
      //   e.target.id,
      //   " name: ",
      //   e.target.name,
      //   " value: ",
      //   e.target.value
      // );
      if (e.target.type === "checkbox") {
        // console.log("type is checkbos... so going out...");
        return;
      } else if (
        ["medName", "medSchd", "medTime", "repName"].includes(e.target.name)
      ) {
        return;
      } else if (["medCount", "medOut", "medQnt"].includes(e.target.name)) {
        let medList = [...this.state.formdetail.medList];
        // console.log("------- Med List --------");
        // console.log(medList);
        medList[e.target.id][e.target.name] =
          e.target.type === "number"
            ? parseInt(e.target.value)
            : e.target.value;
        this.setState({ medList });
        // medList[e.target.id][e.target.name] = e.target.value;
        // } else if (["repName"].includes(e.target.name)) {
        //   let repList = [...this.state.formdetail.repList];
        //   repList[e.target.id][e.target.name] = e.target.value;
        //   this.setState({ repList });
      } else {
        this.setState({
          ...this.state,
          formdetail: {
            ...this.state.formdetail,
            [e.target.name]:
              e.target.type === "number"
                ? parseFloat(e.target.value)
                : e.target.value,
          },
        });
      }
    };

    handleComplaintChange = (index, value) => {
      const newRows = [...this.state.rows];
      const complaintKey = value || ""; // Use the provided value or an empty string

      // Get the existing row for the index or create a new object if it doesn't exist
      const existingRow = newRows[index] || {};

      // Retrieve current values for the complaint key or default to an empty array
      const complaintValues = existingRow[complaintKey] ||
        existingRow[Object.keys(existingRow)[0]] || ["", "", "", ""];

      // Create a new object with only the new complaint key and its values
      const updatedRow = {
        [complaintKey]: complaintValues,
      };

      // Update the row at the specified index
      newRows[index] = updatedRow;

      // Add a new empty row only if modifying the last row
      if (index === newRows.length - 1 && complaintKey.trim() !== "") {
        newRows.push({ "": ["", "", "", ""] }); // Add a new empty row
      }

      // Update the state with the modified rows
      this.setState({ rows: newRows });

      // Handle suggestion box visibility based on values
      const hasEmptyValues = !newRows[index][complaintKey]
        .slice(0, 3)
        .some((v) => v !== ""); // Check for non-empty values

      if (hasEmptyValues) {
        this.setState({
          isfrequencySuggestionOpen: index,
          isSuggestionOpen: index,
          isseveritySuggestionOpen: index,
        });
      } else {
        this.setState({
          isfrequencySuggestionOpen: -1,
          isSuggestionOpen: -1,
          isseveritySuggestionOpen: -1,
        });
      }
    };

    // Function to handle frequency change
    handleFrequencyChange = (index, newValue) => {
      const updatedRows = [...this.state.rows];
      const key = Object.keys(updatedRows[index])[0]; // Get the complaint key
      updatedRows[index][key][0] = newValue ? newValue : ""; // Set severity

      this.setState({ rows: updatedRows });
    };

    // Function to handle severity change
    handleSeverityChange = (index, newValue) => {
      const updatedRows = [...this.state.rows];
      const key = Object.keys(updatedRows[index])[0]; // Get the complaint key
      updatedRows[index][key][1] = newValue ? newValue : ""; // Set severity

      this.setState({ rows: updatedRows });
    };

    // Function to handle duration change and calculate the date
    handleDurationChange = (index, inputNumber) => {
      console.log(this.state.rows);
      const rows = [...this.state.rows];
      const key = Object.keys(rows[index])[0]; // Get the complaint key

      rows[index][key][2] = inputNumber ? `${inputNumber}` : ""; // Set duration

      if (inputNumber) {
        const parts = inputNumber.split(" ");
        const durationValue = parseInt(parts[0], 10);
        const durationUnit = parts[1] ? parts[1].toLowerCase() : "";

        if (!isNaN(durationValue)) {
          const baseDate = new Date();

          if (durationUnit === "day" || durationUnit === "days") {
            baseDate.setDate(baseDate.getDate() - durationValue);
          } else if (durationUnit === "week" || durationUnit === "weeks") {
            baseDate.setDate(baseDate.getDate() - durationValue * 7);
          } else if (durationUnit === "month" || durationUnit === "months") {
            baseDate.setMonth(baseDate.getMonth() - durationValue);
          } else if (durationUnit === "year" || durationUnit === "years") {
            baseDate.setFullYear(baseDate.getFullYear() - durationValue);
          }

          rows[index][key][3] = baseDate.toISOString().split("T")[0]; // Set calculated date
        } else {
          rows[index][key][3] = ""; // Clear date if invalid
        }
      } else {
        rows[index][key][3] = ""; // Clear date if no duration provided
      }

      this.setState({ rows });
    };

    // Function to handle date change
    handleDateChange = (index, dateValue) => {
      const newRows = [...this.state.rows];
      const key = Object.keys(newRows[index])[0]; // Get the complaint key

      newRows[index][key][3] = dateValue; // Update date field

      this.setState({ rows: newRows });
    };

    // Function to convert data to requested format
    convertToNewFormat = () => {
      return this.state.rows;
    };

    handleDeleteComplaint = (index) => {
      this.setState((prevState) => {
        const newRows = prevState.rows.filter((_, i) => i !== index);

        // If no rows are left, insert a default empty row
        const updatedRows =
          newRows.length === 0
            ? [
                {
                  "": ["", "", "", new Date().toISOString().split("T")[0]],
                },
              ]
            : newRows;

        return {
          rows: updatedRows, // Update the rows state with the filtered array
        };
      });
    };

    handlePaymentChange = (updatedRows) => {
      this.setState({ payment: updatedRows });
    };

    handleAdviceChange = (advice) => {
      // Update the state by merging the new `drNotes` with the current state
      this.setState((prevState) => ({
        formdetail: {
          ...prevState.formdetail,
          notes: advice,
        },
      }));
    };
    handleFocus = () => {
      // Optional: Reset filteredDisSug when the field is focused
      this.setState({ filteredDisSug: this.state.symSug });
    };
    handleRepChange = (newValue) => {
      console.log(newValue);
      // Update the repList in the form detail with new values
      this.setState((prevState) => ({
        formdetail: {
          ...prevState.formdetail,
          repList: newValue, // Set repList directly to newValue from Autocomplete
        },
      }));
    };

    handleRowsChange = (updatedRows) => {
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          ["drNotes"]: updatedRows,
        },
      });
      // this.setState({ diagnosis: updatedRows }); // Update the rows state in the parent
    };

    handleMedRowsChange = (updatedRows) => {
      // Ensure medList exists and is an array
      this.setState(
        (prevState) => {
          // Combine existing medList with updatedRows
          const combinedList = [
            ...prevState.formdetail.medList,
            ...updatedRows,
          ];

          // Filter out entries where medName is an empty string
          const filteredList = combinedList.filter(
            (row) => row.medName !== "" && row.medSchd !== ""
          );

          // Update the state with the filtered medList
          return {
            formdetail: {
              ...prevState.formdetail,
              medList: filteredList,
            },
          };
        },
        () => {
          // Log the updated medList to the console
          console.log(this.state.formdetail.medList);
        }
      );
    };

    render() {
      const {
        formdetail: {
          weight,
          bp,
          rr,
          spo,
          temperature,
          pulse,
          age,
          height,
          ptNotes,
          drNotes,
          medList,
          repList,
          amount,
          amountPaid,
          notes,
          diaNotes,
          drRef,
          drGrp,
        },

        rows,
        diagnosis,
        payment,
        drRefState: { refGrpDisabled, refMark, refDisabled },
        drGroups,
        drList,
      } = this.state;
      // console.log(this.state.payment);
      const { classes, handleClose } = this.props;
      console.log(this.state.filteredSymSug);

      return (
        // <div>
        <Fragment>
          <form
            className={classes.root}
            autoComplete="off"
            onChange={this.handleChange}
          >
            <Typography
              gutterBottom
              // variant="h6"
              // color="black"
              color="textSecondary"
              // style={{ fontWeight: "bold" }}
            >
              Vitals
            </Typography>
            <Divider />
            <FormGroup row={true}>
              <div>
                <TextField
                  autoFocus
                  margin="dense"
                  id="temperature"
                  value={temperature}
                  name="temperature"
                  pattern="[0-9]*"
                  style={{ width: "110px" }}
                  label="Temperature"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°F</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="ml-3">
                <TextField
                  // autoFocus
                  margin="dense"
                  id="pulse"
                  style={{ width: "110px" }}
                  name="pulse"
                  value={pulse}
                  pattern="[0-9]*"
                  label="Pulse"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">/ min</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="ml-3">
                <TextField
                  margin="dense"
                  placeholder="120/80"
                  id="bp"
                  name="bp"
                  value={bp}
                  label="BP"
                  type="text"
                  style={{ width: "150px" }}
                  // value={this.state.bp}
                  // onChange={this.handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mm of Hg</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="ml-3">
                <TextField
                  // autoFocus
                  margin="dense"
                  id="spo"
                  name="spo"
                  value={spo}
                  style={{ width: "110px" }}
                  pattern="[0-9]*"
                  label="SpO2"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="ml-3">
                <TextField
                  // autoFocus
                  margin="dense"
                  id="rr"
                  value={rr}
                  name="rr"
                  style={{ width: "110px" }}
                  pattern="[0-9]*"
                  label="RR"
                  type="text"
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">%</InputAdornment>
                  //   ),
                  // }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="ml-3">
                <TextField
                  //////////////////  height
                  // autoFocus
                  margin="dense"
                  id="height"
                  name="height"
                  value={height}
                  pattern="[0-9]*"
                  style={{ width: "110px" }}
                  label="Height"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="ml-3">
                <TextField
                  //////////////////  weight
                  // autoFocus
                  margin="dense"
                  id="weight"
                  value={weight}
                  name="weight"
                  style={{ width: "110px" }}
                  pattern="[0-9]*"
                  label="Weight"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              {/* <div className="ml-3">
                  <TextField
                    //////////////////  age
                    // autoFocus
                    margin="dense"
                    id="age"
                    name="age"
                    pattern="[0-9]*"
                    label="Age"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div> */}
              {/* </FormGroup> */}

              {/* <FormGroup row={true}> */}
            </FormGroup>
            <Grid container direction="row">
              <Grid item xs>
                {/* <FormGroup> */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    // marginTop: "10px",
                  }}
                >
                  {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Complaints
                  </Typography> */}
                  <Typography
                    gutterBottom
                    color="textSecondary"
                    // variant="h6"
                    // color="black"
                    style={{ marginTop: "15px" }}
                  >
                    Complaints (c/o)
                  </Typography>
                </div>
                <Divider variant="left" />
                <Table
                  style={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    marginTop: "18px",
                    width: "auto", // Table Border
                  }}
                  size="small" // Reduce padding in the table
                >
                  <TableHead>
                    <TableRow style={{ backgroundColor: "rgb(238, 249, 222)" }}>
                      <TableCell
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                          width: "35px",
                          fontSize: "12px",
                          color: "#546e7a",
                          fontWeight: "bold",
                        }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                          width: "300px",
                          fontSize: "12px",
                          color: "#546e7a",
                          fontWeight: "bold",
                        }}
                      >
                        c/o
                      </TableCell>
                      {/* <TableCell
                    style={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "2px",
                      width: "200px",
                      fontWeight: "bold",
                    }}
                  >
                    Frequency
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "2px",
                      width: "200px",
                      fontWeight: "bold",
                    }}
                  >
                    Severity
                  </TableCell> */}
                      <TableCell
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                          width: "200px",
                          fontSize: "12px",
                          color: "#546e7a",
                          fontWeight: "bold",
                        }}
                      >
                        Duration
                      </TableCell>
                      {/* <TableCell
                    style={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "2px",
                      width: "200px",
                      fontWeight: "bold",
                    }}
                  >
                    Date
                  </TableCell> */}
                      <TableCell
                        style={{
                          border: "1px solid white",
                          padding: "2px",
                          backgroundColor: "white",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row, index) => {
                      const complaint = Object.keys(row)[0]; // This gives the complaint (e.g., "fever")
                      const values = row[complaint]; // This gives the array of values (e.g., ["daily", "mild", "1 day", "15-10-2024"])

                      return (
                        <TableRow key={index}>
                          {/* Row Number */}
                          <TableCell
                            style={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                            }}
                          >
                            {index + 1}
                          </TableCell>

                          {/* Complaint Autocomplete */}
                          <TableCell
                            style={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                            }}
                          >
                            <Autocomplete
                              onFocus={this.handleFocus}
                              options={this.state.filteredSymSug} // Dynamic filtered suggestions
                              getOptionLabel={(option) =>
                                typeof option === "string"
                                  ? option
                                  : option.label
                              } // Handle both string and object inputs
                              disableClearable
                              freeSolo // Allows custom input
                              value={complaint || ""} // Display the current complaint
                              onChange={(event, newValue) => {
                                const updatedComplaint =
                                  typeof newValue === "string"
                                    ? newValue // Custom input
                                    : newValue?.label || ""; // Selected suggestion
                                this.handleComplaintChange(
                                  index,
                                  updatedComplaint
                                );
                              }}
                              onInputChange={(event, newInputValue) => {
                                const inputValue = newInputValue.toLowerCase();
                                const filteredOptions =
                                  this.state.symSug.filter((option) => {
                                    const label = option.label || option; // Handle both objects and strings
                                    return label
                                      .toLowerCase()
                                      .includes(inputValue);
                                  });

                                // Update filteredSymSug in state
                                // this.setState({
                                //   filteredSymSug: filteredOptions.length
                                //     ? filteredOptions
                                //     : this.state.symSug,
                                // });

                                // Update complaint value
                                this.handleComplaintChange(
                                  index,
                                  newInputValue
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true, // Clean look without underline
                                  }}
                                />
                              )}
                            />
                          </TableCell>

                          {/* Frequency Autocomplete */}
                          {/* <TableCell
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                        }}
                      >
                        <Autocomplete
                          open={this.state.isfrequencySuggestionOpen === index} // Open only for the current index
                          options={this.state.frequencySuggestions} // Use state for options
                          disableClearable
                          freeSolo
                          getOptionLabel={(option) => option.label} // Display the label of each option
                          value={values[0] ? { label: values[0] } : null} // Set the current value
                          filterOptions={(options) => options} // Show all suggestions without filtering by input
                          onChange={(event, newValue) => {
                            // console.log(
                            //   index,
                            //   this.state.isfrequencySuggestionOpen
                            // );
                            const formattedValue = newValue
                              ? newValue.label // Format selected value, removing '__'
                              : ""; // Reset if no value
                            this.handleFrequencyChange(index, formattedValue); // Update the frequency

                            // Close suggestions after selection
                            this.setState({
                              isfrequencySuggestionOpen: -1,
                            }); // Close all suggestions
                          }}
                          onInputChange={(event, newInputValue) => {
                            if (newInputValue.trim() !== "") {
                              const newSuggestions = [
                                { label: "daily" },
                                { label: "alternate day" },
                                { label: "weekly" },
                                { label: "twice weekly" },
                                { label: "monthly" },
                              ];

                              this.setState({
                                // isLoadPreviousClicked: false,
                                // openSuggestionIndex: true,
                                frequencySuggestions: newSuggestions,
                                // isfrequencySuggestionOpen:
                                //   this.state.isLoadPreviousClicked ||
                                //   this.state.openSuggestionIndex === false
                                //     ? -1
                                //     : index, // Open suggestions for the current index
                              });
                              this.handleFrequencyChange(index, newInputValue); // Update frequency input as user types
                            } else {
                              // Reset to default suggestions if input is empty or invalid
                              this.setState({
                                frequencySuggestions: [
                                  { label: "daily" },
                                  { label: "alternate day" },
                                  { label: "weekly" },
                                  { label: "twice weekly" },
                                  { label: "monthly" },
                                ],
                                isfrequencySuggestionOpen: -1, // Close suggestions
                              });
                              this.handleFrequencyChange(index, ""); // Clear previous value
                            }
                          }}
                          onFocus={() => {
                            const newSuggestions = [
                              { label: "daily" },
                              { label: "alternate day" },
                              { label: "weekly" },
                              { label: "twice weekly" },
                              { label: "monthly" },
                            ];

                            // Open suggestions on focus if there is no current value
                            // if (!values[1]) {
                            this.setState({
                              frequencySuggestions: newSuggestions,
                              isfrequencySuggestionOpen: index, // Open suggestions for the current index
                            });
                            // }
                          }}
                          onBlur={() => {
                            this.setState({
                              isfrequencySuggestionOpen: -1,
                            }); // Close suggestions on blur
                          }}
                          // PopperComponent={(props) => (
                          //   <Popper {...props} placement="bottom" />
                          // )}
                          // disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true, // Disable underline for a cleaner look
                              }}
                              inputRef={this.inputRef}
                            />
                          )}
                        />
                      </TableCell>

                      <TableCell
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                        }}
                      >
                        <Autocomplete
                          open={this.state.isseveritySuggestionOpen === index} // Open only for the current index if conditions are met
                          options={this.state.severitySuggestions} // Use state for options
                          disableClearable
                          freeSolo
                          getOptionLabel={(option) => option.label} // Display the label of each option
                          value={values[1] ? { label: values[1] } : null} // Set the current value
                          filterOptions={(options) => options} // Show all suggestions without filtering by input
                          onChange={(event, newValue) => {
                            const formattedValue = newValue
                              ? newValue.label
                              : ""; // Format selected value or reset
                            this.handleSeverityChange(index, formattedValue); // Update the severity
                            this.setState({ isseveritySuggestionOpen: -1 }); // Close suggestions after selection
                          }}
                          onInputChange={(event, newInputValue) => {
                            if (newInputValue.trim() !== "") {
                              // Handle regular input or deletion
                              const newSuggestions = [
                                { label: "mild" },
                                { label: "moderate" },
                                { label: "severe" },
                                { label: "profound" },
                              ];

                              // Update severity value and suggestions
                              this.setState({
                                severitySuggestions: newSuggestions,
                                // Open suggestions for the current index
                              });
                              // this.setState({ isTemplateClicked: false });
                              this.handleSeverityChange(index, newInputValue); // Store custom input value
                            } else {
                              // Reset suggestions and open on backspace deletion
                              const newSuggestions = [
                                { label: "mild" },
                                { label: "moderate" },
                                { label: "severe" },
                                { label: "profound" },
                              ];

                              this.setState({
                                severitySuggestions: newSuggestions,
                                isseveritySuggestionOpen: -1, // Keep suggestions open
                              });
                              this.handleSeverityChange(index, newInputValue); // Clear previous value
                            }
                          }}
                          onFocus={() => {
                            const newSuggestions = [
                              { label: "mild" },
                              { label: "moderate" },
                              { label: "severe" },
                              { label: "profound" },
                            ];

                            // Open suggestions on focus if there is no current value
                            // if (!values[1]) {
                            this.setState({
                              severitySuggestions: newSuggestions,
                              isseveritySuggestionOpen: index, // Open suggestions for the current index
                            });
                            // }
                          }}
                          onBlur={() => {
                            this.setState({ isseveritySuggestionOpen: -1 }); // Close suggestions on blur
                          }}
                          // PopperComponent={(props) => (
                          //   <Popper {...props} placement="bottom" />
                          // )}
                          // disablePortal
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true, // Disable underline for a cleaner look
                              }}
                              inputRef={this.inputRef}
                            />
                          )}
                        />
                      </TableCell> */}

                          {/* Duration Autocomplete */}
                          <TableCell
                            // key={index}
                            style={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                            }}
                          >
                            <Autocomplete
                              open={this.state.isSuggestionOpen === index} // Open only for the current index
                              options={this.state.durationSuggestions} // Use the state variable
                              disableClearable
                              freeSolo
                              getOptionLabel={(option) => option.label} // Display the label of each option
                              value={
                                values[2] ? { label: values[2] } : null // Set the current value
                              }
                              filterOptions={(options) => options} // Show all suggestions without filtering by input
                              onChange={(event, newValue) => {
                                const formattedValue = newValue
                                  ? newValue.label.replace(/__/g, "")
                                  : ""; // Format value

                                this.handleDurationChange(
                                  index,
                                  formattedValue.trim().replace(/\s+/g, " ")
                                ); // Update the duration
                                this.setState({ isSuggestionOpen: -1 }); // Close all suggestions
                              }}
                              onInputChange={(event, newInputValue) => {
                                console.log(newInputValue);
                                // console.log(
                                //   /^\d*$/.test(newInputValue) ||
                                //     /^\d+\s*[a-zA-Z]*$/.test(newInputValue)
                                // );
                                if (
                                  /^\d*$/.test(newInputValue) ||
                                  /^\d+\s*[a-zA-Z]*$/.test(newInputValue)
                                ) {
                                  if (
                                    !isNaN(newInputValue) &&
                                    newInputValue.trim() !== ""
                                  ) {
                                    const newSuggestions = [
                                      { label: `${newInputValue} day` },
                                      { label: `${newInputValue} week` },
                                      { label: `${newInputValue} month` },
                                      { label: `${newInputValue} year` },
                                    ];
                                    this.setState({
                                      durationSuggestions: newSuggestions,
                                      isSuggestionOpen: index, // Open suggestion for the current index
                                    });
                                  } else {
                                    // Reset to default suggestions if input is empty or invalid
                                    const defaultSuggestions = [
                                      { label: "__day" },
                                      { label: "__week" },
                                      { label: "__month" },
                                      { label: "__year" },
                                    ];
                                    this.setState({
                                      durationSuggestions: defaultSuggestions,
                                      isSuggestionOpen: -1, // Close all suggestions if invalid input
                                    });
                                  }
                                }
                                // Check if the input is a valid number

                                // Update duration state on input change
                                this.handleDurationChange(
                                  index,
                                  newInputValue
                                    .trim() // Remove leading and trailing spaces
                                    .replace(/\s+/g, " ")
                                ); // Ensure the value is updated in the state
                              }}
                              onBlur={() => {
                                this.setState({ isSuggestionOpen: -1 }); // Close suggestions when the input loses focus
                              }}
                              // PopperComponent={(props) => (
                              //   <Popper {...props} placement="bottom" />
                              // )}
                              // disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                  // inputRef={this.inputRef}
                                />
                              )}
                            />
                          </TableCell>

                          {/* Date Display */}
                          {/* <TableCell
                        style={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                        }}
                      >
                        <TextField
                          type="date"
                          variant="standard"
                          value={values[3]} // Bind the date value
                          onChange={(event) =>
                            this.handleDateChange(index, event.target.value)
                          } // Update on change
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </TableCell> */}
                          {/* Delete Button */}
                          <TableCell
                            style={{
                              border: "1px solid white",
                              padding: "2px",
                            }}
                          >
                            <IconButton
                              onClick={() => this.handleDeleteComplaint(index)}
                              size="small"
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs>
                <EditDiagnosis
                  // diagnosis={diagnosis}
                  drNotes={drNotes}
                  onRowsChange={this.handleRowsChange}
                  disSug={this.disSug}
                />
              </Grid>
            </Grid>
            {/* <div> */}
            {/* <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                      <Autocomplete
                        //////////////////// ptNotes
                        multiple
                        id="multi field"
                        classes={{ option: classes.autocomp }}
                        // autoFocus
                        size="small"
                        freeSolo
                        options={this.symSug}
                        value={ptNotes}
                        filterSelectedOptions
                        onChange={(e, newValue) => {
                          this.handleMultiFld("ptNotes", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // multiline
                            // rows={2}
                            variant="outlined"
                            label={visitFormfields.complain}
                            // placeholder="word"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs>
                      <Autocomplete
                        //////////////////// drNotes
                        multiple
                        id="multi field"
                        classes={{ option: classes.autocomp }}
                        // autoFocus
                        size="small"
                        freeSolo
                        options={this.disSug}
                        value={drNotes}
                        filterSelectedOptions
                        onChange={(e, newValue) => {
                          this.handleMultiFld("drNotes", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // multiline
                            // rows={2}
                            variant="outlined"
                            label={visitFormfields.diagnose}
                            // placeholder="Word"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid> */}
            {/* <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                      <TextField
                        /////////////////////  notes
                        id="outlined-basic"
                        className={classes.freeTextNotes}
                        multiline
                        rows={2}
                        label={visitFormfields.notes}
                        placeholder={visitFormfields.notes}
                        name="notes"
                        variant="outlined"
                        value={notes}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        // autoFocus
                        id="outlined-basic"
                        className={classes.freeTextNotes}
                        multiline
                        rows={2}
                        label={visitFormfields.diaNotes}
                        placeholder={visitFormfields.diaNotes}
                        name="diaNotes"
                        variant="outlined"
                        value={diaNotes}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid> */}
            {/* </div> */}
            {/* <EditDiagnosis
              diagnosis={diagnosis}
              onRowsChange={this.handleRowsChange}
              disSug={this.disSug}
            /> */}

            <FormGroup>
              <EditVisitMedicine
                add={this.addMedRow}
                delete={this.deleteMedRow}
                medList={medList}
                handleChange={this.handleMedChange}
                medSug={this.medSug}
                handleMedRowsChange={this.handleMedRowsChange}
              />

              {/* <EditVisitReport
                    add={this.addRepRow}
                    delete={this.deleteRepRow}
                    repList={repList}
                    handleChange={this.handleRepChange}
                    repSug={this.repSug}
                  /> */}
            </FormGroup>

            <Grid container direction="row">
              <Grid item xs>
                {" "}
                <EditReports
                  repList={repList}
                  handleRepChange={this.handleRepChange}
                  repSug={this.repSug}
                />
              </Grid>
              <Grid item xs>
                {" "}
                <div>
                  <Typography
                    gutterBottom
                    color="textSecondary"
                    // variant="h6"
                    // color="black"
                    style={{
                      // fontWeight: "bold",
                      // marginTop: "18px",
                      marginLeft: "10px",
                    }}
                  >
                    Notes
                  </Typography>
                  <Divider variant="left" />
                  <Grid container direction="row" spacing={2}>
                    {/* <Grid item xs>
                        <TextField
                          // autoFocus
                          id="outlined-basic"
                          className={classes.freeTextNotes}
                          multiline
                          rows={2}
                          label={visitFormfields.notes}
                          // placeholder={visitFormfields.notes}
                          name="notes"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid> */}
                    <Grid item xs>
                      <TextField
                        // autoFocus
                        style={{ marginLeft: "10px" }}
                        id="outlined-basic"
                        className={classes.freeTextNotes}
                        multiline
                        rows={2}
                        value={this.state.formdetail.notes}
                        label={visitFormfields.diaNotes}
                        // placeholder={visitFormfields.diaNotes}
                        name="notes"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <EditAdvice
              drNotes={this.state.formdetail.diaNotes}
              onAdviceChange={this.handleAdviceChange}
            />
            {/* <EditPayment
              payment={payment}
              // paymentLable={this.state.paymentSuggestions}
              onRowsChange={this.handlePaymentChange}
            /> */}
            {/* <Divider
                  variant="middle"
                  className={classes.seprator}
                  // borderColor={"red"}
                /> */}
            <div>
              <Grid
                container
                direction="row"
                // justify="space-between"
                spacing={2}
              >
                <Grid item>
                  <Typography
                    gutterBottom
                    color="textSecondary"
                    // variant="h5"
                    // component="h2"
                    style={{ marginBottom: "15px" }}
                    // className={classes.refCls}
                  >
                    Amount
                  </Typography>
                  <TextField
                    value={amount}
                    // autoFocus
                    label="Amount"
                    id="amount"
                    name="amount"
                    defaultValue="0"
                    pattern="[0-9]*"
                    variant="outlined"
                    type="number"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    gutterBottom
                    color="textSecondary"
                    // variant="h5"
                    style={{ marginBottom: "15px" }}
                    // component="h2"
                    // className={classes.refCls}
                  >
                    Amount Paid
                  </Typography>
                  <TextField
                    // autoFocus
                    // label="Amount Paid"
                    id="amountPaid"
                    name="amountPaid"
                    defaultValue="0"
                    label="Amount Paid"
                    value={amountPaid}
                    variant="outlined"
                    type="number"
                    pattern="[0-9]*"
                    inputProps={{
                      classes: {
                        classes: { notchedOutline: classes.specialOutline },
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  {/* <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={classes.paymentCls}
                  >
                    {amount}
                  </Typography> */}

                  {/* <TextField
                        value={this.state.payment
                          .filter((item) => item.total) // Remove entries where total is empty
                          .map((item) => parseFloat(item.total)) // Convert total to a number
                          .reduce((acc, curr) => acc + curr, 0)}
                        // autoFocus
                        label="Amount"
                        id="amount"
                        name="amount"
                        defaultValue="0"
                        pattern="[0-9]*"
                        variant="outlined"
                        type="number"
                      /> */}
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Grid container direction="row" spacing={3}>
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={classes.refCls}
                  >
                    Refer
                    <Checkbox
                      checked={refMark}
                      onChange={(e) => this.handleChangeCheckbox(e)}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="drGrp"
                    margin="dense"
                    value={
                      this.state.drRefState.refMark === false
                        ? null
                        : drGrp
                        ? { name: drGrp }
                        : null
                    }
                    // value={{ name: drGrp }}
                    classes={{ option: classes.autocomp }}
                    // autoFocus
                    disableClearable
                    disabled={refGrpDisabled}
                    options={drGroups}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      this.handleDrGrpChange(e, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dr Grp"
                        name="drGrp"
                        margin="dense"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="drRef"
                    margin="dense"
                    classes={{ option: classes.autocomp }}
                    // autoFocus
                    value={
                      this.state.drRefState.refMark === false
                        ? null
                        : drRef
                        ? { name: drRef }
                        : null
                    }
                    // value={{ name: drRef }}
                    disableClearable
                    disabled={refDisabled || !refMark}
                    options={drList}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      value.name === "" || option.name === value.name
                    }
                    onChange={(e, value) => {
                      this.handleDrChange(e, "drRef", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dr Reference"
                        name="drRef"
                        margin="dense"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
            {/* </FormGroup> */}
            <div>
              <Button
                onClick={() => {
                  this.handleSave();
                }}
                color="primary"
                variant="outlined"
              >
                Save
              </Button>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                className={classes.btnClass}
              >
                Cancel
              </Button>

              <Button
                onClick={this.handleBillDialogOpen}
                // className={classes.saveBtn}
                variant="outlined"
                color="primary"
                // variant="outlined"
                // style={{ marginLeft: "15px" }}
              >
                Payment Bill
              </Button>
              <Dialog
                open={this.state.BilldialogOpen}
                onClose={this.handleBillDialogClose}
              >
                <DialogTitle>Payment Bill</DialogTitle>
                <Payment
                  patient_id={this.props.patient_id}
                  payment={this.state.payment}
                  // paymentLable={this.state.paymentSuggestions}
                  onRowsChange={this.handlePaymentChange}
                  paymentSug={this.paymentSug}
                />
                <DialogActions>
                  <Button
                    variant="contained"
                    // color="primary"
                    style={{ backgroundColor: "#9FC088", color: "white" }}
                    size="small"
                    onClick={() => {
                      this.handleBillSave();
                    }}
                    // onClick={(e) => handleClickAdd(e, value)}
                    // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    className={classes.addBtn}
                    // color="primary"
                  >
                    Add
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#9FC088", color: "white" }}
                    // color="primary"
                    size="small"
                    onClick={this.handleBillDialogClose}
                    className={classes.addBtn}
                    // color="primary"
                  >
                    Close
                  </Button>
                  {/* <Button onClick={handleClickClose} color="primary">
            Agree
          </Button> */}
                </DialogActions>
              </Dialog>
            </div>
          </form>
        </Fragment>
        // </div>
      );
    }
  }
);
